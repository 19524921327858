import Swal from 'sweetalert2';
import i18n from './i18n';

function showAlert(type: any, title: any, message?: string, payload?: string) {
  const copyPayload = () => {
    navigator.clipboard.writeText(payload ? payload : noPayload);
  };

  const noPayload = `${i18n.t('general.noPayload')}`;
  const copyPayloadMessage = `${i18n.t('general.copyPayload')}`;
  Swal.fire({
    icon: type,
    position: 'center',
    buttonsStyling: false,
    confirmButtonText: 'Ok',
    title: `<p>${title}</p>`,
    html: message ? `<p>${message}</p>` : '',
    width: '23%',
    customClass: {
      confirmButton: 'btn btn-primary'
    },
    footer: `<a href="#" id="copyPayload"}">${copyPayloadMessage} </a>`
  });

  document.getElementById('copyPayload')?.addEventListener('click', copyPayload);
}

const showLoginModal = () => {
  return Swal.fire({
    title: 'Login',
    input: 'password',
    showCancelButton: true,
    confirmButtonText: 'Entrar',
    preConfirm: result => {
      return result;
    }
  });
};
export { showAlert, showLoginModal };
