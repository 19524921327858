import { interfaces } from 'inversify';
import { container } from 'inversify-props';
import { TYPES } from '@/core/config/Types';
import { Application } from '@/core/config/Application';
import Vue, { VueConstructor } from 'vue';
import { Runner } from '@/core/runner/Runner';
import { StateManager } from '@/core/statemanager/StateManager';
import { TyStateManager } from '@/core/statemanager/TyStateManager';
import { ExecutorLink } from '@/core/runner/ExecutorLink';
import { LoggerLink } from '@/core/runner/LoggerLink';
import { Logger } from '@/core/helper/Logger';
import { ConsoleLogger } from '@/core/consolelogger/ConsoleLogger';
import { CityRepository } from '@/settings/domain/city/CityRepository';
import { CityService } from '@/settings/infrastructure/services/city/CityService';
import { SearchCityByDescriptionLike } from '@/settings/application/uses_cases/city/search/SearchCityByDescriptionLike';
import { SearchCitiesByCountryAndStateActive } from '@/settings/application/uses_cases/city/search/SearchCitiesByCountryAndStateActive';
import { DeleteCity } from '@/settings/application/uses_cases/city/delete/DeleteCity';
import { UpdateCity } from '@/settings/application/uses_cases/city/update/UpdateCity';
import { CreateCity } from '@/settings/application/uses_cases/city/create/CreateCity';
import { SearchTerritoriesByQueryParameter } from '@/settings/application/uses_cases/views/territories/SearchTerritoriesByQueryParameter';
import { TerritoriesRepository } from '@/settings/domain/views/territories/TerritoriesRepository';
import { TerritoryService } from '@/settings/infrastructure/services/views/territories/TerritoryService';
import { LoginRepository } from '@/courier/domain/login/LoginRepository';
import { LoginService } from '@/courier/infrastructure/service/login/LoginService';
import { LoginFindToken } from '@/courier/application/uses_cases/login/search/findtoken/LoginFindToken';
import { EventsRepository } from '@/tracking/domain/events/EventsRepository';
import { EventsServices } from '@/tracking/infrastructure/services/events/EventsServices';
import { ActivitiesRepository } from '@/tracking/domain/activities/ActivitiesRepository';
import { ActivitiesServices } from '@/tracking/infrastructure/services/activities/ActivitiesServices';
import { NoveltyRepository } from '@/tracking/domain/novelty/NoveltyRepository';
import { NoveltyServices } from '@/tracking/infrastructure/services/novelty/NoveltyServices';
import { OperationRepository } from '@/tracking/domain/typeoperation/OperationRepository';
import { OperationServices } from '@/tracking/infrastructure/services/typeoperation/OperationServices';
import { OperationFindAll } from '@/tracking/application/uses_cases/typeoperation/search/OperationFindAll';
import { SwitchesRepository } from '@/tracking/domain/switches/SwitchesRepository';
import { SwitchesServices } from '@/tracking/infrastructure/services/switches/SwitchesServices';
import { SwitchesFindAll } from '@/tracking/application/uses_cases/switches/search/SwitchesFindAll';
import { CreateOperation } from '@/tracking/application/uses_cases/typeoperation/create/CreateOperation';
import { CreateEvents } from '@/tracking/application/uses_cases/events/create/CreateEvents';
import { UpdateOperation } from '@/tracking/application/uses_cases/typeoperation/update/UpdateOperation';
import { UpdateEvents } from '@/tracking/application/uses_cases/events/update/UpdateEvents';
import { CreateActivities } from '@/tracking/application/uses_cases/activities/create/CreateActivities';
import { EventsFindAll } from '@/tracking/application/uses_cases/events/search/EventsFindAll';
import { TimelineRepository } from '@/tracking/domain/timeline/TimelineRepository';
import { TimelineServices } from '@/tracking/infrastructure/services/timeline/TimelineServices';
import { TimelineFindAll } from '@/tracking/application/uses_cases/timeline/search/findall/TimelineFindAll';
import { CreateProcess } from '@/tracking/application/uses_cases/processtype/create/CreateProcess';
import { ProcessRepository } from '@/tracking/domain/processtype/ProcessRepository';
import { ProcessFindAll } from '@/tracking/application/uses_cases/processtype/search/ProcessFindAll';
import { UpdateProcess } from '@/tracking/application/uses_cases/processtype/update/UpdateProcess';
import { ProcessServices } from '@/tracking/infrastructure/services/processtype/ProcessServices';
import { ProcessFindById } from '@/tracking/application/uses_cases/processtype/search/ProcessFindById';
import { TransportRepository } from '@/tracking/domain/typetransport/TransportRepository';
import { TransportServices } from '@/tracking/infrastructure/services/typetransport/TransportServices';
import { TransportFindAll } from '@/tracking/application/uses_cases/typetransport/search/findall/TransportFindAll';
import { CreateTimeline } from '@/tracking/application/uses_cases/timeline/create/CreateTimeline';
import { EventsFindByProcessType } from '@/tracking/application/uses_cases/events/search/EventsFindByProcessType';
import { OperationFindByProcessType } from '@/tracking/application/uses_cases/typeoperation/search/OperationFindByProcessType';
import { UpdateTimeline } from '@/tracking/application/uses_cases/timeline/update/UpdateTimeline';
import { MonetaryTransactionRepository } from '@/tracking/domain/monetarytransaction/MonetaryTransactionRepository';
import { MonetaryTransactionServices } from '@/tracking/infrastructure/services/monetarytransaction/MonetaryTransactionServices';
import { MonetaryTransactionFindAll } from '@/tracking/application/uses_cases/monetarytransaction/search/findall/MonetaryTransactionFindAll';
import { TimelineFindByProcessType } from '@/tracking/application/uses_cases/timeline/search/findtimelinebyprocesstype/TimelineFindByProcessType';

//Andres Escorcia
import { FindChartValuesByTimeLine } from '@/tracking/application/uses_cases/timeline/search/findchartvaluesbytimeline/FindChartValuesByTimeLine';
import { CountryRepository } from '@/settings/domain/country/CountryRepository';
import { CountryServices } from '@/settings/infrastructure/services/country/CountryServices';
import { CountryFindAll } from '@/settings/application/uses_cases/country/search/CountryFindAll';
import { CreateCountry } from '@/settings/application/uses_cases/country/create/CreateCountry';
import { CountryFindByStatus } from '@/settings/application/uses_cases/country/search/CountryFindByStatus';
import { CountryUpdate } from '@/settings/application/uses_cases/country/update/CountryUpdate';
import { StateRepository } from '@/settings/domain/state/StateRepository';
import { StateServices } from '@/settings/infrastructure/services/state/StateServices';
import { StateFindAll } from '@/settings/application/uses_cases/state/search/StateFindAll';
import { CreateState } from '@/settings/application/uses_cases/state/create/CreateState';
import { StateFindByStatus } from '@/settings/application/uses_cases/state/search/StateFindByStatus';
import { StateFindByCountryCode } from '@/settings/application/uses_cases/state/search/StateFindByCountryCode';
import { StateUpdate } from '@/settings/application/uses_cases/state/update/StateUpdate';
import { ZoneRepository } from '@/settings/domain/zone/ZoneRepository';
import { ZoneServices } from '../../settings/infrastructure/services/zone/ZoneServices';
import { ZoneFindAll } from '@/settings/application/uses_cases/zone/search/ZoneFindAll';
import { CreateZone } from '@/settings/application/uses_cases/zone/create/CreateZone';
import { ZoneUpdate } from '@/settings/application/uses_cases/zone/update/ZoneUpdate';
import { ZoneFindByStatusAndCountryAndState } from '@/settings/application/uses_cases/zone/search/ZoneFindByStatusAndCountryAndState';
import { CurrencyRepository } from '@/settings/domain/currency/CurrencyRepository';
import { CurrencyServices } from '@/settings/infrastructure/services/currency/CurrencyServices';
import { CurrencyFindAll } from '@/settings/application/uses_cases/currency/search/CurrencyFindAll';
import { CreateCurrency } from '@/settings/application/uses_cases/currency/create/CreateCurrency';
import { CurrencyUpdate } from '@/settings/application/uses_cases/currency/update/CurrencyUpdate';
import { CurrencyFindById } from '@/settings/application/uses_cases/currency/search/CurrencyFindById';
import { CurrencyFactorFindAll } from '@/settings/application/uses_cases/currencyfactor/search/CurrencyFactorFindAll';
import { CurrencyFactorServices } from '@/settings/infrastructure/services/currencyfactor/CurrencyFactorServices';
import { CurrencyFactorRepository } from '@/settings/domain/currencyfactor/CurrencyFactorRepository';
import { CreateCurrencyFactor } from '@/settings/application/uses_cases/currencyfactor/create/CreateCurrencyFactor';
import { CurrencyFactorUpdate } from '@/settings/application/uses_cases/currencyfactor/update/CurrencyFactorUpdate';
import { CurrencyFactorFindByCurrencyCode } from '@/settings/application/uses_cases/currencyfactor/search/CurrencyFactorFindByCurrencyCode';
import { RoutesServices } from '@/settings/infrastructure/services/routes/RoutesServices';
import { RoutesRepository } from '@/settings/domain/routes/RoutesRepository';
import { RoutesFindAll } from '@/settings/application/uses_cases/routes/search/RoutesFindAll';
import { CreateRoutes } from '@/settings/application/uses_cases/routes/create/CreateRoutes';
import { RoutesUpdate } from '@/settings/application/uses_cases/routes/update/RoutesUpdate';
import { RouteFindById } from '@/settings/application/uses_cases/routes/search/RouteFindById';
import { RoutesFindByStatus } from '@/settings/application/uses_cases/routes/search/RoutesFindByStatus';
import { WarehouseRepository } from '@/settings/domain/warehouse/WarehouseRepository';
import { WarehouseServices } from '@/settings/infrastructure/services/warehouse/WarehouseServices';
import { WarehouseFindAll } from '@/settings/application/uses_cases/warehouse/search/WarehouseFindAll';
import { WarehouseFindByCode } from '@/settings/application/uses_cases/warehouse/search/WarehouseFindByCode';
import { CreateWarehouse } from '@/settings/application/uses_cases/warehouse/create/CreateWarehouse';
import { WarehouseUpdate } from '@/settings/application/uses_cases/warehouse/update/WarehouseUpdate';
import { WarehouseFindByStatus } from '@/settings/application/uses_cases/warehouse/search/WarehouseFindByStatus';
import { CompanyServices } from '@/settings/infrastructure/services/company/CompanyServices';
import { CompanyRepository } from '@/settings/domain/company/CompanyRepository';
import { CompanyFindAll } from '@/settings/application/uses_cases/company/search/CompanyFindAll';
import { CompanyFindById } from '@/settings/application/uses_cases/company/search/CompanyFindById';
import { CompanyUpdate } from '@/settings/application/uses_cases/company/update/CompanyUpdate';
import { CreateCompany } from '@/settings/application/uses_cases/company/create/CreateCompany';
import { FileRepository } from '@/settings/domain/file/FileRepository';
import { FileFindAll } from '@/settings/application/uses_cases/file/search/FileFindAll';
import { CreateFile } from '@/settings/application/uses_cases/file/create/CreateFile';
import { FileServices } from '@/settings/infrastructure/services/file/FileServices';
import { TaxeRepository } from '@/settings/domain/taxe/TaxeRepository';
import { TaxeFindAll } from '@/settings/application/uses_cases/taxe/search/TaxeFindAll';
import { TaxeCreate } from '@/settings/application/uses_cases/taxe/create/TaxeCreate';
import { TaxeFindByStatus } from '@/settings/application/uses_cases/taxe/search/TaxeFindByStatus';
import { TaxeUpdate } from '@/settings/application/uses_cases/taxe/update/TaxeUpdate';
import { TaxeServices } from '@/settings/infrastructure/services/taxe/TaxeServices';

//Carlos
import { PriceListRepositoryVolume } from '@/settings/domain/priceList/PriceListRepositoryVolume';
import { PriceListFindAllVolume } from '@/settings/application/uses_cases/priceList/search/volume/PriceListFindAllVolume';
import { PriceListCreateVolume } from '@/settings/application/uses_cases/priceList/create/volume/PriceListCreateVolume';
import { PriceListFindAllByStatusVolume } from '@/settings/application/uses_cases/priceList/search/volume/PriceListFindAllByStatusVolume';
import { PriceListUpdateVolume } from '@/settings/application/uses_cases/priceList/update/volume/PriceListUpdateVolume';
import { PriceListRepositoryWeight } from '@/settings/domain/priceList/PriceListRepositoryWeight';
import { PriceListFindAllWeight } from '@/settings/application/uses_cases/priceList/search/weight/PriceListFindAllWeight';
import { PriceListCreateWeight } from '@/settings/application/uses_cases/priceList/create/weight/PriceListCreateWeight';
import { PriceListFindAllByStatusWeight } from '@/settings/application/uses_cases/priceList/search/weight/PriceListFindAllByStatusWeight';
import { PriceListUpdateWeight } from '@/settings/application/uses_cases/priceList/update/weight/PriceListUpdateWeight';
import { PalletsFactorUpdate } from '@/settings/application/uses_cases/palletsFactor/update/PalletsFactorUpdate';
import { PalletsFactorCreate } from '@/settings/application/uses_cases/palletsFactor/create/PalletsFactorCreate';
import { PalletsFactorFindAll } from '@/settings/application/uses_cases/palletsFactor/search/PalletsFactorFindAll';
import { PalletsFactorRepository } from '@/settings/domain/palletsFactor/PalletsFactorRepository';
import { PalletsFactorService } from '@/settings/infrastructure/services/palletsFactor/PalletsFactorService';
import { InvoiceRepository } from '@/courier/domain/invoice/InvoiceRepository';
import { InvoiceService } from '@/courier/infrastructure/service/invoice/InvoiceService';
import { InvoiceFindByDateRange } from '@/courier/application/uses_cases/invoice/search/InvoiceFindByDateRange';
import { InvoiceUpdate } from '@/courier/application/uses_cases/invoice/update/InvoiceUpdate';
import { InvoiceFindByPk } from '@/courier/application/uses_cases/invoice/search/InvoiceFindByPk';
import { InvoiceCreate } from '@/courier/application/uses_cases/invoice/create/InvoiceCreate';
import { InvoiceFindByDateRangeAndCustomer } from '@/courier/application/uses_cases/invoice/search/InvoiceFindByDateRangeAndCustomer';
import { InvoiceFindByClientId } from '@/courier/application/uses_cases/invoice/search/InvoiceFindByClientId';
import { InvoiceFindAll } from '@/courier/application/uses_cases/invoice/search/InvoiceFindAll';
import { CustomerRepository } from '@/courier/domain/customer/CustomerRepository';
import { CustomerServices } from '@/courier/infrastructure/service/customer/CustomerServices';
import { CreateCustomer } from '@/courier/application/uses_cases/customer/create/CreateCustomer';
import { CustomerGetSecurityCode } from '@/courier/application/uses_cases/customer/search/CustomerGetSecurityCode';
import { UpdateCustomerPassword } from '@/courier/application/uses_cases/customer/update/UpdateCustomerPassword';
import { CurrencyFactorFindRateByDate } from '@/settings/application/uses_cases/currencyfactor/search/CurrencyFactorFindRateByDate';
import { PriceListFindByOriginAndDestinationWeight } from '@/settings/application/uses_cases/priceList/search/weight/PriceListFindByOriginAndDestinationWeight';
import { PriceListFindByOriginAndDestination } from '@/settings/application/uses_cases/priceList/search/volume/PriceListFindByOriginAndDestination';
import { PurchaseRepository } from '@/courier/domain/purchase/PurchaseRepository';
import { PurchaseServices } from '@/courier/infrastructure/service/purchase/PurchaseServices';
import { PurchaseScraping } from '@/courier/application/uses_cases/purchase/search/PurchaseScraping';
import { OrderRepository } from '@/courier/domain/order/OrderRepository';
import { OrderServices } from '@/courier/infrastructure/service/order/OrderServices';
import { CreateOrder } from '@/courier/application/uses_cases/order/create/CreateOrder';
import { CustomerFindById } from '@/courier/application/uses_cases/customer/search/CustomerFindById';
import { ConsecutiveFindByWarehouseAndType } from '@/courier/application/uses_cases/consecutive/search/ConsecutiveFindByWarehouseAndType';
import { ConsecutiveRepository } from '@/courier/domain/consecutive/ConsecutiveRepository';
import { ConsecutiveServices } from '@/courier/infrastructure/service/Consecutive/ConsecutiveServices';
import { OrderFindByPk } from '@/courier/application/uses_cases/order/search/OrderFindByPk';
import { OrderFindByDateRange } from '@/courier/application/uses_cases/order/search/OrderFindByDateRange';
import { EventsFindAllByTimelineId } from '@/tracking/application/uses_cases/events/search/EventsFindAllByTimelineId';
import { SwitchFindAllByProcessType } from '@/tracking/application/uses_cases/switches/search/SwitchFindAllByProcessType';
import { CreateEventsExec } from '@/tracking/application/uses_cases/events/create/CreateEventsExec';
import { UpdateOrder } from '@/courier/application/uses_cases/order/update/UpdateOrder';
import { RoleRepository } from '@/settings/domain/role/RoleRepository';
import { RoleServices } from '@/settings/infrastructure/services/role/RoleServices';
import { RoleFindById } from '@/settings/application/uses_cases/role/search/RoleFindById';
import { RoleFindAll } from '@/settings/application/uses_cases/role/search/RoleFindAll';
import { RoleDelete } from '@/settings/application/uses_cases/role/delete/RoleDelete';
import { CreateRole } from '@/settings/application/uses_cases/role/create/CreateRole';
import { UpdateRole } from '@/settings/application/uses_cases/role/update/UpdateRole';
import { RoleFindMenuPlaneByRoleId } from '@/settings/application/uses_cases/role/search/RoleFindMenuPlaneByRoleId';
import { RoleFindByCompanyId } from '@/settings/application/uses_cases/role/search/RoleFindByCompanyId';
import { CreateInvoiceFromOrder } from '@/courier/application/uses_cases/invoice/create/CreateInvoiceFromOrder';
import { UpdateUserPassword } from '@/settings/application/uses_cases/user/update/UpdateUserPassword';
import { UpdateUser } from '@/settings/application/uses_cases/user/update/UpdateUser';
import { UserGetSecurityCode } from '@/settings/application/uses_cases/user/search/UserGetSecurityCode';
import { UserFindById } from '@/settings/application/uses_cases/user/search/UserFindById';
import { CreateUser } from '@/settings/application/uses_cases/user/create/CreateUser';
import { UserRepository } from '@/settings/domain/user/UserRepository';
import { UserServices } from '@/settings/infrastructure/services/user/UserServices';
import { OrderFindByDateRangeAndCustomerId } from '@/courier/application/uses_cases/order/search/OrderFindByDateRangeAndCustomerId';
import { UnitOfMeasurementRepository } from '@/settings/domain/unitofmeasurement/UnitOfMeasurementRepository';
import { CreateUnitOfMeasurement } from '@/settings/application/uses_cases/unitofmeasurement/create/CreateUnitOfMeasurement';
import { UnitOfMeasurementFindAll } from '@/settings/application/uses_cases/unitofmeasurement/search/UnitOfMeasurementFindAll';
import { UpdateUnitOfMeasurement } from '@/settings/application/uses_cases/unitofmeasurement/update/UpdateUnitOfMeasurement';
import { UnitOfMeasurementServices } from '@/settings/infrastructure/services/unitofmeasurement/UnitOfMeasurementServices';
import { VolumeRepository } from '@/settings/domain/volume/VolumeRepository';
import { VolumeServices } from '@/settings/infrastructure/services/volume/VolumeServices';
import { CreateVolume } from '@/settings/application/uses_cases/volume/create/CreateVolume';
import { UpdateVolume } from '@/settings/application/uses_cases/volume/update/UpdateVolume';
import { VolumeFindAll } from '@/settings/application/uses_cases/volume/search/VolumeFindAll';
import { WeightRepository } from '@/settings/domain/weight/WeightRepository';
import { WeightServices } from '@/settings/infrastructure/services/weight/WeightServices';
import { CreateWeight } from '@/settings/application/uses_cases/weight/create/CreateWeight';
import { UpdateWeight } from '@/settings/application/uses_cases/weight/update/UpdateWeight';
import { WeightFindAll } from '@/settings/application/uses_cases/weight/search/WeightFindAll';
import { CountryFindByDescriptionLike } from '@/settings/application/uses_cases/country/search/CountryFindByDescriptionLike';
import { StateFindByDescriptionLike } from '@/settings/application/uses_cases/state/search/StateFindByDescriptionLike';
import { ZoneFindByDescriptionLike } from '@/settings/application/uses_cases/zone/search/ZoneFindByDescriptionLike';
import { RoleFindMenuPlaneByRoleName } from '@/settings/application/uses_cases/role/search/RoleFindMenuPlaneByRoleName';
import { EventsFindAllConfActive } from '@/tracking/application/uses_cases/events/search/EventsFindAllConfActive';
import { UpdateClassReferences } from '@/wms/application/catalogs/classReferences/update/UpdateClassReferences';
import { CreateClassReferences } from '@/wms/application/catalogs/classReferences/create/CreateClassReferences';
import { FindAllClassReferences } from '@/wms/application/catalogs/classReferences/search/FindAllClassReferences';
import { ClassReferencesRepository } from '@/wms/domain/catalogs/classReferences/ClassReferencesRepository';
import { ClassReferencesService } from '@/wms/infrastructure/service/catalogs/classReferences/ClassRefrencesService';
import { UpdateCategoryReferences } from '@/wms/application/catalogs/categoryReferences/update/UpdateCategoryReferences';
import { CreateCategoryReferences } from '@/wms/application/catalogs/categoryReferences/create/CreateCategoryReferences';
import { FindAllCategoryReferences } from '@/wms/application/catalogs/categoryReferences/search/FindAllCategoryReferences';
import { CategoryReferencesRepository } from '@/wms/domain/catalogs/categoryReferences/CategoryReferencesRepository';
import { CategoryReferencesService } from '@/wms/infrastructure/service/catalogs/categoryReferences/CategoryReferencesService';
import { UpdateVariety } from '@/wms/application/catalogs/Variety/update/UpdateVariety';
import { CreateVariety } from '@/wms/application/catalogs/Variety/create/CreateVariety';
import { FindAllVariety } from '@/wms/application/catalogs/Variety/search/FindAllVariety';
import { VarietyRepository } from '@/wms/domain/catalogs/xgeneral/variety/VarietyRepository';
import { VarietyService } from '@/wms/infrastructure/service/catalogs/Variety/VarietyService';
import { UpdateSegmentation } from '@/wms/application/catalogs/segmentation/update/UpdateSegmentation';
import { FindAllSegmentation } from '@/wms/application/catalogs/segmentation/search/FindAllSegmentation';
import { SegmentationRepository } from '@/wms/domain/catalogs/segmentation/SegmentationRepository';
import { SegmentationService } from '@/wms/infrastructure/service/catalogs/segmentation/SegmentationService';
import { UpdateAlternativeReferences } from '@/wms/application/catalogs/alternativeReferences/update/UpdateAlternativeReferences';
import { DeleteAlternativeReferences } from '@/wms/application/catalogs/alternativeReferences/delete/DeleteAlternativeReferences';
import { CreateAlternativeReferences } from '@/wms/application/catalogs/alternativeReferences/create/CreateAlternativeReferences';
import { FindAllAlternativeReferences } from '@/wms/application/catalogs/alternativeReferences/search/FindAllAlternativeReferences';
import { FindByReferenceAlternativeReferences } from '@/wms/application/catalogs/alternativeReferences/search/FindByReferenceAlternativeReferences';
import { FindOwnerByReferenceAndType } from '@/wms/application/catalogs/alternativeReferences/search/FindOwnerByReferenceAndType';
import { AlternativeReferencesRepository } from '@/wms/domain/catalogs/alternativeReferences/AlternativeReferencesRepository';
import { AlternativeReferencesService } from '@/wms/infrastructure/service/catalogs/alternativeReferences/AlternativeReferencesService';
import { UpdatePackaging } from '@/wms/application/catalogs/packaging/update/UpdatePackaging';
import { DeletePackaging } from '@/wms/application/catalogs/packaging/delete/DeletePackaging';
import { CreatePackaging } from '@/wms/application/catalogs/packaging/create/CreatePackaging';
import { FindAllPackaging } from '@/wms/application/catalogs/packaging/search/FindAllPackaging';
import { FindByReferencePackaging } from '@/wms/application/catalogs/packaging/search/FindByReferencePackaging';
import { PackagingRepository } from '@/wms/domain/catalogs/packaging/PackagingRepository';
import { PackagingService } from '@/wms/infrastructure/service/catalogs/packaging/PackagingService';
import { SavePricingEngine } from '@/wms/application/catalogs/PricingEngine/save/SavePricingEngine';
import { PricingEngineRepository } from '@/wms/domain/catalogs/pricingEngine/PricingEngineRepository';
import { PricingEngineService } from '@/wms/infrastructure/service/catalogs/pricingEngine/PricingEngineService';
import { GroupReferenceUpdate } from '@/wms/application/category/groupReferences/update/GroupReferenceUpdate';
import { GroupReferenceFindById } from '@/wms/application/category/groupReferences/search/GroupReferenceFindById';
import { CreateGroupReference } from '@/wms/application/category/groupReferences/create/CreateGroupReference';
import { GroupReferenceFindAll } from '@/wms/application/category/groupReferences/search/GroupReferenceFindAll';
import { GroupReferenceFindAllTree } from '@/wms/application/category/groupReferences/search/GroupReferenceFindAllTree';
import { GroupReferencesRepository } from '@/wms/domain/category/groupReferences/GroupReferencesRepository';
import { GroupReferencesServices } from '@/wms/infrastructure/service/category/groupReferences/GroupReferencesServices';
import { ReferenceUpdate } from '@/wms/application/catalogs/references/update/ReferenceUpdate';
import { ReferenceFindById } from '@/wms/application/catalogs/references/search/ReferenceFindById';
import { CreateReference } from '@/wms/application/catalogs/references/create/CreateReference';
import { ReferenceFindAll } from '@/wms/application/catalogs/references/search/ReferenceFindAll';
import { ReferencesRepository } from '@/wms/domain/catalogs/references/ReferencesRepository';
import { ReferencesServices } from '@/wms/infrastructure/service/catalogs/reference/ReferencesServices';
import { FindByTypeParam } from '@/wms/application/catalogs/PricingEngineParam/search/FindByTypeParam';
import { PricingEngineParamRepository } from '@/wms/domain/catalogs/pricingEngineParam/PricingEngineParamRepository';
import { PricingEngineParamService } from '@/wms/infrastructure/service/catalogs/pricingEngineParam/PricingEngineParamService';
import { UpdatePackingUnit } from '@/wms/application/packingUnit/update/UpdatePackingUnit';
import { CreatePackingUnit } from '@/wms/application/packingUnit/create/CreatePackingUnit';
import { FindAllPackingUnit } from '@/wms/application/packingUnit/search/FindAllPackingUnit';
import { PackingUnitRepository } from '@/wms/domain/packingUnit/PackingUnitRepository';
import { PackingUnitService } from '@/wms/infrastructure/service/catalogs/packingUnit/PackingUnitService';
import { UpdateTypeIdentification } from '@/settings/application/uses_cases/parmsGeneral/typeIdentificacion/update/UpdateTypeIdentification';
import { TypeCustomerService } from '@/settings/infrastructure/services/ParmsGeneral/TypeCustomerService';
import { TypeCustomerRepository } from '@/settings/domain/entity/typeCustomer/TypeCustomerRepository';
import { TypeCustomerFindAll } from '@/settings/application/uses_cases/parmsGeneral/typeCustomer/search/TypeCustomerFindAll';
import { CreateTypeIdentification } from '@/settings/application/uses_cases/parmsGeneral/typeIdentificacion/create/CreateTypeIdentification';
import { TypeIdentificationFindAll } from '@/settings/application/uses_cases/parmsGeneral/typeIdentificacion/search/TypeIdentificationFindAll';
import { TypeIdentificationRepository } from '@/settings/domain/entity/typeIdentification/TypeIdentificationRepository';
import { TypeIdentificationService } from '@/settings/infrastructure/services/ParmsGeneral/TypeIdentificationService';
import { IncotermsFindAll } from '@/settings/application/uses_cases/parmsGeneral/incoterms/search/IncotermsFindAll';
import { CreateIncoterms } from '@/settings/application/uses_cases/parmsGeneral/incoterms/create/CreateIncoterms';
import { UpdateIncoterms } from '@/settings/application/uses_cases/parmsGeneral/incoterms/update/UpdateIncoterms';
import { IncotermsRepository } from '@/settings/domain/ParmsGeneral/Incoterms/IncotermsRepository';
import { IncotermsService } from '@/settings/infrastructure/services/ParmsGeneral/IncotermsService';
import { UpdateTermsPayment } from '@/settings/application/uses_cases/parmsGeneral/termsPayment/update/UpdateTermsPayment';
import { CreateTermsPayment } from '@/settings/application/uses_cases/parmsGeneral/termsPayment/create/CreateTermsPayment';
import { TermsPaymentFindAll } from '@/settings/application/uses_cases/parmsGeneral/termsPayment/search/TermsPaymentFindAll';
import { TermsPaymentRepository } from '@/settings/domain/ParmsGeneral/termsPayment/TermsPaymentRepository';
import { TermsPaymentService } from '@/settings/infrastructure/services/ParmsGeneral/TermsPaymentService';
import { UpdateBank } from '@/settings/application/uses_cases/parmsGeneral/bank/update/UpdateBank';
import { CreateBank } from '@/settings/application/uses_cases/parmsGeneral/bank/create/CreateBank';
import { BankFindAll } from '@/settings/application/uses_cases/parmsGeneral/bank/search/BankFindAll';
import { BankRepository } from '@/settings/domain/ParmsGeneral/bank/BankRepository';
import { BankService } from '@/settings/infrastructure/services/ParmsGeneral/BankService';
import { GroupAgentServiceFindAll } from '@/settings/application/uses_cases/parmsGeneral/groupAgentService/search/GroupAgentServiceFindAll';
import { GroupAgentServiceRepository } from '@/settings/domain/ParmsGeneral/groupAgentService/GroupAgentServiceRepository';
import { CreateGroupAgentService } from '@/settings/application/uses_cases/parmsGeneral/groupAgentService/create/CreateGroupAgentService';
import { UpdateGroupAgentService } from '@/settings/application/uses_cases/parmsGeneral/groupAgentService/update/UpdateGroupAgentService';
import { GroupAgentSrvService } from '@/settings/infrastructure/services/ParmsGeneral/GroupAgentSrvService';
import { ContainerService } from '@/settings/infrastructure/services/container/ContainerService';
import { CreateContainer } from '@/settings/application/uses_cases/containers/create/CreateContainer';
import { UpdateContainer } from '@/settings/application/uses_cases/containers/update/UpdateContainer';
import { FindContainerById } from '@/settings/application/uses_cases/containers/search/FindContainerById';
import { FindAllContainers } from '@/settings/application/uses_cases/containers/search/FindAllContianers';
import { FindAllContainersByStatus } from '@/settings/application/uses_cases/containers/search/FindAllContainersByStatus';
import { ContainersRepository } from '@/settings/domain/containers/CotainersRepository';
import { CreatePackingUnits } from '@/settings/application/uses_cases/packing_units/create/CreatePackingUnit';
import { UpdatePackingUnits } from '@/settings/application/uses_cases/packing_units/update/UpdatePackingUnits';
import { FindPackingUnitById } from '@/settings/application/uses_cases/packing_units/search/FindPackingUnitById';
import { FindAllPackingUnits } from '@/settings/application/uses_cases/packing_units/search/FindAllPackingUnits';
import { FindAllPackingUnitsByStatus } from '@/settings/application/uses_cases/packing_units/search/FindPackingUnitsByStatus';
import { PackingUnitsRepository } from '@/settings/domain/packing_units/PackingUnitsRepository';
import { PackingUnitServices } from '@/settings/infrastructure/services/packing_units/PackingUnitServices';
import { SearchPortsByDescriptionLike } from '@/freight/application/port/search/SearchPortsByDescriptionLike';
import { PortRepository } from '@/freight/domain/ports/PortRepository';
import { PortService } from '@/freight/infrastructure/service/ports/PortService';
import { DeleteOperation } from '@/tracking/application/uses_cases/typeoperation/delete/DeleteOperation';
import { CreateStore } from '@/wms/application/layout/store/create/CreateStore';
import { UpdateStore } from '@/wms/application/layout/store/update/UpdateStore';
import { FindAllStorebyWarehouse } from '@/wms/application/layout/store/search/FindAllStorebyWarehouse';
import { FindAllStore } from '@/wms/application/layout/store/search/FindAllStore';
import { StoreRepository } from '@/wms/domain/layout/store/StoreRepository';
import { StoreServices } from '@/wms/infrastructure/service/layout/store/StoreServices';
import { CreateRack } from '@/wms/application/layout/rack/create/CreateRack';
import { UpdateRack } from '@/wms/application/layout/rack/update/UpdateRack';
import { FindAllRacks } from '@/wms/application/layout/rack/search/FindAllRacks';
import { RackRepository } from '@/wms/domain/layout/rack/RackRepository';
import { RackService } from '@/wms/infrastructure/service/layout/rack/RackServices';
import { GroupReferenceDelete } from '@/wms/application/category/delete/GroupReferenceDelete';
import { DeleteTaxByCode } from '@/settings/application/uses_cases/taxe/delete/DeleteTaxByCode';
import { WarehouseDelete } from '@/settings/application/uses_cases/warehouse/delete/WarehouseDelete';
import { SearchTerritoryByQueryDescription } from '@/settings/application/uses_cases/views/territories/SearchTerritoryByQueryDescription';
import { UpdatePort } from '@/freight/application/port/update/UpdatePort';
import { CreatePort } from '@/freight/application/port/create/CreatePort';
import { SearchPortsByStatus } from '@/freight/application/port/search/SearchPortsByStatus';
import { PreEntityRepository } from '@/settings/domain/pre_entity/PreEntityRepository';
import { PreEntityService } from '@/settings/infrastructure/services/pre_entity/PreEntityService';
import { SearchPreEntityByFullNameLike } from '@/settings/application/uses_cases/pre_entity/search/SearchPreEntityByFullNameLike';
import { OrderbyFilterRepository } from '@/courier/domain/view/orderbyfilter/OrderbyFilterRepository';
import { OrderByFilterService } from '@/courier/infrastructure/service/view/order/OrderByFilterService';
import { FindOrderByFilters } from '@/courier/application/uses_cases/view/order/FindOrderByFilters';
import { MigrationFromCountryCode } from '@/settings/application/uses_cases/migration/MigrationFromCountryCode';
import { MigrationFromCountryRepository } from '@/settings/domain/migrations/MigrationFromCountryRepository';
import { MigrationFromCountryService } from '@/settings/infrastructure/services/migrations/MigrationFromCountryService';
import { EntityFindFilter } from '@/settings/application/uses_cases/entity/search/EntityFindFilter';
import { EntityRepository } from '@/settings/domain/entity/entity/EntityRepository';
import { EntityService } from '@/settings/infrastructure/services/entity/EntityService';
import { CreateEntity } from '@/settings/application/uses_cases/entity/create/CreateEntity';
import { SearchByCountryStateAndCity } from '@/settings/application/uses_cases/zipCod/search/SearchCitiesByCountryAndStateActive';
import { SearchByCountryStateAndCityActive } from '@/settings/application/uses_cases/zipCod/search/SearchByCountryStateAndCityActive';
import { ZipCodeRepository } from '@/settings/domain/ZipCode/ZipCodeRepository';
import { ZipCodeService } from '@/settings/infrastructure/services/zipCode/ZipCodeService';
import { SearchAll } from '@/settings/application/uses_cases/zipCod/search/SearchAll';
import { FindAllCommodityTypes } from '@/freight/application/commodity_type/search/FindAllCommodityType';
import { CommodityTypeService } from '@/freight/infrastructure/service/commodity/CommodityTypeService';
import { UpdateCommodityType } from '@/freight/application/commodity_type/update/UpdateCommodityType';
import { CreateCommodityType } from '@/freight/application/commodity_type/create/CreateCommodityType';
import { FindCommodityTypeByStatus } from '@/freight/application/commodity_type/search/FindCommodityTypesByStatus';
import { CommodityTypeRepository } from '@/freight/domain/commodity_type/CommodityTypeRepository';
import { CreateTimelineConfig } from '@/tracking/application/uses_cases/timelineConfig/create/CreateTimelineConfig';
import { TimelineConfigRepository } from '@/tracking/domain/timelineConfig/TimelineConfigRepository';
import { TimelineConfigServices } from '@/tracking/infrastructure/services/timelineConfig/TimelineConfigServices';
import { FindAllTimelineConfig } from '@/tracking/application/uses_cases/timelineConfig/search/findAll/FindAllTimelineConfig';
import { CreateTypeCustomer } from '@/settings/application/uses_cases/parmsGeneral/typeCustomer/create/CreateTypeCustomer';
import { UpdateTypeContact } from '@/settings/application/uses_cases/parmsGeneral/typeContact/update/UpdateTypeContact';
import { CreateTypeContact } from '@/settings/application/uses_cases/parmsGeneral/typeContact/create/CreateTypeContact';
import { TypeContactFindAll } from '@/settings/application/uses_cases/parmsGeneral/typeContact/search/TypeContactFindAll';
import { TypeContactRepository } from '@/settings/domain/ParmsGeneral/typeContact/TypeContactRepository';
import { TypeContactService } from '@/settings/infrastructure/services/ParmsGeneral/TypeContactService';
import { UpdateZoneL } from '@/wms/application/layout/zone/update/UpdateZoneL';
import { FindAllZone } from '@/wms/application/layout/zone/search/FindAllZone';
import { ZoneLRepository } from '@/wms/domain/layout/zone/ZoneLRepository';
import { ZoneLServices } from '@/wms/infrastructure/service/layout/zoneL/ZoneLServices';
import { CreateZoneL } from '@/wms/application/layout/zone/create/CreateZoneL';
import { CreateLayoutConfig } from '@/wms/application/layout/config/create/CreateLayoutConfig';
import { LayoutConfigRepository } from '@/wms/domain/layout/configResizing/LayoutConfigRepository';
import { LayoutConfigServices } from '@/wms/infrastructure/service/layout/config/LayoutConfigServices';
import { FindLayoutConfigByWarehouseAndStore } from '@/wms/application/layout/config/search/FindLayoutConfigByWarehouseAndStore';
import { SearchAllPorts } from '@/freight/application/port/search/SearchAllPorts';
import { CreatePaymentMethods } from '@/settings/application/uses_cases/parmsGeneral/paymentMethods/create/CreatePaymentMethods';
import { UpdatePaymentMethods } from '@/settings/application/uses_cases/parmsGeneral/paymentMethods/update/UpdatePaymentMethods';
import { PaymentMethodsFindAll } from '@/settings/application/uses_cases/parmsGeneral/paymentMethods/search/PaymentMethodsFindAll';
import { PaymentMethodsRepository } from '@/settings/domain/ParmsGeneral/paymentMethods/PaymentMethodsRepository';
import { PaymentMethodsService } from '@/settings/infrastructure/services/ParmsGeneral/PaymentMethodsService';
import { UpdateTypeAddress } from '@/settings/application/uses_cases/parmsGeneral/typeAddress/update/UpdateTypeAddress';
import { CreateTypeAddress } from '@/settings/application/uses_cases/parmsGeneral/typeAddress/create/CreateTypeAddress';
import { TypeAddressFindAll } from '@/settings/application/uses_cases/parmsGeneral/typeAddress/search/TypeAddressFindAll';
import { TypeAddressRepository } from '@/settings/domain/ParmsGeneral/typeAddress/TypeAddressRepository';
import { TypeAddressService } from '@/settings/infrastructure/services/ParmsGeneral/TypeAddressService';
import { EntityMaxCode } from '@/settings/application/uses_cases/entity/search/EntityMaxCode';
import { EntityFindById } from '@/settings/application/uses_cases/entity/search/EntityFindById';
import { EntityFindAll } from '@/settings/application/uses_cases/entity/search/EntityFindAll';
import { DeleteEventOnGeneralTimeline } from '@/tracking/application/uses_cases/events/delete/DeleteEventOnGeneralTimeline';
import { DeleteEventOnRouteTimeline } from '@/tracking/application/uses_cases/events/delete/deleteEventOnRouteTimeline';
import { EntityDeleteContact } from '@/settings/application/uses_cases/entity/delete/EntityDeleteContact';
import { EntityDeleteAddress } from '@/settings/application/uses_cases/entity/delete/EntityDeleteAddress';
import { CourierOrderRepository } from '@/courier/domain/CourierOrder/CourierOrderRepository';
import { CourierOrderServices } from '@/courier/infrastructure/service/courierOrder/CourierOrderServices';
import { CalculatePricesCourierOrder } from '@/courier/application/uses_cases/courierOrder/calculatePrices/CalculatePricesCourierOrder';
import { DeleteZoneLayoutConfig } from '@/wms/application/layout/config/delete/DeleteZoneLayoutConfig';
import { DeleteRackLayoutConfig } from '@/wms/application/layout/rack/delete/DeleteRackLayoutConfig';
import { FindAllTimelineConfigByStatus } from '@/tracking/application/uses_cases/timelineConfig/search/findAll/FindAllTimelineConfigByStatus';
import { OrderFindAllByModule } from '@/courier/application/uses_cases/order/search/OrderFindAllByModule';
import { DocumentRepository } from '@/courier/domain/documents/DocumentRepository';
import { DocumentServices } from '@/courier/infrastructure/service/documents/DocumentServices';
import { CreateDocument } from '@/courier/application/uses_cases/documents/create/CreateDocument';
import { DocumentFindAllByType } from '@/courier/application/uses_cases/documents/search/DocumentFindAllByType';
import { DeleteCommodityType } from '@/freight/application/commodity_type/delete/DeleteCommodityType';
import { DeleteVolume } from '@/settings/application/uses_cases/volume/delete/DeleteVolume';
import { DeleteWeight } from '@/settings/application/uses_cases/weight/delete/DeleteWeight';
import { StockRepository } from '@/wms/domain/stock/StockRepository';
import { StockServices } from '@/wms/infrastructure/service/stock/StockServices';
import { StockFindAll } from '@/wms/application/stock/search/FindallStock';
import { DocumentFindByPk } from '@/courier/application/uses_cases/documents/search/DocumentFindByPk';
import { DeletePackingUnits } from '@/settings/application/uses_cases/packing_units/delete/DeletePackingUnits';
import { FindPackagingByType } from '@/wms/application/packingUnit/search/FindPackagingByType';
import { DeleteActivity } from '@/tracking/application/uses_cases/activities/delete/DeleteActivity';
import { StateFindByCountryAndDescription } from '@/settings/application/uses_cases/state/search/StateFindByCountryAndDescription';
import { SearchCitiesByCountryStateAndDescription } from '@/settings/application/uses_cases/city/search/SearchCitiesByCountryStateAndDescription';
import { CountryFindByDescriptionLikeInGeneralTerritories } from '@/settings/application/uses_cases/country/search/CountryFindByDescriptionLikeInGeneralTerritories';
import { PricingZoneRepository } from '@/settings/domain/PricingZone/PricingZoneRepository';
import { PricingZoneServices } from '@/settings/infrastructure/services/PricingZone/PricingZoneServices';
import { SavePricingZone } from '@/settings/application/uses_cases/PricingZone/create/SavePricingZone';
import { UpdatePricingZone } from '@/settings/application/uses_cases/PricingZone/update/UpdatePricingZone';
import { PricingZoneFindAll } from '@/settings/application/uses_cases/PricingZone/search/PricingZoneFindAll';
import { PricingZoneFindByStatus } from '@/settings/application/uses_cases/PricingZone/search/PricingZoneFindByStatus';
import { FindAllMovements } from '@/wms/application/stock/search/FindAllMovements';
import { FindReferenceStock } from '@/wms/application/stock/search/FindReferenceStock';
import { DeletePricingZone } from '@/settings/application/uses_cases/PricingZone/delete/DeletePricingZone';
import { PricingZoneFindByOriginAndDestination } from '@/settings/application/uses_cases/PricingZone/search/PricingZoneFindByOriginAndDestination';
import { RoutesFindByOiriginAndDestination } from '@/settings/application/uses_cases/routes/search/RoutesFindByOiriginAndDestination';
import { CreateQuotation } from '@/courier/application/uses_cases/order/create/CreateQuotation';
import { WmsOrderFindAllByModule } from '@/courier/application/uses_cases/order/search/WmsOrderFindAllByModule';
import { WmsOrderFindByPk } from '@/courier/application/uses_cases/order/search/WmsOrderFindByPk';
import { PreRegisterRepository } from '@/wms/domain/preRegister/PreRegisterRepository';
import { PreRegisterServices } from '@/wms/infrastructure/service/preRegister/PreRegisterServices';
import { PreRegisterSave } from '@/wms/application/preRegister/create/PreRegisterSave';
import { PreRegisterFindAll } from '@/wms/application/preRegister/search/PreRegisterFindAll';
import { PreRegisterUpdate } from '@/wms/application/preRegister/update/PreRegisterUpdate';
import { PreRegisterFindByPk } from '@/wms/application/preRegister/search/PreRegisterFindByPk';
import { ReferenceFindAllServices } from '@/wms/application/catalogs/references/search/ReferenceFindAllServices';
import { ViewLocationRepository } from '@/settings/domain/views/location/ViewLocationRepository';
import { ViewLocationService } from '@/settings/infrastructure/services/views/location/ViewLocationService';
import { ShippingMethodRepository } from '@/settings/domain/shippingMethod/ShippingMethodRepository';
import { ShippingMethodService } from '@/settings/infrastructure/services/shippingMethod/ShippingMethodService';
import { SaveShippingMethod } from '@/settings/application/uses_cases/shippingMethod/create/SaveShippingMethod';
import { UpdateShippingMethod } from '@/settings/application/uses_cases/shippingMethod/update/UpdateShippingMethod';
import { DeleteShippingMethod } from '@/settings/application/uses_cases/shippingMethod/delete/DeleteShippingMethod';
import { FindAllShippingMethod } from '@/settings/application/uses_cases/shippingMethod/search/FindAllShippingMethod';
import { FindByStatusShippingMethod } from '@/settings/application/uses_cases/shippingMethod/search/FindByStatusShippingMethod';
import { FindByIdShippingMethod } from '@/settings/application/uses_cases/shippingMethod/search/FindByIdShippingMethod';
import { CommodityClassRepository } from '@/settings/domain/commodityClass/CommodityClassRepository';
import { CommodityClassService } from '@/settings/infrastructure/services/commodityClass/CommodityClassService';
import { CreateCommodityClass } from '@/settings/application/uses_cases/commodityClass/create/CreateCommodityClass';
import { UpdateCommodityClass } from '@/settings/application/uses_cases/commodityClass/update/UpdateCommodityClass';
import { DeleteCommodityClass } from '@/settings/application/uses_cases/commodityClass/delete/DeleteCommodityClass';
import { FindAllCommodityClass } from '@/settings/application/uses_cases/commodityClass/search/FindAllCommodityClass';
import { FindByCodeCommodityClass } from '@/settings/application/uses_cases/commodityClass/search/FindByCodeCommodityClass';
import { FindByStatusCommodityClass } from '@/settings/application/uses_cases/commodityClass/search/FindByStatusCommodityClass';
import { BillOfLandingRepository } from '@/wms/domain/billOfLanding/BillOfLandingRepository';
import { BillOfLandingServices } from '@/wms/infrastructure/service/billOfLanding/BillOfLandingService';
import { CreateBillOfLanding } from '@/wms/application/billOfLanding/create/CreateBillOfLanding';
import { FindallBillOfLanding } from '@/wms/application/billOfLanding/search/FindallBillOfLanding';
import { FindAllTimelineConfigByParams } from '@/tracking/application/uses_cases/timelineConfig/search/findAll/FindAllTimelineConfigByParams';
import { PreRegisterFindWithBulks } from '@/wms/application/preRegister/search/PreRegisterFindWithBulks';
import { ConceptsContainerRepository } from '@/settings/domain/containers/conceptsContainer/ConceptsContainerRepository';
import { ConceptContainerService } from '@/settings/infrastructure/services/container/ConceptsContainer/ConceptContainerService';
import { CreateConceptContainer } from '@/settings/application/uses_cases/containers/conceptsContainer/create/CreateConceptContainer';
import { UpdateConceptContainer } from '@/settings/application/uses_cases/containers/conceptsContainer/update/UpdateConceptContainer';
import { FindAllConceptsContainer } from '@/settings/application/uses_cases/containers/conceptsContainer/search/FindAllConceptContainer';
import { FindConceptContainerByCode } from '@/settings/application/uses_cases/containers/conceptsContainer/search/FindConceptContainerByCode';
import { ReferenceDelete } from '@/wms/application/catalogs/references/delete/ReferenceDelete';
import { GroupReferenceValidateCode } from '@/wms/application/category/groupReferences/validateCode/GroupReferenceValidateCode';
import { FindAllTimelineConfigByPk } from '@/tracking/application/uses_cases/timelineConfig/search/findAll/FindAllTimelineConfigByPk';
import { UpdateQuotation } from '@/courier/application/uses_cases/order/update/UpdateQuotation';
import { OrderFindAllByTypeAndState } from '@/courier/application/uses_cases/order/search/OrderFindAllByTypeAndState';
import { PreRegisterFindByDateAndType } from '@/wms/application/preRegister/search/PreRegisterFindByDateAndType';
import { DeleteZoneL } from '@/wms/application/layout/zone/delete/DeleteZone';
import { DocumentFindBySwtichsAndFilter } from '@/courier/application/uses_cases/documents/search/DocumentFindBySwtichsAndFilters';
import { CurrencyFindAllWithRate } from '@/settings/application/uses_cases/currency/search/CurrencyFindAllWithRate';
import { UpdateDocument } from '@/courier/application/uses_cases/documents/update/UpdateDocument';
import { CreateDocumentApplied } from '@/courier/application/uses_cases/documents/documentApplied/create/CreateDocumentApplied';
import { DocumentAppliedRepository } from '@/courier/domain/documents/documentApplied/DocumentAppliedRepository';
import { DocumentAppliedServices } from '@/courier/infrastructure/service/documents/documentApplied/DocumentAppliedServices';
import { FindByDateRangeAndStore } from '@/wms/application/stock/search/FindByDateRangeAndStore';
import { CalculateAverageCost } from '@/wms/application/stock/search/CalculateAverageCost';
import { PreRegisterFindByDateAndLikeType } from '@/wms/application/preRegister/search/PreRegisterFindByDateAndLikeType';
import { ProductRepository } from '@/wms/domain/catalogs/product/ProductRepository';
import { ProductServices } from '@/wms/infrastructure/service/catalogs/product/ProductServices';
import { CreateProduct } from '@/wms/application/catalogs/product/create/CreateProduct';
import { UpdateProduct } from '@/wms/application/catalogs/product/update/UpdateProduct';
import { ProductFindBySKU } from '@/wms/application/catalogs/product/search/ProductFindBySKU';
import { PersonRepository } from '@/settings/domain/person/PersonRepository';
import { CreatePerson } from '@/settings/application/uses_cases/person/create/CreatePerson';
import { UpdatePerson } from '@/settings/application/uses_cases/person/update/UpdatePerson';
import { PersonServices } from '@/settings/infrastructure/services/person/PersonService';
import { PersonFindByDocument } from '@/settings/application/uses_cases/person/search/PersonFindByDocument';
import { UserErpRepository } from '@/settings/domain/userERP/UserErpRepository';
import { CreateUserERP } from '@/settings/application/uses_cases/userERP/create/CreateUserERP';
import { UpdateUserERP } from '@/settings/application/uses_cases/userERP/update/UpdateUserERP';
import { UserErpServices } from '@/settings/infrastructure/services/userERP/UserErpServices';
import { FindUserErpByEmail } from '@/settings/application/uses_cases/userERP/search/FindUserErpByEmail';
import { SearchPreEntityById } from '@/settings/application/uses_cases/pre_entity/search/SearchPreEntityById';
import { CreateEmailConfig } from '@/settings/application/uses_cases/company/create/CreateEmailConfig';
import { FindEmailConfigByCompany } from '@/settings/application/uses_cases/company/search/FindEmailConfigByCompany';
import { EmailTypeRepository } from '@/settings/domain/emailType/EmailTypeRepository';
import { EmailTypeService } from '@/settings/infrastructure/services/emailTypes/EmailTypeService';
import { EmailTypeFindAll } from '@/settings/application/uses_cases/emailType/search/EmailTypeFindAll';
import { EmailTypeConfigRepository } from '@/settings/domain/emailTypeConfig/EmailTypeConfigRepository';
import { EmailTypeConfigService } from '@/settings/infrastructure/services/emailTypeConfig/EmailTypeConfigService';
import { SaveEmailTypeConfig } from '@/settings/application/uses_cases/emailTypeConfig/create/SaveEmailTypeConfig';
import { ReferenceGroupLevelRepository } from '@/wms/domain/referenceGroupLevel/ReferenceGroupLevelRepository';
import { ReferenceGroupLevelServices } from '@/wms/infrastructure/service/referenceGroupLevel/ReferenceGroupLevelServices';
import { CreateReferenceGroupLevel } from '@/wms/application/referenceGroupLevel/create/CreateReferenceGroupLevel';
import { UpdateReferenceGroupLevel } from '@/wms/application/referenceGroupLevel/update/UpdateReferenceGroupLevel';
import { DeleteReferenceGroupLevel } from '@/wms/application/referenceGroupLevel/delete/DeleteReferenceGroupLevel';
import { FindByIdReferenceGroupLevel } from '@/wms/application/referenceGroupLevel/search/FindByIdReferenceGroupLevel';
import { FindByParentReferenceGroupLevel } from '@/wms/application/referenceGroupLevel/search/FindByParentReferenceGroupLevel';
import { FindAllReferenceGroupLevel } from '@/wms/application/referenceGroupLevel/search/FindAllReferenceGroupLevel';
import { GetTreeReferenceGroupLevel } from '@/wms/application/referenceGroupLevel/search/GetTreeReferenceGroupLevel';
import { FindAllByStatusRack } from '@/wms/application/layout/rack/search/FindAllByStatusRack';
import { UpdateSwitchsStatus } from '@/tracking/application/uses_cases/switches/update/UpdateSwitchsStatus';
import { DocumentsV2Repository } from '@/courier/domain/DocumentsV2/DocumentsRepository_V2';
import { DocumentsV2Service } from '@/courier/infrastructure/service/DocumentsV2/Documents_V2_Service';
import { SaveDocumentsV2 } from '@/courier/application/uses_cases/DocumentsV2/create/CreateDocumentsV2';
import { UpdateDocumentsV2 } from '@/courier/application/uses_cases/DocumentsV2/update/UpdateDocumentsV2';
import { FindDocumentsByPkV2 } from '@/courier/application/uses_cases/DocumentsV2/search/FindDocumentsByPkV2';
import { FindDocumentsBySwAndFiltersV2 } from '@/courier/application/uses_cases/DocumentsV2/search/FindDocumentsBySwAndFiltersV2';
import { FindAllDocumentsByTypeV2 } from '@/courier/application/uses_cases/DocumentsV2/search/FindAllDocumentsByTypeV2';
import { TypeDocumentRepository } from '@/settings/domain/TypeDocument/TypeDocumentRepository';
import { TypeDocumentService } from '@/settings/infrastructure/services/TypeDocument/TypeDocumentService';
import { TypeDocumentSave } from '@/settings/application/uses_cases/TypeDocument/create/CreateTypeDocument';
import { UpdateTypeDocument } from '@/settings/application/uses_cases/TypeDocument/update/UpdateTypeDocument';
import { DeleteTypeDocument } from '@/settings/application/uses_cases/TypeDocument/delete/DeleteTypeDocument';
import { FindAllTypeDocument } from '@/settings/application/uses_cases/TypeDocument/search/FindAllTypeDocument';
import { FindByIdTypeDocument } from '@/settings/application/uses_cases/TypeDocument/search/FindByIdTypeDocument';
import { ContableConceptsRepository } from '@/courier/domain/ContableConcepts/ContableConceptsRepository';
import { ContableConceptsService } from '@/courier/infrastructure/service/ContableConcepts/ContableConceptsService';
import { CreateContableConcept } from '@/courier/application/uses_cases/ContableConcepts/create/CreateContableConcept';
import { UpdateContableConcepts } from '@/courier/application/uses_cases/ContableConcepts/update/UpdateContableConcept';
import { DeleteContableConcepts } from '@/courier/application/uses_cases/ContableConcepts/delete/DeleteContableConcept';
import { FindAllContableConcepts } from '@/courier/application/uses_cases/ContableConcepts/search/FindAllContableConcepts';
import { FindByDocumentTypeContableConcept } from '@/courier/application/uses_cases/ContableConcepts/search/FindByDocumentTypeContableConcept';
import { FindByIdContableConcepts } from '@/courier/application/uses_cases/ContableConcepts/search/FindByIdContableConcept';
import { FindByStatusContableConcepts } from '@/courier/application/uses_cases/ContableConcepts/search/FindByStatusContableConcept';
import { HouseRepository } from '@/courier/domain/House/HouseRepository';
import { HouseService } from '@/courier/infrastructure/service/House/HouseService';
import { FindHouseByEntityAndMaster } from '@/courier/application/uses_cases/House/search/FindHouseByEntityAndMaster';
import { FindDocumentsBySwAndDateRangeV2 } from '@/courier/application/uses_cases/DocumentsV2/search/FindDocumentsBySwAndDateRangeV2';
import { CloudConfigRepository } from '@/settings/domain/cloudConfig/CloudConfigRepository';
import { CloudConfigService } from '@/settings/infrastructure/services/cloudConfig/CloudConfigService';
import { FindByCompanyIdCloudConfig } from '@/settings/application/uses_cases/cloudConfig/search/FindByCompanyIdCloudConfig';
import { CreateCloudConfig } from '@/settings/application/uses_cases/cloudConfig/create/CreateCloudConfig';
import { LoginImageRepository } from '@/settings/domain/loginImage/LoginImageRepository';
import { LoginImageService } from '@/settings/infrastructure/services/loginImage/LoginImageService';
import { LoginImageFindAll } from '@/settings/application/uses_cases/loginImage/search/LoginImageFindAll';
import { LoginImageConfigRepository } from '@/settings/domain/loginImageConfig/LoginImageConfigRepository';
import { LoginImageConfigService } from '@/settings/infrastructure/services/loginImageConfig/LoginImageConfigService';
import { SaveLoginImageConfig } from '@/settings/application/uses_cases/loginImageConfig/create/SaveLoginImageConfig';
import { MenuRepository } from '@/settings/domain/menu/MenuRepository';
import { MenuServices } from '@/settings/infrastructure/services/menu/MenuServices';
import { FindAllMenu } from '@/settings/application/uses_cases/menu/search/FindAllMenu';
import { FindMenuById } from '@/settings/application/uses_cases/menu/search/FindMenuById';
import { CreateMenu } from '@/settings/application/uses_cases/menu/create/CreateMenu';
import { UpdateMenu } from '@/settings/application/uses_cases/menu/update/UpdateMenu';
import { DeleteMenu } from '@/settings/application/uses_cases/menu/delete/DeleteMenu';
import { FindDocumentsByMaster } from '@/courier/application/uses_cases/DocumentsV2/search/FindDocumentsByMaster';
import { PreRegisterEventLogDetailRepository } from '@/wms/domain/preRegisterEventLogDetail/PreRegisterEventLogDetailRepository';
import { PreRegisterEventLogDetailService } from '@/wms/infrastructure/service/preRegisterEventLogDetail/PreRegisterEventLogDetailService';
import { PreRegisterEventLogDetailSave } from '@/wms/application/preRegisterEventLogDetail/create/PreRegisterEventLogDetailSave';
import { PreRegisterEventLogDetailFindDetail } from '@/wms/application/preRegisterEventLogDetail/search/PreRegisterEventLogDetailFindDetail';
import { ActivityTransactionalRepository } from '@/tracking/domain/activitiesTransactional/ActivityTransactionalRepository';
import { ActivityTransactionalService } from '@/tracking/infrastructure/services/activitiesTransactional/ActivityTransactionalService';
import { ActivityTransactionalUpdateStatus } from '@/tracking/application/uses_cases/activitiesTransactional/update/ActivityTransactionalUpdateStatus';
import { AirportRepository } from '@/settings/domain/airport/AirportRepository';
import { AirportService } from '@/settings/infrastructure/services/airport/AirportService';
import { CreateAirport } from '@/settings/application/uses_cases/airport/create/CreateAirport';
import { UpdateAirport } from '@/settings/application/uses_cases/airport/update/UpdateAirport';
import { FindByCodeAirport } from '@/settings/application/uses_cases/airport/search/FindByCodeAirport';
import { FindAllAirport } from '@/settings/application/uses_cases/airport/search/FindAllAirport';
import { CheckExistBillOfLanding } from '@/wms/application/billOfLanding/search/CheckExistBillOfLanding';
import { PreRegisterChangeType } from '@/wms/application/preRegister/update/PreRegisterChangeType';
import { FindByNameConsignee } from '@/settings/application/uses_cases/consignee/search/FindByNameConsignee';
import { ConsigneeRepository } from '@/settings/domain/consignee/ConsigneeRepository';
import { ConsigneeService } from '@/settings/infrastructure/services/consignee/ConsigneeService';
import { FindTransactionalActivityById } from '@/tracking/application/uses_cases/activitiesTransactional/search/FindTransactionalActivityById';
import { PriceListImportWeight } from '@/settings/application/uses_cases/priceList/create/weight/PriceListImportWeight';
import { PriceListVolumeServices } from '@/settings/infrastructure/services/priceList/Volume/PriceListVolumeService';
import { PriceListWeightServices } from '@/settings/infrastructure/services/priceList/Weight/PriceListWeightService';
import { PriceListImport } from '@/settings/application/uses_cases/priceList/create/volume/PriceListImportVolume';
import { DeleteCurrency } from '@/settings/application/uses_cases/currency/delete/DeleteCurrency';
import { ViewLocationFindAllStoreAndCommodity } from '@/settings/application/uses_cases/views/location/search/ViewLocationFindAllStoreAndCommodity';
import { DeleteUser } from '@/settings/application/uses_cases/user/delete/DeleteUser';
import { ZoneLevelRepository } from '@/settings/domain/ZoneLevel/ZoneLevelRepository';
import { ZoneLevelService } from '@/settings/infrastructure/services/ZoneLevel/ZoneLevelService';
import { DeleteZoneLevel } from '@/settings/application/uses_cases/ZoneLevel/delete/DeleteZoneLevel';
import { GeneralPortRepository } from '@/settings/domain/generalPort/GeneralPortsRepository';
import { GeneralPortService } from '@/settings/infrastructure/services/generalPort/GeneralPortService';
import { SearchGeneralPortByQuery } from '@/settings/application/uses_cases/generalPort/search/SearchGeneralPortByQuery';
import { FindFreightStockByDateRangeAndStore } from '@/wms/application/stock/search/FindFreightStockByDateRangeAndStore';
import { FindFreightMovements } from '@/wms/application/stock/search/FindFreightMovements';
import { DeleteConceptContainer } from '@/settings/application/uses_cases/containers/conceptsContainer/delete/DeleteConceptContainer';
import { AssignStoreToUser } from '@/settings/application/uses_cases/user/update/AssignStoreToUser';
import { FindAllStoreByUser } from '@/wms/application/layout/store/search/FindAllStoreByUser';
import { FindAllStoreForUserAssign } from '@/wms/application/layout/store/search/FindAllStoreForUserAssign';
import { BulkRepository } from '@/wms/domain/bulk/BulkRepository';
import { BulkServices } from '@/wms/infrastructure/service/bulk/BulkServices';
import { FindBulksInStock } from '@/wms/application/bulk/search/FindBulksInStock';
import { UpdateBulkPickingStatus } from '@/wms/application/bulk/update/UpdateBulkPickingStatus';
import { FindPickedBulks } from '@/wms/application/bulk/search/FindPickedBulks';
import { BranchRepository } from '@/settings/domain/branch/BranchRepository';
import { BranchService } from '@/settings/infrastructure/services/branch/BranchService';
import { FindBranchByCode } from '@/settings/application/uses_cases/branch/search/FindBranchByCode';
import { FindAllBranch } from '@/settings/application/uses_cases/branch/search/FindAllBranch';
import { CreateBranch } from '@/settings/application/uses_cases/branch/create/CreateBranch';
import { UpdateBranch } from '@/settings/application/uses_cases/branch/update/UpdateBranch';
import { DeleteBranch } from '@/settings/application/uses_cases/branch/delete/DeleteBranch';
import { FindBranchByStatus } from '@/settings/application/uses_cases/branch/search/FindBranchByStatus';
import { FindPickedBulksByStoreAndDate } from '@/wms/application/bulk/search/FindPickedBulksByStoreAndDate.1';
import { ConsolidateBulks } from '@/wms/application/bulk/update/ConsolidateBulks';
import { DeconsolidateBulks } from '@/wms/application/bulk/update/DeconsolidateBulks';
import { FindConsolidatedBulks } from '@/wms/application/bulk/search/FindConsolidatedBulks';
import { CreateGamma } from '@/settings/application/uses_cases/gamma/gamma/create/CreateGamma';
import { GammaRepository } from '@/wms/domain/gamma/GammaRepository';
import GammaServices from '@/wms/infrastructure/service/gamma/GammaServices';
import { DeleteGamma } from '@/settings/application/uses_cases/gamma/gamma/delete/DeleteGamma';
import { FindAllGamma } from '@/settings/application/uses_cases/gamma/gamma/search/GammaFindall';
import { CreateColor } from '@/settings/application/uses_cases/gamma/color/create/CreateColor';
import { DeleteColor } from '@/settings/application/uses_cases/gamma/color/delete/DeleteColor';
import { FindAllColor } from '@/settings/application/uses_cases/gamma/color/search/ColorFindall';
import { CreateSize } from '@/settings/application/uses_cases/gamma/size/create/CreateSize';
import { DeleteSize } from '@/settings/application/uses_cases/gamma/size/delete/DeleteSize';
import { FindAllSize } from '@/settings/application/uses_cases/gamma/size/search/SizeFindall';
import { ColorRepository } from '@/wms/domain/gamma/ColorRepository';
import ColorServices from '@/wms/infrastructure/service/gamma/ColorServices';
import { SizeRepository } from '@/wms/domain/gamma/SizeRepository';
import SizeServices from '@/wms/infrastructure/service/gamma/SizeServices';
import { UpdateGamma } from '@/settings/application/uses_cases/gamma/gamma/update/UpdateGamma';
import { UpdateColor } from '@/settings/application/uses_cases/gamma/color/update/UpdateColor';
import { FindReferencesByMainSku } from '@/wms/application/catalogs/references/search/FindReferencesByMainSku';
import { FindAllBillOfLandingWithOperationalData } from '@/wms/application/billOfLanding/search/FindallBillOfLandingWithOperationalData';
import { ReferenceUpdateBarcode } from '@/wms/application/catalogs/references/update/ReferenceUpdateBarcode';
import { CreateFileV2 } from '@/settings/application/uses_cases/file/create/CreateFileV2';
import { FindFile } from '@/settings/application/uses_cases/file/search/FindFile';
import { CreateSerial } from '@/wms/application/catalogs/references/create/CreateSerial';
import { DeleteSerials } from '@/wms/application/catalogs/references/delete/SerialDelete';
import { FindAllAssociatedSerialsByReference } from '@/wms/application/catalogs/references/search/SerialFindAllByReference';
import { SearchEmailTypeConfig } from '@/settings/application/uses_cases/emailTypeConfig/search/SearchEmailTypeConfig';
import { CreateCourierPriceList } from '@/settings/application/uses_cases/courierPriceList/create/CreateCourierPriceList';
import { DeleteCourierPriceList } from '@/settings/application/uses_cases/courierPriceList/delete/DeleteCourierPriceList';
import { UpdateCourierPriceList } from '@/settings/application/uses_cases/courierPriceList/update/UpdateCourierPriceList';
import { FindAllCourierPriceList } from '@/settings/application/uses_cases/courierPriceList/search/FindAllCourierPriceList';
import { FindByStatusCourierPriceList } from '@/settings/application/uses_cases/courierPriceList/search/FindByStatusCourierPriceList';
import { FindByIdCourierPriceList } from '@/settings/application/uses_cases/courierPriceList/search/FindByIdCourierPriceList';
import { CourierPriceListRepository } from '@/settings/domain/courierPriceList/CourierPriceListRepository';
import { CourierPriceListService } from '@/settings/infrastructure/services/courierPriceList/CourierPriceListService';
import { FindZoneNotConfigured } from '@/wms/application/layout/zone/search/FindAllZoneNotConfigured';
import { SetRacksToConfigZone } from '@/wms/application/layout/config/update/SetRacksToConfigZone';
import { DeleteTypeIdentification } from '@/settings/application/uses_cases/parmsGeneral/typeIdentificacion/delete/TypeIdentificationDelete';
import { AssignReferencesToTimeline } from '@/wms/application/catalogs/references/create/AssignReferencesToTimeline';
import { ReferenceFindByTimeline } from '@/wms/application/catalogs/references/search/ReferenceFindByTimeline ';
import { ReferenceFindCodeByTimeline } from '@/wms/application/catalogs/references/search/ReferenceFindCodeByTimeline';
import { DocsLinRepository } from '@/courier/domain/orderline/DocsLinRepository';
import { DocsLinService } from '@/courier/infrastructure/service/DocsLin/DocsLinService';
import { GetDocsLinByTimeline } from '@/courier/application/uses_cases/DocsLin/search/GetDocsLinByTimeline';
import { DeletePaymentMethods } from '@/settings/application/uses_cases/parmsGeneral/paymentMethods/delete/DeletePaymentMethods';
import { DeleteGroupAgentService } from '@/settings/application/uses_cases/parmsGeneral/groupAgentService/delete/DeleteGroupAgentService';
import { DeleteTypeContact } from '@/settings/application/uses_cases/parmsGeneral/typeContact/delete/DeleteTypeContact';
import { DeleteIncoterms } from '@/settings/application/uses_cases/parmsGeneral/incoterms/delete/DeleteIncoterms';
import { DeleteTermsPayment } from '@/settings/application/uses_cases/parmsGeneral/termsPayment/delete/DeleteTermsPayment';
import { DeleteTypeAddress } from '@/settings/application/uses_cases/parmsGeneral/typeAddress/delete/DeleteTypeAddress';
import { DeleteClassReferences } from '@/wms/application/catalogs/classReferences/delete/DeleteClassReferences';
import { DeleteCategoryReferences } from '@/wms/application/catalogs/categoryReferences/delete/DeleteCategoryReferences';
import { GetTemporalToken } from '@/courier/application/uses_cases/login/search/findtoken/GetTemporalToken';
import { GetWorkspaces } from '@/courier/application/uses_cases/login/search/findtoken/GetWorkspaces';
import { LoginWithWorkspace } from '@/courier/application/uses_cases/login/search/findtoken/LoginWithWorkspace';
import { FindMasterBLByPickingFilters } from '@/wms/application/billOfLanding/search/FindMasterBLByPickingFilters';
import { FindBulksForPickingByHouseBL } from '@/wms/application/bulk/search/FindBulksForPickingByHouseBL';
import { FindBulksByPickingLists } from '@/wms/application/bulk/search/FindBulksByPickingLists';
import { PickingListRepository } from '@/freight/domain/pickingList/PickingListRepository';
import { PickingListService } from '@/freight/infrastructure/service/pickingList/PickingListService';
import { FindPickingListsByDate } from '@/freight/application/pickingList/search/FindPickingListsByDate';
import { FindBulksForPickingByFilters } from '@/wms/application/bulk/search/FindBulksForPickingByFilters';
import { FindNoShippedPickingListsNumber } from '@/freight/application/pickingList/search/FindNoShippedPickingListsNumber';
import { FindPickingListDataForReport } from '@/freight/application/pickingList/search/FindPickingListDataForReport';
import { FindHouseSerialsByDateRange } from '@/courier/application/uses_cases/House/search/FindHouseSerialsByDateRange';
import { FindMasterBlByHouses } from '@/wms/application/billOfLanding/search/FindMasterBlByHouses';
import { FindDataForReportBillOfLading } from '@/wms/application/billOfLanding/search/FindDataForReportBillfOfLading';
import { HouseBillOfLandingRepository } from '@/wms/domain/houseBillOfLading/HouseBillOfLadingRepository';
import { HouseBillOfLandingServices } from '@/wms/infrastructure/service/houseBillOfLading/HouseBillOfLadingService';
import { FindDataForReportHouseBillOfLading } from '@/wms/application/houseBillOfLading/search/FindDataForReportHouseBL';
import { FindBulksForWarehouseReport } from '@/wms/application/bulk/search/FindBulksForWarehouseReport';
import { FindShippedPickingListsNumberByDateRange } from '@/freight/application/pickingList/search/FindShippedPickingListsNumberByDateRange';
import { ConsolidatedRepository } from '@/wms/domain/consolidated/ConsolidatedRepository';
import { ConsolidatedService } from '@/wms/infrastructure/service/consolidated/ConsolidatedService';
import { FindConsolidatedDataForReport } from '@/wms/application/consolidated/FindConsolidatedDataForReport';
import { FindConsolidatedNumbersByDateRange } from '@/wms/application/consolidated/FindConsolidatedNumbersByDateRange';
import { PreRegisterCreateReport } from '@/wms/application/preRegister/search/PreRegisterCreateReport';
import { PreRegisterFindForTraceability } from '@/wms/application/preRegister/search/PreRegisterFindForTraceabilityPanel';
import { PreRegisterFindForDashboard } from '@/wms/application/preRegister/search/PreRegisterFindForDashboard';
import { EventTransactionalRepository } from '@/tracking/domain/eventsTransactional/EventTransactionalRepository';
import { TransactionalEventService } from '@/tracking/infrastructure/services/transactionalEvent/TransactionalEventService';
import { TransactionalEventFindByPk } from '@/tracking/application/uses_cases/transactionalEvent/search/TransactionalEventFindByPk';
import { FindActivitiesForTraceabilityByEvent } from '@/tracking/application/uses_cases/activitiesTransactional/search/FindActivitiesForTraceabilityByEvent';
import { CreateBillOfLandingForPreRegister } from '@/wms/application/billOfLanding/create/CreateBillOfLandingForPreRegister';
import { FindMasterBLByPreRegister } from '@/wms/application/billOfLanding/search/FindMasterBLByPreRegister';
import { FindConsigneeByOperation } from '@/settings/application/uses_cases/consignee/search/FindConsigneeByOperation';
import { FindBulkCubeDataByOperation } from '@/wms/application/bulk/search/FindBulkCubeDataByOperation';
import { BulkCubeProcess } from '@/wms/application/bulk/update/BulkCubeProcess';
import { FindHousesByOperation } from '@/wms/application/houseBillOfLading/search/FindHousesByOperation';
import { SaveHouseBillOfLanding } from '@/wms/application/houseBillOfLading/create/SaveHouseBillOfLanding';
import { UserFindAllForList } from '@/settings/application/uses_cases/user/search/UserFindAllForList';
import { UserAssignRole } from '@/settings/application/uses_cases/user/update/UserAssignRole';
import { CreateRoleV2 } from '@/settings/application/uses_cases/role/create/CreateRoleV2';
import { UpdateRoleV2 } from '@/settings/application/uses_cases/role/update/UpdateRoleV2';
import { InviteUserToNamespace } from '@/settings/application/uses_cases/user/create/InviteUserToNamespace';
import { ConfirmToken } from '@/settings/application/uses_cases/user/create/ConfirmToken';
import { FindMenuByRole } from '@/settings/application/uses_cases/menu/search/FindMenuByRole';
import { AssignMenuToRole } from '@/settings/application/uses_cases/menu/update/AssignMenuToRole';
import { RemoveUserFromNamespace } from '@/settings/application/uses_cases/user/delete/RemoveUserFromNamespace';
import { CreateBillOfLandingReport } from '@/wms/application/billOfLanding/create/CreateBillOfLandingReport';
import { ResetPassword } from '@/settings/application/uses_cases/user/update/ResetPassword';
import { UpdateUserProfile } from '@/settings/application/uses_cases/user/update/UpdateUserProfile';
import { UserFindByEmail } from '@/settings/application/uses_cases/user/search/UserFindByEmail';
import { FindPackagingByReferenceAndType } from '@/wms/application/catalogs/packaging/search/FindPackagingByReferenceAndType';
import { FindPaymentsForPreRegister } from '@/courier/application/uses_cases/DocumentsV2/search/FindPaymentsForPreRegister';
import { FindBulkByOperation } from '@/wms/application/bulk/search/FindBulkByOperation';
import { ReferencesFindByQuery } from '@/wms/application/catalogs/references/search/ReferencesFindByQuery';
import { PreparationService } from '@/freight/infrastructure/service/preparation/PreparationService';
import { PreparationRepository } from '@/freight/domain/Preparation/PreparationRepository';
import { CreatePreparation } from '@/freight/application/Preparation/create/CreatePreparation';
import { UpdatePreparation } from '@/freight/application/Preparation/update/UpdatePreparation';
import { DeletePreparation } from '@/freight/application/Preparation/delete/DeletePreparation';
import { FindAllPreparations } from '@/freight/application/Preparation/search/FindAllPreparations';
import { FindPreparationById } from '@/freight/application/Preparation/search/FindPreparationById';
import { FindAllPreparationsByStatus } from '../../freight/application/Preparation/search/FindAllPreparationsByStatus';

export class Container {
  private static _instance: Container | null = null;
  private readonly _container: interfaces.Container;
  private constructor() {
    //-------------------------------------------------
    //PreEntity
    container
      .bind<SearchPreEntityByFullNameLike>(TYPES.PRE_ENTITY_FIND_BY_DESCRIPTION_LIKE)
      .to(SearchPreEntityByFullNameLike)
      .inSingletonScope();
    container
      .bind<SearchPreEntityById>(TYPES.API_PRE_ENTITY_FIND_BY_ID)
      .to(SearchPreEntityById)
      .inSingletonScope();
    container
      .bind<PreEntityRepository>(TYPES.API_PRE_ENTITY_REPOSITORY)
      .to(PreEntityService)
      .inSingletonScope();
    //Entity
    container
      .bind<EntityFindFilter>(TYPES.ENTITY_FIND_BY_DESCRIPTION_LIKE)
      .to(EntityFindFilter)
      .inSingletonScope();
    container
      .bind<CreateEntity>(TYPES.API_ENTITY_SAVE)
      .to(CreateEntity)
      .inSingletonScope();
    container
      .bind<EntityRepository>(TYPES.API_ENTITY_REPOSITORY)
      .to(EntityService)
      .inSingletonScope();
    container
      .bind<EntityFindAll>(TYPES.API_ENTITY_FIND_ALL_PAGE)
      .to(EntityFindAll)
      .inSingletonScope();
    //Email config
    container
      .bind<CreateEmailConfig>(TYPES.EMAIL_CONFIG_SAVE)
      .to(CreateEmailConfig)
      .inSingletonScope();
    container
      .bind<FindEmailConfigByCompany>(TYPES.EMAIL_CONFIG_FIND_BY_COMPANY)
      .to(FindEmailConfigByCompany)
      .inSingletonScope();
    container
      .bind<SearchEmailTypeConfig>(TYPES.EMAIL_TYPE_CONFIG_FIND_BY_COMPANY_AND_TYPE)
      .to(SearchEmailTypeConfig)
      .inSingletonScope();
    //-------------------------------------------------

    /**
     * Email Types
     */
    container
      .bind<EmailTypeRepository>(TYPES.EMAIL_TYPE_REPOSITORY)
      .to(EmailTypeService)
      .inSingletonScope();
    container
      .bind<EmailTypeFindAll>(TYPES.EMAIL_TYPE_FIND_ALL)
      .to(EmailTypeFindAll)
      .inSingletonScope();

    /**Email type config */
    container
      .bind<EmailTypeConfigRepository>(TYPES.EMAIL_TYPE_CONFIG_REPOSITORY)
      .to(EmailTypeConfigService)
      .inSingletonScope();
    container
      .bind<SaveEmailTypeConfig>(TYPES.EMAIL_TYPE_CONFIG_SAVE)
      .to(SaveEmailTypeConfig)
      .inSingletonScope();

    //City
    container
      .bind<CreateCity>(TYPES.CITY_CREATE)
      .to(CreateCity)
      .inSingletonScope();
    container
      .bind<UpdateCity>(TYPES.CITY_UPDATE)
      .to(UpdateCity)
      .inSingletonScope();
    container
      .bind<DeleteCity>(TYPES.CITY_DELETE)
      .to(DeleteCity)
      .inSingletonScope();
    container
      .bind<SearchCitiesByCountryAndStateActive>(TYPES.CITY_SEARCH_BY_COUNTRY_AND_STATE)
      .to(SearchCitiesByCountryAndStateActive)
      .inSingletonScope();
    container
      .bind<SearchCityByDescriptionLike>(TYPES.CITY_SEARCH_BY_DESCRIPTION_LIKE)
      .to(SearchCityByDescriptionLike)
      .inSingletonScope();
    container
      .bind<SearchCitiesByCountryStateAndDescription>(TYPES.CITY_SEARCH_BY_COUNTRY_STATE_AND_DESCRIPTION)
      .to(SearchCitiesByCountryStateAndDescription)
      .inSingletonScope();
    container
      .bind<CityRepository>(TYPES.CITY_REPOSITORY)
      .to(CityService)
      .inSingletonScope();
    //-------------------------------------------------
    //zipcode
    container
      .bind<SearchByCountryStateAndCity>(TYPES.ZIPCODE_SEARCH_BY_COUNTRY_STATE_CITY)
      .to(SearchByCountryStateAndCity)
      .inSingletonScope();
    container
      .bind<SearchAll>(TYPES.ZIPCODE_SEARCH_ALL)
      .to(SearchAll)
      .inSingletonScope();
    container
      .bind<SearchByCountryStateAndCityActive>(TYPES.ZIPCODE_SEARCH_BY_COUNTRY_STATE_CITY_ACTIVE)
      .to(SearchByCountryStateAndCityActive)
      .inSingletonScope();
    container
      .bind<ZipCodeRepository>(TYPES.ZIPCODE_REPOSITORY)
      .to(ZipCodeService)
      .inSingletonScope();
    //-------------------------------------------------
    //Vistas
    container
      .bind<SearchTerritoryByQueryDescription>(TYPES.API_VIEW_FIND_TERRITORIES_BY_QUERY)
      .to(SearchTerritoryByQueryDescription)
      .inSingletonScope();
    container
      .bind<SearchTerritoriesByQueryParameter>(TYPES.VIEW_FIND_TERRITORIES)
      .to(SearchTerritoriesByQueryParameter)
      .inSingletonScope();
    container
      .bind<TerritoriesRepository>(TYPES.VIEW_TERRITORIES_REPOSITORY)
      .to(TerritoryService)
      .inSingletonScope();
    //Ports
    container
      .bind<SearchAllPorts>(TYPES.PORT_SEARCH_ALL)
      .to(SearchAllPorts)
      .inSingletonScope();
    container
      .bind<SearchPortsByStatus>(TYPES.SEARCH_PORTS_BY_STATUS)
      .to(SearchPortsByStatus)
      .inSingletonScope();
    container
      .bind<UpdatePort>(TYPES.PORT_UPDATE)
      .to(UpdatePort)
      .inSingletonScope();
    container
      .bind<CreatePort>(TYPES.PORT_CREATE)
      .to(CreatePort)
      .inSingletonScope();
    container
      .bind<SearchPortsByDescriptionLike>(TYPES.SEARCH_PORTS_BY_DESCRIPTION_LIKE)
      .to(SearchPortsByDescriptionLike)
      .inSingletonScope();
    container
      .bind<PortRepository>(TYPES.PORT_REPOSITORY)
      .to(PortService)
      .inSingletonScope();
    //-------------------------------------------------
    //Packing units
    container
      .bind<CreatePackingUnits>(TYPES.CREATE_PACKING_UNITS)
      .to(CreatePackingUnits)
      .inSingletonScope();

    container
      .bind<UpdatePackingUnits>(TYPES.UPDATE_PACKING_UNITS)
      .to(UpdatePackingUnits)
      .inSingletonScope();
    container
      .bind<DeletePackingUnits>(TYPES.DELETE_PACKINGUNIT_REFERENCE)
      .to(DeletePackingUnits)
      .inSingletonScope();

    container
      .bind<FindPackingUnitById>(TYPES.FIND_PACKING_UNITS_BY_ID)
      .to(FindPackingUnitById)
      .inSingletonScope();

    container
      .bind<FindAllPackingUnits>(TYPES.FIND_ALL_PACKING_UNITS)
      .to(FindAllPackingUnits)
      .inSingletonScope();

    container
      .bind<FindAllPackingUnitsByStatus>(TYPES.FIND_PACKING_UNITS_BY_STATUS)
      .to(FindAllPackingUnitsByStatus)
      .inSingletonScope();

    container
      .bind<PackingUnitsRepository>(TYPES.PACKING_UNITS_REPOSITORY)
      .to(PackingUnitServices)
      .inSingletonScope();

    //-------------------------------------------------
    //Containers
    container
      .bind<CreateContainer>(TYPES.CREATE_CONTAINER)
      .to(CreateContainer)
      .inSingletonScope();

    container
      .bind<UpdateContainer>(TYPES.UPDATE_CONTAINER)
      .to(UpdateContainer)
      .inSingletonScope();

    container
      .bind<FindContainerById>(TYPES.FIND_CONTAINER_BY_ID)
      .to(FindContainerById)
      .inSingletonScope();

    container
      .bind<FindAllContainers>(TYPES.FIND_ALL_CONTAINERS)
      .to(FindAllContainers)
      .inSingletonScope();

    container
      .bind<FindAllContainersByStatus>(TYPES.FIND_CONTAINER_BY_STATUS)
      .to(FindAllContainersByStatus)
      .inSingletonScope();

    container
      .bind<ContainersRepository>(TYPES.CONTAINER_REPOSITORY)
      .to(ContainerService)
      .inSingletonScope();

    //-------------------------------------------------
    //Concepts Container
    container
      .bind<ConceptsContainerRepository>(TYPES.CONCEPTS_CONTAINER_REPOSITORY)
      .to(ConceptContainerService)
      .inSingletonScope();

    container
      .bind<CreateConceptContainer>(TYPES.CREATE_CONCEPT_CONTAINER)
      .to(CreateConceptContainer)
      .inSingletonScope();

    container
      .bind<UpdateConceptContainer>(TYPES.UPDATE_CONCEPT_CONTAINER)
      .to(UpdateConceptContainer)
      .inSingletonScope();

    container
      .bind<DeleteConceptContainer>(TYPES.DELETE_CONCEPT_CONTAINER)
      .to(DeleteConceptContainer)
      .inSingletonScope();

    container
      .bind<FindAllConceptsContainer>(TYPES.FINDALL_CONCEPT_CONTAINER)
      .to(FindAllConceptsContainer)
      .inSingletonScope();

    container
      .bind<FindConceptContainerByCode>(TYPES.FINDBYCODE_CONCEPT_CONTAINER)
      .to(FindConceptContainerByCode)
      .inSingletonScope();

    //-------------------------------------------------
    //ClassReference
    container
      .bind<UpdateClassReferences>(TYPES.UPDATE_CLASS_REFERENCE)
      .to(UpdateClassReferences)
      .inSingletonScope();
    container
      .bind<DeleteClassReferences>(TYPES.DELETE_CLASS_REFERENCE)
      .to(DeleteClassReferences)
      .inSingletonScope();

    container
      .bind<CreateClassReferences>(TYPES.CREATE_CLASS_REFERENCE)
      .to(CreateClassReferences)
      .inSingletonScope();
    container
      .bind<FindAllClassReferences>(TYPES.FINDALL_CLASS_REFERENCE)
      .to(FindAllClassReferences)
      .inSingletonScope();

    container
      .bind<ClassReferencesRepository>(TYPES.CLASS_REFERENCE_REPOSITORY)
      .to(ClassReferencesService)
      .inSingletonScope();
    //CategoryReference
    container
      .bind<UpdateCategoryReferences>(TYPES.UPDATE_CATEGORY_REFERENCE)
      .to(UpdateCategoryReferences)
      .inSingletonScope();
    container
      .bind<DeleteCategoryReferences>(TYPES.DELETE_CATEGORY_REFERENCE)
      .to(DeleteCategoryReferences)
      .inSingletonScope();

    container
      .bind<CreateCategoryReferences>(TYPES.CREATE_CATEGORY_REFERENCE)
      .to(CreateCategoryReferences)
      .inSingletonScope();
    container
      .bind<FindAllCategoryReferences>(TYPES.FINDALL_CATEGORY_REFERENCE)
      .to(FindAllCategoryReferences)
      .inSingletonScope();

    container
      .bind<ReferenceFindAllServices>(TYPES.FINDALL_CATEGORY_REFERENCE_SERVICE)
      .to(ReferenceFindAllServices)
      .inSingletonScope();
    container
      .bind<ReferencesFindByQuery>(TYPES.FIND_REFERENCES_BY_QUERY)
      .to(ReferencesFindByQuery)
      .inSingletonScope();

    container
      .bind<CategoryReferencesRepository>(TYPES.CATEGORY_REFERENCE_REPOSITORY)
      .to(CategoryReferencesService)
      .inSingletonScope();
    //-------------------------------------------------
    //Commodity type
    container
      .bind<FindAllCommodityTypes>(TYPES.COMMODITY_TYPE_FIND_ALL)
      .to(FindAllCommodityTypes)
      .inSingletonScope();
    container
      .bind<FindCommodityTypeByStatus>(TYPES.COMMODITY_TYPE_FIND_BY_STATUS)
      .to(FindCommodityTypeByStatus)
      .inSingletonScope();
    container
      .bind<CommodityTypeRepository>(TYPES.COMMODITY_TYPE_REPOSITORY)
      .to(CommodityTypeService)
      .inSingletonScope();
    container
      .bind<UpdateCommodityType>(TYPES.COMMODITY_TYPE_UPDATE)
      .to(UpdateCommodityType)
      .inSingletonScope();
    container
      .bind<CreateCommodityType>(TYPES.COMMODITY_TYPE_SAVE)
      .to(CreateCommodityType)
      .inSingletonScope();
    container
      .bind<DeleteCommodityType>(TYPES.COMMODITY_TYPE_DELETE)
      .to(DeleteCommodityType)
      .inSingletonScope();
    //TIMELINECONFIG
    container
      .bind<CreateTimelineConfig>(TYPES.TIMELINECONFIG_TYPE_SAVE)
      .to(CreateTimelineConfig)
      .inSingletonScope();
    container
      .bind<TimelineConfigRepository>(TYPES.TIMELINECONFIG_TYPE_REPOSITORY)
      .to(TimelineConfigServices)
      .inSingletonScope();
    container
      .bind<FindAllTimelineConfig>(TYPES.TIMELINECONFIG_TYPE_FINDALL)
      .to(FindAllTimelineConfig)
      .inSingletonScope();
    container
      .bind<FindAllTimelineConfigByStatus>(TYPES.TIMELINECONFIG_TYPE_FINDALL_BY_STATUS)
      .to(FindAllTimelineConfigByStatus)
      .inSingletonScope();
    container
      .bind<FindAllTimelineConfigByParams>(TYPES.TIMELINECONFIG_TYPE_FINDALL_BY_PARAMS)
      .to(FindAllTimelineConfigByParams)
      .inSingletonScope();
    container
      .bind<FindAllTimelineConfigByPk>(TYPES.TIMELINECONFIG_TYPE_FINDALL_BY_PK)
      .to(FindAllTimelineConfigByPk)
      .inSingletonScope();
    //Variety
    container
      .bind<UpdateVariety>(TYPES.UPDATE_VARIETY_REFERENCE)
      .to(UpdateVariety)
      .inSingletonScope();

    container
      .bind<CreateVariety>(TYPES.CREATE_VARIETY_REFERENCE)
      .to(CreateVariety)
      .inSingletonScope();
    container
      .bind<FindAllVariety>(TYPES.FINDALL_VARIETY_REFERENCE)
      .to(FindAllVariety)
      .inSingletonScope();

    container
      .bind<VarietyRepository>(TYPES.VARIETY_REFERENCE_REPOSITORY)
      .to(VarietyService)
      .inSingletonScope();
    //Zone
    container
      .bind<UpdateSegmentation>(TYPES.UPDATE_SEGMENTATION_REFERENCE)
      .to(UpdateSegmentation)
      .inSingletonScope();

    container
      .bind<CreateZone>(TYPES.CREATE_SEGMENTATION_REFERENCE)
      .to(CreateZone)
      .inSingletonScope();
    container
      .bind<FindAllSegmentation>(TYPES.FINDALL_SEGMENTATION_REFERENCE)
      .to(FindAllSegmentation)
      .inSingletonScope();

    container
      .bind<SegmentationRepository>(TYPES.SEGMENTATION_REFERENCE_REPOSITORY)
      .to(SegmentationService)
      .inSingletonScope();
    //PackingUnit
    container
      .bind<UpdatePackingUnit>(TYPES.UPDATE_PACKINGUNIT_REFERENCE)
      .to(UpdatePackingUnit)
      .inSingletonScope();

    container
      .bind<CreatePackingUnit>(TYPES.CREATE_PACKINGUNIT_REFERENCE)
      .to(CreatePackingUnit)
      .inSingletonScope();
    container
      .bind<FindAllPackingUnit>(TYPES.FINDALL_PACKINGUNIT_REFERENCE)
      .to(FindAllPackingUnit)
      .inSingletonScope();
    container
      .bind<PackingUnitRepository>(TYPES.PACKINGUNIT_REFERENCE_REPOSITORY)
      .to(PackingUnitService)
      .inSingletonScope();
    //AlternativeReference
    container
      .bind<UpdateAlternativeReferences>(TYPES.UPDATE_ALTERNATIVE_REFERENCE)
      .to(UpdateAlternativeReferences)
      .inSingletonScope();
    container
      .bind<DeleteAlternativeReferences>(TYPES.DELETE_ALTERNATIVE_REFERENCE)
      .to(DeleteAlternativeReferences)
      .inSingletonScope();
    container
      .bind<CreateAlternativeReferences>(TYPES.CREATE_ALTERNATIVE_REFERENCE)
      .to(CreateAlternativeReferences)
      .inSingletonScope();
    container
      .bind<FindAllAlternativeReferences>(TYPES.FINDALL_ALTERNATIVE_REFERENCE)
      .to(FindAllAlternativeReferences)
      .inSingletonScope();
    container
      .bind<FindByReferenceAlternativeReferences>(TYPES.FINDBYREFERENCE_ALTERNATIVE_REFERENCE)
      .to(FindByReferenceAlternativeReferences)
      .inSingletonScope();
    container
      .bind<FindOwnerByReferenceAndType>(TYPES.FINDOWNER_ALTERNATIVE_REFERENCE)
      .to(FindOwnerByReferenceAndType)
      .inSingletonScope();
    container
      .bind<AlternativeReferencesRepository>(TYPES.ALTERNATIVE_REFERENCE_REPOSITORY)
      .to(AlternativeReferencesService)
      .inSingletonScope();
    //TypeCustomer
    container
      .bind<TypeCustomerFindAll>(TYPES.FINDALL_TYPE_CUSTOMER)
      .to(TypeCustomerFindAll)
      .inSingletonScope();
    container
      .bind<CreateTypeCustomer>(TYPES.CREATE_TYPE_CUSTOMER)
      .to(CreateTypeCustomer)
      .inSingletonScope();
    container
      .bind<TypeCustomerRepository>(TYPES.TYPE_CUSTOMER_REPOSITORY)
      .to(TypeCustomerService)
      .inSingletonScope();

    //TypeIdentification
    container
      .bind<UpdateTypeIdentification>(TYPES.UPDATE_TYPE_IDENTIFICATION)
      .to(UpdateTypeIdentification)
      .inSingletonScope();
    container
      .bind<CreateTypeIdentification>(TYPES.CREATE_TYPE_IDENTIFICATION)
      .to(CreateTypeIdentification)
      .inSingletonScope();
    container
      .bind<DeleteTypeIdentification>(TYPES.DELETE_TYPE_IDENTIFICATION)
      .to(DeleteTypeIdentification)
      .inSingletonScope();
    container
      .bind<TypeIdentificationFindAll>(TYPES.FINDALL_TYPE_IDENTIFICATION)
      .to(TypeIdentificationFindAll)
      .inSingletonScope();

    container
      .bind<TypeIdentificationRepository>(TYPES.TYPE_IDENTIFICATION_REPOSITORY)
      .to(TypeIdentificationService)
      .inSingletonScope();
    //COURIER ORDER
    container
      .bind<CourierOrderRepository>(TYPES.COURIER_ORDER_REPOSITORY)
      .to(CourierOrderServices)
      .inSingletonScope();
    container
      .bind<CalculatePricesCourierOrder>(TYPES.COURIER_ORDER_CALCULATE_PRICING)
      .to(CalculatePricesCourierOrder)
      .inSingletonScope();
    //TypeContact
    container
      .bind<UpdateTypeContact>(TYPES.UPDATE_TYPE_CONTACT)
      .to(UpdateTypeContact)
      .inSingletonScope();
    container
      .bind<DeleteTypeContact>(TYPES.DELETE_TYPE_CONTACT)
      .to(DeleteTypeContact)
      .inSingletonScope();
    container
      .bind<CreateTypeContact>(TYPES.CREATE_TYPE_CONTACT)
      .to(CreateTypeContact)
      .inSingletonScope();
    container
      .bind<TypeContactFindAll>(TYPES.FINDALL_TYPE_CONTACT)
      .to(TypeContactFindAll)
      .inSingletonScope();

    container
      .bind<TypeContactRepository>(TYPES.TYPE_CONTACT_REPOSITORY)
      .to(TypeContactService)
      .inSingletonScope();
    //TypeAddress
    container
      .bind<DeleteTypeAddress>(TYPES.DELETE_TYPE_ADDRESS)
      .to(DeleteTypeAddress)
      .inSingletonScope();
    container
      .bind<UpdateTypeAddress>(TYPES.UPDATE_TYPE_ADDRESS)
      .to(UpdateTypeAddress)
      .inSingletonScope();
    container
      .bind<CreateTypeAddress>(TYPES.CREATE_TYPE_ADDRESS)
      .to(CreateTypeAddress)
      .inSingletonScope();
    container
      .bind<TypeAddressFindAll>(TYPES.FINDALL_TYPE_ADDRESS)
      .to(TypeAddressFindAll)
      .inSingletonScope();
    container
      .bind<TypeAddressRepository>(TYPES.TYPE_ADDRESS_REPOSITORY)
      .to(TypeAddressService)
      .inSingletonScope();
    //Incoterms
    container
      .bind<UpdateIncoterms>(TYPES.UPDATE_INCOTERMS)
      .to(UpdateIncoterms)
      .inSingletonScope();
    container
      .bind<DeleteIncoterms>(TYPES.DELETE_INCOTERMS)
      .to(DeleteIncoterms)
      .inSingletonScope();

    container
      .bind<CreateIncoterms>(TYPES.CREATE_INCOTERMS)
      .to(CreateIncoterms)
      .inSingletonScope();
    container
      .bind<IncotermsFindAll>(TYPES.FINDALL_INCOTERMS)
      .to(IncotermsFindAll)
      .inSingletonScope();
    container
      .bind<IncotermsRepository>(TYPES.INCOTERMS_REPOSITORY)
      .to(IncotermsService)
      .inSingletonScope();
    //TermsPayment
    container
      .bind<UpdateTermsPayment>(TYPES.UPDATE_TERMS_PAYMENT)
      .to(UpdateTermsPayment)
      .inSingletonScope();
    container
      .bind<DeleteTermsPayment>(TYPES.DELETE_TERMS_PAYMENT)
      .to(DeleteTermsPayment)
      .inSingletonScope();
    container
      .bind<CreateTermsPayment>(TYPES.CREATE_TERMS_PAYMENT)
      .to(CreateTermsPayment)
      .inSingletonScope();
    container
      .bind<TermsPaymentFindAll>(TYPES.FINDALL_TERMS_PAYMENT)
      .to(TermsPaymentFindAll)
      .inSingletonScope();
    container
      .bind<TermsPaymentRepository>(TYPES.TERMS_PAYMENT_REPOSITORY)
      .to(TermsPaymentService)
      .inSingletonScope();
    //PaymentMethods
    container
      .bind<DeletePaymentMethods>(TYPES.DELETE_PAYMENT_METHODS)
      .to(DeletePaymentMethods)
      .inSingletonScope();
    container
      .bind<UpdatePaymentMethods>(TYPES.UPDATE_PAYMENT_METHODS)
      .to(UpdatePaymentMethods)
      .inSingletonScope();
    container
      .bind<CreatePaymentMethods>(TYPES.CREATE_PAYMENT_METHODS)
      .to(CreatePaymentMethods)
      .inSingletonScope();
    container
      .bind<PaymentMethodsFindAll>(TYPES.FINDALL_PAYMENT_METHODS)
      .to(PaymentMethodsFindAll)
      .inSingletonScope();
    container
      .bind<PaymentMethodsRepository>(TYPES.PAYMENT_METHODS_REPOSITORY)
      .to(PaymentMethodsService)
      .inSingletonScope();
    //Bank
    container
      .bind<UpdateBank>(TYPES.UPDATE_BANK)
      .to(UpdateBank)
      .inSingletonScope();
    container
      .bind<CreateBank>(TYPES.CREATE_BANK)
      .to(CreateBank)
      .inSingletonScope();
    container
      .bind<BankFindAll>(TYPES.FINDALL_BANK)
      .to(BankFindAll)
      .inSingletonScope();
    container
      .bind<BankRepository>(TYPES.BANK_REPOSITORY)
      .to(BankService)
      .inSingletonScope();
    //GroupAgentService
    container
      .bind<DeleteGroupAgentService>(TYPES.DELETE_GROUP_AGENT_SERVICE)
      .to(DeleteGroupAgentService)
      .inSingletonScope();
    container
      .bind<UpdateGroupAgentService>(TYPES.UPDATE_GROUP_AGENT_SERVICE)
      .to(UpdateGroupAgentService)
      .inSingletonScope();
    container
      .bind<CreateGroupAgentService>(TYPES.CREATE_GROUP_AGENT_SERVICE)
      .to(CreateGroupAgentService)
      .inSingletonScope();
    container
      .bind<GroupAgentServiceFindAll>(TYPES.FINDALL_GROUP_AGENT_SERVICE)
      .to(GroupAgentServiceFindAll)
      .inSingletonScope();
    container
      .bind<GroupAgentServiceRepository>(TYPES.GROUP_AGENT_SERVICE_REPOSITORY)
      .to(GroupAgentSrvService)
      .inSingletonScope();
    //#region Packaging
    container
      .bind<UpdatePackaging>(TYPES.UPDATE_PACKAGING_REFERENCE)
      .to(UpdatePackaging)
      .inSingletonScope();
    container
      .bind<DeletePackaging>(TYPES.DELETE_PACKAGING_REFERENCE)
      .to(DeletePackaging)
      .inSingletonScope();
    container
      .bind<FindPackagingByReferenceAndType>(TYPES.FIND_PACKAGING_BY_REFERENCE_AND_TYPE)
      .to(FindPackagingByReferenceAndType)
      .inSingletonScope();
    container
      .bind<CreatePackaging>(TYPES.CREATE_PACKAGING_REFERENCE)
      .to(CreatePackaging)
      .inSingletonScope();
    container
      .bind<FindAllPackaging>(TYPES.FINDALL_PACKAGING_REFERENCE)
      .to(FindAllPackaging)
      .inSingletonScope();
    container
      .bind<FindPackagingByType>(TYPES.FINDALL_BY_TYPE_PACKINGUNIT_REFERENCE)
      .to(FindPackagingByType)
      .inSingletonScope();
    container
      .bind<FindByReferencePackaging>(TYPES.FINDBYREFERENCE_PACKAGING_REFERENCE)
      .to(FindByReferencePackaging)
      .inSingletonScope();
    container
      .bind<PackagingRepository>(TYPES.PACKAGING_REFERENCE_REPOSITORY)
      .to(PackagingService)
      .inSingletonScope();
    //Pricing Engine
    container
      .bind<SavePricingEngine>(TYPES.SAVE_PRICING_ENGINE_REFERENCE)
      .to(SavePricingEngine)
      .inSingletonScope();
    container
      .bind<PricingEngineRepository>(TYPES.PRICING_ENGINE_REPOSITORY)
      .to(PricingEngineService)
      .inSingletonScope();
    //GroupReference
    container
      .bind<GroupReferenceUpdate>(TYPES.UPDATE_GROUP_REFERENCE)
      .to(GroupReferenceUpdate)
      .inSingletonScope();
    container
      .bind<GroupReferenceDelete>(TYPES.DELETE_GROUP_REFERENCE)
      .to(GroupReferenceDelete)
      .inSingletonScope();
    container
      .bind<GroupReferenceValidateCode>(TYPES.VALIDATE_GROUP_REFERENCE_CODE)
      .to(GroupReferenceValidateCode)
      .inSingletonScope();
    container
      .bind<GroupReferenceFindById>(TYPES.FINDBYID_GROUP_REFERENCE)
      .to(GroupReferenceFindById)
      .inSingletonScope();
    container
      .bind<CreateGroupReference>(TYPES.CREATE_GROUP_REFERENCE)
      .to(CreateGroupReference)
      .inSingletonScope();
    container
      .bind<GroupReferenceFindAll>(TYPES.FINDALL_GROUP_REFERENCE)
      .to(GroupReferenceFindAll)
      .inSingletonScope();
    container
      .bind<GroupReferenceFindAllTree>(TYPES.FINDALL_GROUP_TREE_REFERENCE)
      .to(GroupReferenceFindAllTree)
      .inSingletonScope();
    container
      .bind<GroupReferencesRepository>(TYPES.GROUP_REFERENCE_REPOSITORY)
      .to(GroupReferencesServices)
      .inSingletonScope();
    //#region References
    container
      .bind<ReferenceUpdate>(TYPES.UPDATE_REFERENCE)
      .to(ReferenceUpdate)
      .inSingletonScope();
    container
      .bind<ReferenceUpdateBarcode>(TYPES.UPDATE_BARCODE_REFERENCES)
      .to(ReferenceUpdateBarcode)
      .inSingletonScope();
    container
      .bind<ReferenceDelete>(TYPES.DELETE_REFERENCE)
      .to(ReferenceDelete)
      .inSingletonScope();
    container
      .bind<ReferenceFindById>(TYPES.FINDBYID_REFERENCE)
      .to(ReferenceFindById)
      .inSingletonScope();
    container
      .bind<CreateReference>(TYPES.CREATE_REFERENCE)
      .to(CreateReference)
      .inSingletonScope();
    container
      .bind<ReferenceFindAll>(TYPES.FINDALL_REFERENCE)
      .to(ReferenceFindAll)
      .inSingletonScope();
    container
      .bind<FindReferencesByMainSku>(TYPES.FIND_REFERENCES_BY_MAIN_SKU)
      .to(FindReferencesByMainSku)
      .inSingletonScope();
    container
      .bind<ReferencesRepository>(TYPES.REFERENCE_REPOSITORY)
      .to(ReferencesServices)
      .inSingletonScope();
    //  Serials
    container
      .bind<CreateSerial>(TYPES.REFERENCES_SAVE_SERIALS)
      .to(CreateSerial)
      .inSingletonScope();
    container
      .bind<DeleteSerials>(TYPES.REFERENCES_DELETE_SERIAL)
      .to(DeleteSerials)
      .inSingletonScope();
    container
      .bind<FindAllAssociatedSerialsByReference>(TYPES.REFERENCES_FIND_SERIALS_BY_REFERENCE)
      .to(FindAllAssociatedSerialsByReference)
      .inSingletonScope();
    container
      .bind<AssignReferencesToTimeline>(TYPES.TIMELINE_REFERENCES_ASSIGN)
      .to(AssignReferencesToTimeline)
      .inSingletonScope();
    container
      .bind<ReferenceFindByTimeline>(TYPES.TIMELINE_REFERENCES_FIND_BY_TIMELINE)
      .to(ReferenceFindByTimeline)
      .inSingletonScope();
    container
      .bind<ReferenceFindCodeByTimeline>(TYPES.TIMELINE_REFERENCES_CODE_FIND_BY_TIMELINE)
      .to(ReferenceFindCodeByTimeline)
      .inSingletonScope();
    //#endregion
    //#region Products
    container
      .bind<ProductRepository>(TYPES.PRODUCT_REPOSITORY)
      .to(ProductServices)
      .inSingletonScope();
    container
      .bind<CreateProduct>(TYPES.CREATE_PRODUCT)
      .to(CreateProduct)
      .inSingletonScope();
    container
      .bind<UpdateProduct>(TYPES.UPDATE_PRODUCT)
      .to(UpdateProduct)
      .inSingletonScope();
    container
      .bind<ProductFindBySKU>(TYPES.PRODUCT_FIND_BY_SKU)
      .to(ProductFindBySKU)
      .inSingletonScope();
    // Person
    container
      .bind<PersonRepository>(TYPES.PERSON_REPOSITORY)
      .to(PersonServices)
      .inSingletonScope();
    container
      .bind<CreatePerson>(TYPES.CREATE_PERSON)
      .to(CreatePerson)
      .inSingletonScope();
    container
      .bind<UpdatePerson>(TYPES.UPDATE_PERSON)
      .to(UpdatePerson)
      .inSingletonScope();
    container
      .bind<PersonFindByDocument>(TYPES.FIND_PERSON_BY_DOCUMENT)
      .to(PersonFindByDocument)
      .inSingletonScope();
    // UserERP
    container
      .bind<UserErpRepository>(TYPES.USER_ERP_REPOSITORY)
      .to(UserErpServices)
      .inSingletonScope();
    container
      .bind<CreateUserERP>(TYPES.CREATE_USER_ERP)
      .to(CreateUserERP)
      .inSingletonScope();
    container
      .bind<UpdateUserERP>(TYPES.UPDATE_USER_ERP)
      .to(UpdateUserERP)
      .inSingletonScope();
    container
      .bind<FindUserErpByEmail>(TYPES.FIND_USER_ERP_BY_EMAIL)
      .to(FindUserErpByEmail)
      .inSingletonScope();
    //params pricing engine
    container
      .bind<FindByTypeParam>(TYPES.PRICING_ENGINE_PARAM_FIND)
      .to(FindByTypeParam)
      .inSingletonScope();
    container
      .bind<PricingEngineParamRepository>(TYPES.PRICING_ENGINE_PARAM_REPOSITORY)
      .to(PricingEngineParamService)
      .inSingletonScope();
    //view
    container
      .bind<FindOrderByFilters>(TYPES.VIEW_ORDER_FINDBYFILTERS)
      .to(FindOrderByFilters)
      .inSingletonScope();
    container
      .bind<OrderbyFilterRepository>(TYPES.VIEW_ORDER_REPOSITORY)
      .to(OrderByFilterService)
      .inSingletonScope();
    //migrations
    container
      .bind<MigrationFromCountryCode>(TYPES.MIGRATIONS_FROMCOUNTRYCODE)
      .to(MigrationFromCountryCode)
      .inSingletonScope();
    container
      .bind<MigrationFromCountryRepository>(TYPES.MIGRATIONS_FROMCOUNTRYCODE_REPOSITORY)
      .to(MigrationFromCountryService)
      .inSingletonScope();
    //Carlos
    //invoice
    container
      .bind<InvoiceUpdate>(TYPES.UPDATE_INVOICE)
      .to(InvoiceUpdate)
      .inSingletonScope();
    container
      .bind<InvoiceCreate>(TYPES.CREATE_INVOICE)
      .to(InvoiceCreate)
      .inSingletonScope();
    container
      .bind<CreateInvoiceFromOrder>(TYPES.CREATE_INVOICE_ORDER)
      .to(CreateInvoiceFromOrder)
      .inSingletonScope();
    container
      .bind<InvoiceFindAll>(TYPES.FINDALL_INVOICE)
      .to(InvoiceFindAll)
      .inSingletonScope();
    container
      .bind<InvoiceFindByDateRange>(TYPES.FINDBYDATERANGE_INVOICE)
      .to(InvoiceFindByDateRange)
      .inSingletonScope();
    container
      .bind<InvoiceFindByDateRangeAndCustomer>(TYPES.FINDBYDATERANGEANDCUSTOMER_INVOICE)
      .to(InvoiceFindByDateRangeAndCustomer)
      .inSingletonScope();
    container
      .bind<InvoiceFindByPk>(TYPES.FINDBYPK_INVOICE)
      .to(InvoiceFindByPk)
      .inSingletonScope();
    container
      .bind<InvoiceFindByClientId>(TYPES.FINDBYCLIENTID_INVOICE)
      .to(InvoiceFindByClientId)
      .inSingletonScope();
    container
      .bind<InvoiceRepository>(TYPES.INVOICE_REPOSITORY)
      .to(InvoiceService)
      .inSingletonScope();
    container
      .bind<PurchaseRepository>(TYPES.PURCHASE_REPOSITORY)
      .to(PurchaseServices)
      .inSingletonScope();
    container
      .bind<PurchaseScraping>(TYPES.PURCHASE_SCRAPING)
      .to(PurchaseScraping)
      .inSingletonScope();
    container
      .bind<RoleRepository>(TYPES.ROLE_REPOSITORY)
      .to(RoleServices)
      .inSingletonScope();
    container
      .bind<RoleFindByCompanyId>(TYPES.FIND_ROLE_BY_COMPANY_ID)
      .to(RoleFindByCompanyId)
      .inSingletonScope();
    container
      .bind<CreateRole>(TYPES.CREATE_ROLE)
      .to(CreateRole)
      .inSingletonScope();
    container
      .bind<CreateRoleV2>(TYPES.CREATE_ROLE_V2)
      .to(CreateRoleV2)
      .inSingletonScope();
    container
      .bind<UpdateRoleV2>(TYPES.UPDATE_ROLE_V2)
      .to(UpdateRoleV2)
      .inSingletonScope();
    container
      .bind<UpdateRole>(TYPES.UPDATE_ROLE)
      .to(UpdateRole)
      .inSingletonScope();
    container
      .bind<RoleFindAll>(TYPES.FINDALL_ROLE)
      .to(RoleFindAll)
      .inSingletonScope();
    container
      .bind<RoleDelete>(TYPES.DELETE_ROLE)
      .to(RoleDelete)
      .inSingletonScope();
    container
      .bind<RoleFindById>(TYPES.FINDBYMENU_ROLE)
      .to(RoleFindById)
      .inSingletonScope();
    container
      .bind<RoleFindMenuPlaneByRoleId>(TYPES.FINDMENUPLANEBYROLEID_ROLE)
      .to(RoleFindMenuPlaneByRoleId)
      .inSingletonScope();
    container
      .bind<RoleFindMenuPlaneByRoleName>(TYPES.FINDMENUPLANEBYROLENAME_ROLE)
      .to(RoleFindMenuPlaneByRoleName)
      .inSingletonScope();
    //pallets factor
    container
      .bind<PalletsFactorUpdate>(TYPES.UPDATE_PALLETSFACTOR)
      .to(PalletsFactorUpdate)
      .inSingletonScope();
    container
      .bind<PalletsFactorCreate>(TYPES.CREATE_PALLETSFACTOR)
      .to(PalletsFactorCreate)
      .inSingletonScope();
    container
      .bind<PalletsFactorFindAll>(TYPES.FINDALL_PALLETSFACTOR)
      .to(PalletsFactorFindAll)
      .inSingletonScope();
    container
      .bind<PalletsFactorRepository>(TYPES.PALLETSFACTOR_REPOSITORY)
      .to(PalletsFactorService)
      .inSingletonScope();
    container
      .bind<PriceListUpdateVolume>(TYPES.UPDATE_PRICE_LIST_VOLUME)
      .to(PriceListUpdateVolume)
      .inSingletonScope();
    container
      .bind<PriceListFindAllByStatusVolume>(TYPES.FINDBYSTATUS_PRICE_LIST_VOLUME)
      .to(PriceListFindAllByStatusVolume)
      .inSingletonScope();
    container
      .bind<PriceListCreateVolume>(TYPES.CREATE_PRICE_LIST_VOLUME)
      .to(PriceListCreateVolume)
      .inSingletonScope();
    container
      .bind<PriceListFindAllVolume>(TYPES.FINDALL_PRICE_LIST_VOLUME)
      .to(PriceListFindAllVolume)
      .inSingletonScope();
    container
      .bind<PriceListRepositoryVolume>(TYPES.PRICE_LIST_REPOSITORY_VOLUME)
      .to(PriceListVolumeServices)
      .inSingletonScope();
    container
      .bind<PriceListFindByOriginAndDestination>(TYPES.FINDBY_ORIGINANDDESTINATION_VOLUME)
      .to(PriceListFindByOriginAndDestination)
      .inSingletonScope();

    //price list weight
    container
      .bind<PriceListFindByOriginAndDestinationWeight>(TYPES.FINDBY_ORIGINANDDESTINATION_WEIGHT)
      .to(PriceListFindByOriginAndDestinationWeight)
      .inSingletonScope();
    container
      .bind<PriceListUpdateWeight>(TYPES.UPDATE_PRICE_LIST_WEIGHT)
      .to(PriceListUpdateWeight)
      .inSingletonScope();
    container
      .bind<PriceListFindAllByStatusWeight>(TYPES.FINDBYSTATUS_PRICE_LIST_WEIGHT)
      .to(PriceListFindAllByStatusWeight)
      .inSingletonScope();
    container
      .bind<PriceListCreateWeight>(TYPES.CREATE_PRICE_LIST_WEIGHT)
      .to(PriceListCreateWeight)
      .inSingletonScope();
    container
      .bind<PriceListFindAllWeight>(TYPES.FINDALL_PRICE_LIST_WEIGHT)
      .to(PriceListFindAllWeight)
      .inSingletonScope();

    container
      .bind<PriceListRepositoryWeight>(TYPES.PRICE_LIST_REPOSITORY_WEIGHT)
      .to(PriceListWeightServices)
      .inSingletonScope();

    container
      .bind<PriceListImport>(TYPES.IMPORT_PRICE_LIST)
      .to(PriceListImport)
      .inSingletonScope();

    container
      .bind<PriceListImportWeight>(TYPES.IMPORT_PRICE_LIST_WEIGHT)
      .to(PriceListImportWeight)
      .inSingletonScope();

    //Andres Escorcia
    //Tax
    container
      .bind<DeleteTaxByCode>(TYPES.DELETE_TAX)
      .to(DeleteTaxByCode)
      .inSingletonScope();
    container
      .bind<TaxeUpdate>(TYPES.UPDATE_TAXE)
      .to(TaxeUpdate)
      .inSingletonScope();
    container
      .bind<TaxeFindByStatus>(TYPES.FINDBYSTATUS_TAXE)
      .to(TaxeFindByStatus)
      .inSingletonScope();
    container
      .bind<TaxeCreate>(TYPES.CREATE_TAXE)
      .to(TaxeCreate)
      .inSingletonScope();
    container
      .bind<TaxeFindAll>(TYPES.FINDALL_TAXE)
      .to(TaxeFindAll)
      .inSingletonScope();
    container
      .bind<TaxeRepository>(TYPES.TAXE_REPOSITORY)
      .to(TaxeServices)
      .inSingletonScope();
    //File
    container
      .bind<CreateFile>(TYPES.CREATE_FILE)
      .to(CreateFile)
      .inSingletonScope();
    container
      .bind<FileFindAll>(TYPES.FINDALL_FILE)
      .to(FileFindAll)
      .inSingletonScope();
    container
      .bind<CreateFileV2>(TYPES.SAVE_FILE)
      .to(CreateFileV2)
      .inSingletonScope();
    container
      .bind<FindFile>(TYPES.FIND_FILE_BY_NAME)
      .to(FindFile)
      .inSingletonScope();
    container
      .bind<FileRepository>(TYPES.FILE_REPOSITORY)
      .to(FileServices)
      .inSingletonScope();
    //Company
    container
      .bind<CompanyFindById>(TYPES.FINDBYID_COMPANY)
      .to(CompanyFindById)
      .inSingletonScope();
    container
      .bind<CompanyUpdate>(TYPES.UPDATE_COMPANY)
      .to(CompanyUpdate)
      .inSingletonScope();
    container
      .bind<CreateCompany>(TYPES.CREATE_COMPANY)
      .to(CreateCompany)
      .inSingletonScope();
    container
      .bind<CompanyFindAll>(TYPES.FINDALL_COMPANY)
      .to(CompanyFindAll)
      .inSingletonScope();
    container
      .bind<CompanyRepository>(TYPES.COMPANY_REPOSITORY)
      .to(CompanyServices)
      .inSingletonScope();
    //Warehouses
    container
      .bind<WarehouseFindByStatus>(TYPES.FINDBYSTATUS_WAREHOUSE)
      .to(WarehouseFindByStatus)
      .inSingletonScope();
    container
      .bind<WarehouseFindByCode>(TYPES.FINDBYCODE_WAREHOUSE)
      .to(WarehouseFindByCode)
      .inSingletonScope();
    container
      .bind<WarehouseUpdate>(TYPES.UPDATE_WAREHOUSE)
      .to(WarehouseUpdate)
      .inSingletonScope();
    container
      .bind<CreateWarehouse>(TYPES.CREATE_WAREHOUSE)
      .to(CreateWarehouse)
      .inSingletonScope();
    container
      .bind<WarehouseDelete>(TYPES.DELETE_WAREHOUSE)
      .to(WarehouseDelete)
      .inSingletonScope();
    container
      .bind<WarehouseFindAll>(TYPES.FINDALL_WAREHOUSE)
      .to(WarehouseFindAll)
      .inSingletonScope();
    container
      .bind<WarehouseRepository>(TYPES.WAREHOUSE_REPOSITORY)
      .to(WarehouseServices)
      .inSingletonScope();
    //Routes
    container
      .bind<RoutesFindByStatus>(TYPES.FINDBYSTATUS_ROUTES)
      .to(RoutesFindByStatus)
      .inSingletonScope();
    container
      .bind<RouteFindById>(TYPES.FINDBYID_ROUTES)
      .to(RouteFindById)
      .inSingletonScope();
    container
      .bind<RoutesFindByOiriginAndDestination>(TYPES.ROUTES_FIND_BY_ORIGIN_AND_DESTINATION)
      .to(RoutesFindByOiriginAndDestination)
      .inSingletonScope();
    container
      .bind<RoutesUpdate>(TYPES.UPDATE_ROUTES)
      .to(RoutesUpdate)
      .inSingletonScope();
    container
      .bind<CreateRoutes>(TYPES.CREATE_ROUTES)
      .to(CreateRoutes)
      .inSingletonScope();
    container
      .bind<RoutesFindAll>(TYPES.FINDALL_ROUTES)
      .to(RoutesFindAll)
      .inSingletonScope();
    container
      .bind<RoutesRepository>(TYPES.ROUTES_REPOSITORY)
      .to(RoutesServices)
      .inSingletonScope();
    //CurrencyFactor
    container
      .bind<CurrencyFactorFindRateByDate>(TYPES.FINDRATEBYDATE_CURRENCY_FACTOR)
      .to(CurrencyFactorFindRateByDate)
      .inSingletonScope();
    container
      .bind<CurrencyFactorFindByCurrencyCode>(TYPES.FINDBYCURRENCYCODE_CURRENCYFACTOR)
      .to(CurrencyFactorFindByCurrencyCode)
      .inSingletonScope();
    container
      .bind<CurrencyFactorUpdate>(TYPES.UPDATE_CURRENCYFACTOR)
      .to(CurrencyFactorUpdate)
      .inSingletonScope();
    container
      .bind<CreateCurrencyFactor>(TYPES.CREATE_CURRENCYFACTOR)
      .to(CreateCurrencyFactor)
      .inSingletonScope();
    container
      .bind<CurrencyFactorFindAll>(TYPES.FINDALL_CURRENCYFACTOR)
      .to(CurrencyFactorFindAll)
      .inSingletonScope();
    container
      .bind<CurrencyFactorRepository>(TYPES.CURRENCYFACTOR_REPOSITORY)
      .to(CurrencyFactorServices)
      .inSingletonScope();
    //Currency
    container
      .bind<CurrencyFindById>(TYPES.FINDBYID_CURRENCY)
      .to(CurrencyFindById)
      .inSingletonScope();
    container
      .bind<CurrencyUpdate>(TYPES.UPDATE_CURRENCY)
      .to(CurrencyUpdate)
      .inSingletonScope();
    container
      .bind<CreateCurrency>(TYPES.CREATE_CURRENCY)
      .to(CreateCurrency)
      .inSingletonScope();
    container
      .bind<DeleteCurrency>(TYPES.DELETE_CURRENCY)
      .to(DeleteCurrency)
      .inSingletonScope();
    container
      .bind<CurrencyFindAll>(TYPES.FINDALL_CURRENCY)
      .to(CurrencyFindAll)
      .inSingletonScope();
    container
      .bind<CurrencyFindAllWithRate>(TYPES.FINDALL_CURRENCY_WITH_RATE)
      .to(CurrencyFindAllWithRate)
      .inSingletonScope();
    container
      .bind<CurrencyRepository>(TYPES.CURRENCY_REPOSITORY)
      .to(CurrencyServices)
      .inSingletonScope();
    //segmentation
    container
      .bind<ZoneUpdate>(TYPES.UPDATE_ZONE)
      .to(ZoneUpdate)
      .inSingletonScope();
    container
      .bind<CreateZone>(TYPES.CREATE_ZONE)
      .to(CreateZone)
      .inSingletonScope();
    container
      .bind<ZoneFindByStatusAndCountryAndState>(TYPES.FINDSTATUSCOUNTRYSTATE_ZONE)
      .to(ZoneFindByStatusAndCountryAndState)
      .inSingletonScope();
    container
      .bind<ZoneFindAll>(TYPES.FINDALL_ZONE)
      .to(ZoneFindAll)
      .inSingletonScope();
    container
      .bind<ZoneFindByDescriptionLike>(TYPES.FIND_BY_DESCRIPTION_LIKE_ZONE)
      .to(ZoneFindByDescriptionLike)
      .inSingletonScope();
    container
      .bind<ZoneRepository>(TYPES.ZONE_REPOSITORY)
      .to(ZoneServices)
      .inSingletonScope();
    //State
    container
      .bind<StateUpdate>(TYPES.UPDATE_STATE)
      .to(StateUpdate)
      .inSingletonScope();
    container
      .bind<StateFindByCountryCode>(TYPES.FINDBYCOUNTRYCODE_STATE)
      .to(StateFindByCountryCode)
      .inSingletonScope();
    container
      .bind<StateFindByStatus>(TYPES.FINDBYSTATUS_STATE)
      .to(StateFindByStatus)
      .inSingletonScope();
    container
      .bind<CreateState>(TYPES.CREATE_STATE)
      .to(CreateState)
      .inSingletonScope();
    container
      .bind<StateFindAll>(TYPES.FINDALL_STATE)
      .to(StateFindAll)
      .inSingletonScope();
    container
      .bind<StateFindByDescriptionLike>(TYPES.FIND_BY_DESCRIPTION_LIKE_STATE)
      .to(StateFindByDescriptionLike)
      .inSingletonScope();
    container
      .bind<StateFindByCountryAndDescription>(TYPES.FIND_STATE_BY_COUNTRY_AND_DESCRIPTION_LIKE)
      .to(StateFindByCountryAndDescription)
      .inSingletonScope();
    container
      .bind<StateRepository>(TYPES.STATE_REPOSITORY)
      .to(StateServices)
      .inSingletonScope();
    //Country
    container
      .bind<CountryUpdate>(TYPES.UPDATE_COUNTRY)
      .to(CountryUpdate)
      .inSingletonScope();
    container
      .bind<CountryFindByStatus>(TYPES.FINDBYSTATUS_COUNTRY)
      .to(CountryFindByStatus)
      .inSingletonScope();
    container
      .bind<CreateCountry>(TYPES.CREATE_COUNTRY)
      .to(CreateCountry)
      .inSingletonScope();
    container
      .bind<CountryFindAll>(TYPES.FINDALL_COUNTRY)
      .to(CountryFindAll)
      .inSingletonScope();
    container;
    container
      .bind<CountryFindByDescriptionLikeInGeneralTerritories>(TYPES.COUNTRY_FIND_BY_DESCRIPTION_IN_GENERAL_TERRITORIES)
      .to(CountryFindByDescriptionLikeInGeneralTerritories)
      .inSingletonScope();
    container
      .bind<CountryFindByDescriptionLike>(TYPES.FIND_BY_DESCRIPTION_LIKE_COUNTRY)
      .to(CountryFindByDescriptionLike)
      .inSingletonScope();
    container
      .bind<CountryRepository>(TYPES.TERRITORIES_REPOSITORY)
      .to(CountryServices)
      .inSingletonScope();
    //Dony Cortinez
    container
      .bind<ConsecutiveFindByWarehouseAndType>(TYPES.FINDBYWAREHOUSEANDTYPE_CONSECUTIVE)
      .to(ConsecutiveFindByWarehouseAndType)
      .inSingletonScope();
    container
      .bind<ConsecutiveRepository>(TYPES.CONSECUTIVE_REPOSITORY)
      .to(ConsecutiveServices)
      .inSingletonScope();
    container
      .bind<CreateOrder>(TYPES.CREATE_ORDER)
      .to(CreateOrder)
      .inSingletonScope();
    container
      .bind<UpdateOrder>(TYPES.UPDATE_ORDER)
      .to(UpdateOrder)
      .inSingletonScope();
    container
      .bind<OrderFindAllByModule>(TYPES.ORDER_FIND_ALL_BY_MODULE)
      .to(OrderFindAllByModule)
      .inSingletonScope();
    container
      .bind<OrderFindByPk>(TYPES.FINDBYPK_ORDER)
      .to(OrderFindByPk)
      .inSingletonScope();
    container
      .bind<OrderFindAllByTypeAndState>(TYPES.ORDER_FIND_ALL_BY_TYPE_AND_STATE)
      .to(OrderFindAllByTypeAndState)
      .inSingletonScope();
    container
      .bind<OrderFindByDateRange>(TYPES.FINDBYDATERANGE_ORDER)
      .to(OrderFindByDateRange)
      .inSingletonScope();
    container
      .bind<OrderFindByDateRangeAndCustomerId>(TYPES.FINDBYDATERANGEANDCUSTOMERID_ORDER)
      .to(OrderFindByDateRangeAndCustomerId)
      .inSingletonScope();
    container
      .bind<OrderRepository>(TYPES.ORDER_REPOSITORY)
      .to(OrderServices)
      .inSingletonScope();
    container
      .bind<UpdateCustomerPassword>(TYPES.UPDATE_CUSTOMER_PASSWORD)
      .to(UpdateCustomerPassword)
      .inSingletonScope();
    container
      .bind<CustomerGetSecurityCode>(TYPES.GET_SECURITYCODE_CUSTOMER)
      .to(CustomerGetSecurityCode)
      .inSingletonScope();
    container
      .bind<CustomerFindById>(TYPES.FINDBYID_CUSTOMER)
      .to(CustomerFindById)
      .inSingletonScope();
    container
      .bind<CreateCustomer>(TYPES.CREATE_CUSTOMER)
      .to(CreateCustomer)
      .inSingletonScope();
    container
      .bind<EntityMaxCode>(TYPES.ENTITY_MAX_CODE)
      .to(EntityMaxCode)
      .inSingletonScope();
    container
      .bind<EntityDeleteContact>(TYPES.API_ENTITY_DELETE_CONTACT)
      .to(EntityDeleteContact)
      .inSingletonScope();
    container
      .bind<EntityDeleteAddress>(TYPES.API_ENTITY_DELETE_ADDRESS)
      .to(EntityDeleteAddress)
      .inSingletonScope();
    container
      .bind<EntityFindById>(TYPES.API_ENTITY_FIND_BY_ID)
      .to(EntityFindById)
      .inSingletonScope();
    container
      .bind<CustomerRepository>(TYPES.CUSTOMER_REPOSITORY)
      .to(CustomerServices)
      .inSingletonScope();
    container
      .bind<UpdateUserPassword>(TYPES.UPDATE_USER_PASSWORD)
      .to(UpdateUserPassword)
      .inSingletonScope();
    container
      .bind<GetTemporalToken>(TYPES.GET_TEMPORAL_TOKEN)
      .to(GetTemporalToken)
      .inSingletonScope();
    container
      .bind<GetWorkspaces>(TYPES.LOGIN_GET_WORKSPACES)
      .to(GetWorkspaces)
      .inSingletonScope();
    container
      .bind<LoginWithWorkspace>(TYPES.LOGIN_WITH_WORKSPACE)
      .to(LoginWithWorkspace)
      .inSingletonScope();
    //Store
    container
      .bind<CreateStore>(TYPES.CREATE_STORE)
      .to(CreateStore)
      .inSingletonScope();
    container
      .bind<UpdateStore>(TYPES.UPDATE_STORE)
      .to(UpdateStore)
      .inSingletonScope();
    container
      .bind<FindAllStorebyWarehouse>(TYPES.FIND_ALL_STORE_WAREHOUSE)
      .to(FindAllStorebyWarehouse)
      .inSingletonScope();
    container
      .bind<FindAllStoreByUser>(TYPES.STORES_GET_BY_USER)
      .to(FindAllStoreByUser)
      .inSingletonScope();
    container
      .bind<FindAllStore>(TYPES.FIND_ALL_STORE)
      .to(FindAllStore)
      .inSingletonScope();
    container
      .bind<FindAllStoreForUserAssign>(TYPES.STORE_FIND_ALL_FOR_USER_ASSIGN)
      .to(FindAllStoreForUserAssign)
      .inSingletonScope();
    container
      .bind<StoreRepository>(TYPES.STORE_REPOSITORY)
      .to(StoreServices)
      .inSingletonScope();
    //racks
    container
      .bind<CreateRack>(TYPES.CREATE_RACKS)
      .to(CreateRack)
      .inSingletonScope();
    container
      .bind<UpdateRack>(TYPES.UPDATE_RACKS)
      .to(UpdateRack)
      .inSingletonScope();
    container
      .bind<FindAllRacks>(TYPES.FIND_ALL_RACKS)
      .to(FindAllRacks)
      .inSingletonScope();
    container
      .bind<FindAllByStatusRack>(TYPES.FIND_RACKS_BY_STATUS)
      .to(FindAllByStatusRack)
      .inSingletonScope();
    container
      .bind<RackRepository>(TYPES.RACKS_REPOSITORY)
      .to(RackService)
      .inSingletonScope();
    //zone wms
    container
      .bind<CreateZoneL>(TYPES.CREATE_LAYOUT_ZONE)
      .to(CreateZoneL)
      .inSingletonScope();
    container
      .bind<DeleteZoneL>(TYPES.DELETE_LAYOUT_ZONE)
      .to(DeleteZoneL)
      .inSingletonScope();
    container
      .bind<UpdateZoneL>(TYPES.UPDATE_LAYOUT_ZONE)
      .to(UpdateZoneL)
      .inSingletonScope();
    container
      .bind<FindAllZone>(TYPES.FINDALL_LAYOUT_ZONE)
      .to(FindAllZone)
      .inSingletonScope();
    container
      .bind<SetRacksToConfigZone>(TYPES.SET_RACKS_CONFIG_TO_LAYOUT_ZONE)
      .to(SetRacksToConfigZone)
      .inSingletonScope();
    container
      .bind<FindZoneNotConfigured>(TYPES.FINDALL_LAYOUT_ZONE_NOT_CONFIGURED)
      .to(FindZoneNotConfigured)
      .inSingletonScope();
    container
      .bind<ZoneLRepository>(TYPES.LAYOUT_ZONE_REPOSITORY)
      .to(ZoneLServices)
      .inSingletonScope();
    //layout config
    container
      .bind<CreateLayoutConfig>(TYPES.LAYOUT_CONFIG_SAVE)
      .to(CreateLayoutConfig)
      .inSingletonScope();
    container
      .bind<FindLayoutConfigByWarehouseAndStore>(TYPES.LAYOUT_CONFIG_FIND)
      .to(FindLayoutConfigByWarehouseAndStore)
      .inSingletonScope();
    container
      .bind<LayoutConfigRepository>(TYPES.LAYOUT_CONFIG_REPOSITORY)
      .to(LayoutConfigServices)
      .inSingletonScope();
    container
      .bind<DeleteZoneLayoutConfig>(TYPES.LAYOUT_CONFIG_DELETE_ZONE)
      .to(DeleteZoneLayoutConfig)
      .inSingletonScope();
    container
      .bind<DeleteRackLayoutConfig>(TYPES.LAYOUT_CONFIG_DELETE_RACK)
      .to(DeleteRackLayoutConfig)
      .inSingletonScope();
    //UpdateUser
    container
      .bind<AssignStoreToUser>(TYPES.ASSIGN_STORES_TO_USER)
      .to(AssignStoreToUser)
      .inSingletonScope();
    container
      .bind<UpdateUser>(TYPES.UPDATE_USER)
      .to(UpdateUser)
      .inSingletonScope();
    container
      .bind<DeleteUser>(TYPES.DELETE_USER)
      .to(DeleteUser)
      .inSingletonScope();
    container
      .bind<UserGetSecurityCode>(TYPES.GET_SECURITYCODE_USER)
      .to(UserGetSecurityCode)
      .inSingletonScope();
    container
      .bind<UserFindById>(TYPES.FINDBYID_USER)
      .to(UserFindById)
      .inSingletonScope();
    container
      .bind<CreateUser>(TYPES.CREATE_USER)
      .to(CreateUser)
      .inSingletonScope();
    // #region User
    container
      .bind<UserRepository>(TYPES.USER_REPOSITORY)
      .to(UserServices)
      .inSingletonScope();
    container
      .bind<UserFindAllForList>(TYPES.USER_FIND_ALL_FOR_LIST)
      .to(UserFindAllForList)
      .inSingletonScope();
    container
      .bind<UpdateUserProfile>(TYPES.USER_UPDATE_PROFILE)
      .to(UpdateUserProfile)
      .inSingletonScope();
    container
      .bind<UserFindByEmail>(TYPES.USER_FIND_BY_EMAIL)
      .to(UserFindByEmail)
      .inSingletonScope();
    container
      .bind<ResetPassword>(TYPES.USER_RESET_PASSWORD)
      .to(ResetPassword)
      .inSingletonScope();
    container
      .bind<ConfirmToken>(TYPES.USER_ACCEPT_TOKEN)
      .to(ConfirmToken)
      .inSingletonScope();
    container
      .bind<RemoveUserFromNamespace>(TYPES.REMOVE_FROM_NAMESPACE)
      .to(RemoveUserFromNamespace)
      .inSingletonScope();
    container
      .bind<InviteUserToNamespace>(TYPES.USER_INVITE_TO_NAMESPACE)
      .to(InviteUserToNamespace)
      .inSingletonScope();
    container
      .bind<UserAssignRole>(TYPES.USER_ASSIGN_ROLE)
      .to(UserAssignRole)
      .inSingletonScope();
    container
      .bind<SwitchesFindAll>(TYPES.FINDALL_SWITCH)
      .to(SwitchesFindAll)
      .inSingletonScope();
    container
      .bind<UpdateSwitchsStatus>(TYPES.UPDATE_SWITCHES_STATUS)
      .to(UpdateSwitchsStatus)
      .inSingletonScope();
    container
      .bind<SwitchesRepository>(TYPES.SWITCH_REPOSITORY)
      .to(SwitchesServices)
      .inSingletonScope();
    container
      .bind<OperationFindAll>(TYPES.FINDALL_TYPE_OPERATION)
      .to(OperationFindAll)
      .inSingletonScope();
    container
      .bind<OperationFindByProcessType>(TYPES.FINDBYPROCESSTYPE_TYPE_OPERATION)
      .to(OperationFindByProcessType)
      .inSingletonScope();
    container
      .bind<UpdateOperation>(TYPES.UPDATE_OPERATION)
      .to(UpdateOperation)
      .inSingletonScope();
    container
      .bind<DeleteOperation>(TYPES.DELETE_OPERATION)
      .to(DeleteOperation)
      .inSingletonScope();
    container
      .bind<CreateOperation>(TYPES.CREATE_OPERATION)
      .to(CreateOperation)
      .inSingletonScope();
    container
      .bind<OperationRepository>(TYPES.TYPE_OPERATION_REPOSITORY)
      .to(OperationServices)
      .inSingletonScope();
    container
      .bind<LoginFindToken>(TYPES.LOGIN_USER)
      .to(LoginFindToken)
      .inSingletonScope();
    container
      .bind<LoginRepository>(TYPES.LOGIN_REPOSITORY)
      .to(LoginService)
      .inSingletonScope();
    container
      .bind<NoveltyRepository>(TYPES.NOVELTY_REPOSITORY)
      .to(NoveltyServices)
      .inSingletonScope();
    container
      .bind<CreateActivities>(TYPES.CREATE_ACTIVITIES)
      .to(CreateActivities)
      .inSingletonScope();
    container
      .bind<DeleteActivity>(TYPES.DELETE_ACTIVITY)
      .to(DeleteActivity)
      .inSingletonScope();
    container
      .bind<ActivitiesRepository>(TYPES.ACTIVITIES_REPOSITORY)
      .to(ActivitiesServices)
      .inSingletonScope();
    container
      .bind<EventsFindAll>(TYPES.FINDALL_EVENTS)
      .to(EventsFindAll)
      .inSingletonScope();
    container
      .bind<EventsFindAllConfActive>(TYPES.FINDALL_CONF_ACTIVE_EVENTS)
      .to(EventsFindAllConfActive)
      .inSingletonScope();
    container
      .bind<EventsFindByProcessType>(TYPES.FINDEVENTSBYPROCESSTYPE_EVENTS)
      .to(EventsFindByProcessType)
      .inSingletonScope();
    container
      .bind<EventsFindAllByTimelineId>(TYPES.FINDALLBYTIMELINEID_EVENTS)
      .to(EventsFindAllByTimelineId)
      .inSingletonScope();
    container
      .bind<UpdateEvents>(TYPES.UPDATE_EVENTS)
      .to(UpdateEvents)
      .inSingletonScope();
    container
      .bind<CreateEvents>(TYPES.CREATE_EVENTS)
      .to(CreateEvents)
      .inSingletonScope();
    container
      .bind<DeleteEventOnGeneralTimeline>(TYPES.DELETE_EVENT_ON_GENERAL_TIMELINE)
      .to(DeleteEventOnGeneralTimeline)
      .inSingletonScope();
    container
      .bind<DeleteEventOnRouteTimeline>(TYPES.DELETE_EVENT_ON_ROUTE_TIMELINE)
      .to(DeleteEventOnRouteTimeline)
      .inSingletonScope();
    container
      .bind<CreateEventsExec>(TYPES.CREATE_EXEC_EVENTS)
      .to(CreateEventsExec)
      .inSingletonScope();
    container
      .bind<EventsRepository>(TYPES.EVENTS_REPOSITORY)
      .to(EventsServices)
      .inSingletonScope();
    container
      .bind<TimelineFindAll>(TYPES.FINDALL_TIMELINE)
      .to(TimelineFindAll)
      .inSingletonScope();
    container
      .bind<TimelineFindByProcessType>(TYPES.FINDBYPROCESSTYPE_TIMELINE)
      .to(TimelineFindByProcessType)
      .inSingletonScope();
    container
      .bind<SwitchFindAllByProcessType>(TYPES.FINDBYPROCESSTYPE_SWITCH)
      .to(SwitchFindAllByProcessType)
      .inSingletonScope();
    container
      .bind<UpdateTimeline>(TYPES.UPDATE_TIMELINE)
      .to(UpdateTimeline)
      .inSingletonScope();
    container
      .bind<CreateTimeline>(TYPES.CREATE_TIMELINE)
      .to(CreateTimeline)
      .inSingletonScope();
    container
      .bind<FindChartValuesByTimeLine>(TYPES.FIND_CHARTVALUESTIMELINE)
      .to(FindChartValuesByTimeLine)
      .inSingletonScope();
    container
      .bind<TimelineRepository>(TYPES.TIMELINE_REPOSITORY)
      .to(TimelineServices)
      .inSingletonScope();
    container
      .bind<ProcessFindAll>(TYPES.FINDALL_PROCESS)
      .to(ProcessFindAll)
      .inSingletonScope();
    container
      .bind<ProcessFindById>(TYPES.FINDBYID_PROCESS)
      .to(ProcessFindById)
      .inSingletonScope();
    container
      .bind<UpdateProcess>(TYPES.UPDATE_PROCESS)
      .to(UpdateProcess)
      .inSingletonScope();
    container
      .bind<CreateProcess>(TYPES.CREATE_PROCESS)
      .to(CreateProcess)
      .inSingletonScope();
    container
      .bind<ProcessRepository>(TYPES.PROCESS_TYPE_REPOSITORY)
      .to(ProcessServices)
      .inSingletonScope();
    container
      .bind<TransportRepository>(TYPES.TYPE_TRANSPORT_REPOSITORY)
      .to(TransportServices)
      .inSingletonScope();
    container
      .bind<TransportFindAll>(TYPES.FINDALL_TRANSPORT)
      .to(TransportFindAll)
      .inSingletonScope();
    container
      .bind<MonetaryTransactionRepository>(TYPES.MONETARY_TRANSACTION_REPOSITORY)
      .to(MonetaryTransactionServices)
      .inSingletonScope();
    container
      .bind<MonetaryTransactionFindAll>(TYPES.FINDALL_MONETARY_TRANSACTION)
      .to(MonetaryTransactionFindAll)
      .inSingletonScope();
    //VOLUME UNIT
    container
      .bind<VolumeRepository>(TYPES.VOLUME_REPOSITORY)
      .to(VolumeServices)
      .inSingletonScope();
    container
      .bind<CreateVolume>(TYPES.CREATE_VOLUME)
      .to(CreateVolume)
      .inSingletonScope();
    container
      .bind<UpdateVolume>(TYPES.UPDATE_VOLUME)
      .to(UpdateVolume)
      .inSingletonScope();
    container
      .bind<VolumeFindAll>(TYPES.FINDALL_VOLUME)
      .to(VolumeFindAll)
      .inSingletonScope();
    container
      .bind<DeleteVolume>(TYPES.DELETE_VOLUME)
      .to(DeleteVolume)
      .inSingletonScope();
    //WEIGHT UNIT
    container
      .bind<WeightRepository>(TYPES.WEIGHT_REPOSITORY)
      .to(WeightServices)
      .inSingletonScope();
    container
      .bind<CreateWeight>(TYPES.CREATE_WEIGHT)
      .to(CreateWeight)
      .inSingletonScope();
    container
      .bind<UpdateWeight>(TYPES.UPDATE_WEIGHT)
      .to(UpdateWeight)
      .inSingletonScope();
    container
      .bind<WeightFindAll>(TYPES.FINDALL_WEIGHT)
      .to(WeightFindAll)
      .inSingletonScope();
    container
      .bind<DeleteWeight>(TYPES.DELETE_WEIGHT)
      .to(DeleteWeight)
      .inSingletonScope();
    //STOCK
    container
      .bind<StockRepository>(TYPES.STOCK_REPOSITORY)
      .to(StockServices)
      .inSingletonScope();
    container
      .bind<StockFindAll>(TYPES.FINDALL_STOCK)
      .to(StockFindAll)
      .inSingletonScope();
    container
      .bind<FindAllMovements>(TYPES.FIND_STOCK_MOVES)
      .to(FindAllMovements)
      .inSingletonScope();
    container
      .bind<FindReferenceStock>(TYPES.FIND_STOCK_REFERENCE)
      .to(FindReferenceStock)
      .inSingletonScope();
    container
      .bind<FindByDateRangeAndStore>(TYPES.FINDBY_DATERANGE_AND_STORE)
      .to(FindByDateRangeAndStore)
      .inSingletonScope();
    container
      .bind<CalculateAverageCost>(TYPES.CALCULATE_AVERAGE_COST)
      .to(CalculateAverageCost)
      .inSingletonScope();
    container
      .bind<FindFreightStockByDateRangeAndStore>(TYPES.FIND_FREIGHT_STOCK)
      .to(FindFreightStockByDateRangeAndStore)
      .inSingletonScope();
    container
      .bind<FindFreightMovements>(TYPES.FIND_FREIGHT_STOCK_MOVEMENTS)
      .to(FindFreightMovements)
      .inSingletonScope();
    //UNIT OF MEASUREMENT
    container
      .bind<UnitOfMeasurementRepository>(TYPES.UNITOFMEASUREMENT_REPOSITORY)
      .to(UnitOfMeasurementServices)
      .inSingletonScope();
    container
      .bind<CreateUnitOfMeasurement>(TYPES.CREATE_UNITOFMEASUREMENT)
      .to(CreateUnitOfMeasurement)
      .inSingletonScope();
    container
      .bind<UpdateUnitOfMeasurement>(TYPES.UPDATE_UNITOFMEASUREMENT)
      .to(UpdateUnitOfMeasurement)
      .inSingletonScope();
    container
      .bind<UnitOfMeasurementFindAll>(TYPES.FINDALL_UNITOFMEASUREMENT)
      .to(UnitOfMeasurementFindAll)
      .inSingletonScope();
    container
      .bind<Runner>(TYPES.RUNNER)
      .to(Runner)
      .inSingletonScope();
    container
      .bind<ExecutorLink>(TYPES.EXECUTOR_LINK)
      .to(ExecutorLink)
      .inSingletonScope();
    container
      .bind<LoggerLink>(TYPES.LOGGER_LINK)
      .to(LoggerLink)
      .inSingletonScope();
    container
      .bind<Logger>(TYPES.LOGGER)
      .to(ConsoleLogger)
      .inSingletonScope();
    container.bind<Window>(TYPES.WINDOW).toConstantValue(window);
    container
      .bind<StateManager>(TYPES.STATE_MANAGER)
      .to(TyStateManager)
      .inSingletonScope();
    container
      .bind<Application>(TYPES.APPLICATION)
      .to(Application)
      .inSingletonScope();
    //PRICING ZONES
    container
      .bind<PricingZoneRepository>(TYPES.PRICING_ZONE_REPOSITORY)
      .to(PricingZoneServices)
      .inSingletonScope();
    container
      .bind<SavePricingZone>(TYPES.PRICING_ZONE_SAVE)
      .to(SavePricingZone)
      .inSingletonScope();
    container
      .bind<DeletePricingZone>(TYPES.PRICING_ZONE_DELETE)
      .to(DeletePricingZone)
      .inSingletonScope();
    container
      .bind<UpdatePricingZone>(TYPES.PRICING_ZONE_UPDATE)
      .to(UpdatePricingZone)
      .inSingletonScope();
    container
      .bind<PricingZoneFindAll>(TYPES.PRICING_ZONE_FIND_ALL)
      .to(PricingZoneFindAll)
      .inSingletonScope();
    container
      .bind<PricingZoneFindByStatus>(TYPES.PRICING_ZONE_FIND_BY_STATUS)
      .to(PricingZoneFindByStatus)
      .inSingletonScope();
    container
      .bind<PricingZoneFindByOriginAndDestination>(TYPES.PRICING_ZONE_FIND_BY_ORIGIN_AND_DESTINATION)
      .to(PricingZoneFindByOriginAndDestination)
      .inSingletonScope();
    //Pricing zone level
    container
      .bind<ZoneLevelRepository>(TYPES.PRICING_ZONE_LEVEL_REPOSITORY)
      .to(ZoneLevelService)
      .inSingletonScope();
    container
      .bind<DeleteZoneLevel>(TYPES.PRICING_ZONE_LEVEL_DELETE)
      .to(DeleteZoneLevel)
      .inSingletonScope();
    //Quotations
    container
      .bind<CreateQuotation>(TYPES.QUOTATIONS_SAVE)
      .to(CreateQuotation)
      .inSingletonScope();
    container
      .bind<UpdateQuotation>(TYPES.QUOTATIONS_UPDATE)
      .to(UpdateQuotation)
      .inSingletonScope();
    //Ordenes
    container
      .bind<WmsOrderFindAllByModule>(TYPES.QUOTATIONS_FIND_ALL_BY_TYPE_AND_MODULE)
      .to(WmsOrderFindAllByModule)
      .inSingletonScope();
    container
      .bind<WmsOrderFindByPk>(TYPES.WMS_ORDER_FIND_BY_PK)
      .to(WmsOrderFindByPk)
      .inSingletonScope();
    //Documents
    container
      .bind<DocumentRepository>(TYPES.DOCUMENT_TYPE_REPOSITORY)
      .to(DocumentServices)
      .inSingletonScope();
    container
      .bind<CreateDocument>(TYPES.DOCUMENT_TYPE_SAVE)
      .to(CreateDocument)
      .inSingletonScope();
    container
      .bind<UpdateDocument>(TYPES.DOCUMENT_TYPE_UPDATE)
      .to(UpdateDocument)
      .inSingletonScope();
    container
      .bind<DocumentFindAllByType>(TYPES.DOCUMENT_TYPE_FINDALL_BY_TYPE)
      .to(DocumentFindAllByType)
      .inSingletonScope();
    container
      .bind<DocumentFindBySwtichsAndFilter>(TYPES.DOCUMENT_TYPE_FIND_BY_SWITCHS_FILTERS)
      .to(DocumentFindBySwtichsAndFilter)
      .inSingletonScope();
    container
      .bind<DocumentFindByPk>(TYPES.DOCUMENT_TYPE_FIND_BY_PK)
      .to(DocumentFindByPk)
      .inSingletonScope();
    // Document Applied
    container
      .bind<DocumentAppliedRepository>(TYPES.DOCUMENT_APPLIED_REPOSITORY)
      .to(DocumentAppliedServices)
      .inSingletonScope();
    container
      .bind<CreateDocumentApplied>(TYPES.DOCUMENT_APPLIED_SAVE)
      .to(CreateDocumentApplied)
      .inSingletonScope();
    // #region PreRegister
    container
      .bind<PreRegisterRepository>(TYPES.PRE_REGISTER_REPOSITORY)
      .to(PreRegisterServices)
      .inSingletonScope();
    container
      .bind<FindConsigneeByOperation>(TYPES.PRE_REGISTER_FIND_CONSIGNEES)
      .to(FindConsigneeByOperation)
      .inSingletonScope();
    container
      .bind<PreRegisterCreateReport>(TYPES.PRE_REGISTER_CREATE_REPORT)
      .to(PreRegisterCreateReport)
      .inSingletonScope();
    container
      .bind<PreRegisterSave>(TYPES.PRE_REGISTER_SAVE)
      .to(PreRegisterSave)
      .inSingletonScope();
    container
      .bind<FindPaymentsForPreRegister>(TYPES.PRE_REGISTER_FIND_PAYMENTS)
      .to(FindPaymentsForPreRegister)
      .inSingletonScope();
    container
      .bind<PreRegisterFindAll>(TYPES.PRE_REGISTER_FINDALL)
      .to(PreRegisterFindAll)
      .inSingletonScope();
    container
      .bind<PreRegisterFindWithBulks>(TYPES.PRE_REGISTER_FINDALL_WITH_BULKS)
      .to(PreRegisterFindWithBulks)
      .inSingletonScope();
    container
      .bind<PreRegisterFindByDateAndType>(TYPES.PRE_REGISTER_FINDALL_BY_DATE_AND_TYPE)
      .to(PreRegisterFindByDateAndType)
      .inSingletonScope();
    container
      .bind<PreRegisterFindByDateAndLikeType>(TYPES.PRE_REGISTER_FINDALL_BY_DATE_AND_LIKE_TYPE)
      .to(PreRegisterFindByDateAndLikeType)
      .inSingletonScope();
    container
      .bind<PreRegisterUpdate>(TYPES.PRE_REGISTER_UPDATE)
      .to(PreRegisterUpdate)
      .inSingletonScope();
    container
      .bind<PreRegisterChangeType>(TYPES.PRE_REGISTER_CHANGE_TYPE)
      .to(PreRegisterChangeType)
      .inSingletonScope();
    container
      .bind<PreRegisterFindByPk>(TYPES.PRE_REGISTER_FIND_BY_PK)
      .to(PreRegisterFindByPk)
      .inSingletonScope();
    container
      .bind<PreRegisterFindForTraceability>(TYPES.PRE_REGISTER_FIND_FOR_TRACEABILITY)
      .to(PreRegisterFindForTraceability)
      .inSingletonScope();
    container
      .bind<PreRegisterFindForDashboard>(TYPES.PRE_REGISTER_FIND_FOR_DASHBOARD)
      .to(PreRegisterFindForDashboard)
      .inSingletonScope();
    //View location
    container
      .bind<ViewLocationRepository>(TYPES.VIEW_FIND_LOCATIONS_BY_STORE_AND_COMMODITY_REPOSITORY)
      .to(ViewLocationService)
      .inSingletonScope();
    container
      .bind<ViewLocationFindAllStoreAndCommodity>(TYPES.VIEW_FIND_LOCATIONS_BY_STORE_AND_COMMODITY)
      .to(ViewLocationFindAllStoreAndCommodity)
      .inSingletonScope();
    //Shipping method
    container
      .bind<ShippingMethodRepository>(TYPES.SHIPPING_METHOD_REPOSITORY)
      .to(ShippingMethodService)
      .inSingletonScope();
    container
      .bind<SaveShippingMethod>(TYPES.SHIPPING_METHOD_SAVE)
      .to(SaveShippingMethod)
      .inSingletonScope();
    container
      .bind<UpdateShippingMethod>(TYPES.SHIPPING_METHOD_UPDATE)
      .to(UpdateShippingMethod)
      .inSingletonScope();
    container
      .bind<DeleteShippingMethod>(TYPES.SHIPPING_METHOD_DELETE)
      .to(DeleteShippingMethod)
      .inSingletonScope();
    container
      .bind<FindAllShippingMethod>(TYPES.SHIPPING_METHOD_FIND_ALL)
      .to(FindAllShippingMethod)
      .inSingletonScope();
    container
      .bind<FindByStatusShippingMethod>(TYPES.SHIPPING_METHOD_FIND_BY_STATUS)
      .to(FindByStatusShippingMethod)
      .inSingletonScope();
    container
      .bind<FindByIdShippingMethod>(TYPES.SHIPPING_METHOD_FIND_BY_ID)
      .to(FindByIdShippingMethod)
      .inSingletonScope();
    //CommodityClass
    container
      .bind<CommodityClassRepository>(TYPES.COMMODITY_CLASS_REPOSITORY)
      .to(CommodityClassService)
      .inSingletonScope();
    container
      .bind<CreateCommodityClass>(TYPES.COMMODITY_CLASS_SAVE)
      .to(CreateCommodityClass)
      .inSingletonScope();
    container
      .bind<UpdateCommodityClass>(TYPES.COMMODITY_CLASS_UPDATE)
      .to(UpdateCommodityClass)
      .inSingletonScope();
    container
      .bind<DeleteCommodityClass>(TYPES.COMMODITY_CLASS_DELETE)
      .to(DeleteCommodityClass)
      .inSingletonScope();
    container
      .bind<FindAllCommodityClass>(TYPES.COMMODITY_CLASS_FIND_ALL)
      .to(FindAllCommodityClass)
      .inSingletonScope();
    container
      .bind<FindByCodeCommodityClass>(TYPES.COMMODITY_CLASS_FIND_BY_CODE)
      .to(FindByCodeCommodityClass)
      .inSingletonScope();
    container
      .bind<FindByStatusCommodityClass>(TYPES.COMMODITY_CLASS_FIND_BY_STATUS)
      .to(FindByStatusCommodityClass)
      .inSingletonScope();

    //#region Bill of landing
    container
      .bind<BillOfLandingRepository>(TYPES.BILL_OF_LANDING_REPOSITORY)
      .to(BillOfLandingServices)
      .inSingletonScope();
    container
      .bind<FindMasterBlByHouses>(TYPES.MASTER_BILL_OF_LANDING_FIND_BY_HOUSES)
      .to(FindMasterBlByHouses)
      .inSingletonScope();
    container
      .bind<CreateBillOfLandingReport>(TYPES.BILL_OF_LANDING_CREATE_REPORT)
      .to(CreateBillOfLandingReport)
      .inSingletonScope();
    container
      .bind<CreateBillOfLandingForPreRegister>(TYPES.MASTER_BILL_OF_LANDING_CREATE_FOR_PRE_REGISTER)
      .to(CreateBillOfLandingForPreRegister)
      .inSingletonScope();
    container
      .bind<FindMasterBLByPreRegister>(TYPES.MASTER_BILL_OF_LANDING_FIND_BY_PRE_REGISTER)
      .to(FindMasterBLByPreRegister)
      .inSingletonScope();

    container
      .bind<CreateBillOfLanding>(TYPES.BILL_OF_LANDING_SAVE)
      .to(CreateBillOfLanding)
      .inSingletonScope();
    container
      .bind<FindallBillOfLanding>(TYPES.BILL_OF_LANDING_FINDALL)
      .to(FindallBillOfLanding)
      .inSingletonScope();
    container
      .bind<FindDataForReportBillOfLading>(TYPES.BILL_OF_LANDING_FIND_DATA_FOR_REPORT)
      .to(FindDataForReportBillOfLading)
      .inSingletonScope();
    container
      .bind<CheckExistBillOfLanding>(TYPES.BILL_OF_LANDING_CHECK_EXIST)
      .to(CheckExistBillOfLanding)
      .inSingletonScope();
    container
      .bind<FindAllBillOfLandingWithOperationalData>(TYPES.BILL_OF_LANDING_FINDALL_WITH_OPERATION_DATA)
      .to(FindAllBillOfLandingWithOperationalData)
      .inSingletonScope();
    container
      .bind<FindMasterBLByPickingFilters>(TYPES.MASTER_BILL_OF_LANDING_FIND_BY_FILTERS)
      .to(FindMasterBLByPickingFilters)
      .inSingletonScope();

    // Reference group level
    container
      .bind<ReferenceGroupLevelRepository>(TYPES.REFERENCE_GROUP_LEVEL_REPOSITORY)
      .to(ReferenceGroupLevelServices)
      .inSingletonScope();
    container
      .bind<CreateReferenceGroupLevel>(TYPES.REFERENCE_GROUP_LEVEL_SAVE)
      .to(CreateReferenceGroupLevel)
      .inSingletonScope();
    container
      .bind<UpdateReferenceGroupLevel>(TYPES.REFERENCE_GROUP_LEVEL_UPDATE)
      .to(UpdateReferenceGroupLevel)
      .inSingletonScope();
    container
      .bind<DeleteReferenceGroupLevel>(TYPES.REFERENCE_GROUP_LEVEL_DELETE)
      .to(DeleteReferenceGroupLevel)
      .inSingletonScope();
    container
      .bind<FindByIdReferenceGroupLevel>(TYPES.REFERENCE_GROUP_LEVEL_FIND_BY_ID)
      .to(FindByIdReferenceGroupLevel)
      .inSingletonScope();
    container
      .bind<FindByParentReferenceGroupLevel>(TYPES.REFERENCE_GROUP_LEVEL_FIND_BY_PARENT)
      .to(FindByParentReferenceGroupLevel)
      .inSingletonScope();
    container
      .bind<FindAllReferenceGroupLevel>(TYPES.REFERENCE_GROUP_LEVEL_FIND_ALL)
      .to(FindAllReferenceGroupLevel)
      .inSingletonScope();
    container
      .bind<GetTreeReferenceGroupLevel>(TYPES.REFERENCE_GROUP_LEVEL_GET_TREE)
      .to(GetTreeReferenceGroupLevel)
      .inSingletonScope();
    container
      .bind<DocumentsV2Repository>(TYPES.DOCUMENTS_V2_REPOSITORY)
      .to(DocumentsV2Service)
      .inSingletonScope();
    container
      .bind<SaveDocumentsV2>(TYPES.DOCUMENTS_V2_SAVE)
      .to(SaveDocumentsV2)
      .inSingletonScope();
    container
      .bind<UpdateDocumentsV2>(TYPES.DOCUMENTS_V2_UPDATE)
      .to(UpdateDocumentsV2)
      .inSingletonScope();
    container
      .bind<FindDocumentsByPkV2>(TYPES.DOCUMENTS_V2_FIND_BY_PK)
      .to(FindDocumentsByPkV2)
      .inSingletonScope();
    container
      .bind<FindDocumentsByMaster>(TYPES.DOCUMENTS_V2_FIND_BY_MASTER)
      .to(FindDocumentsByMaster)
      .inSingletonScope();
    container
      .bind<FindDocumentsBySwAndFiltersV2>(TYPES.DOCUMENTS_V2_FIND_BY_SWITCHS_AND_FILTERS)
      .to(FindDocumentsBySwAndFiltersV2)
      .inSingletonScope();
    container
      .bind<FindAllDocumentsByTypeV2>(TYPES.DOCUMENTS_V2_FIND_ALL_BY_TYPE)
      .to(FindAllDocumentsByTypeV2)
      .inSingletonScope();
    container
      .bind<FindDocumentsBySwAndDateRangeV2>(TYPES.DOCUMENTS_V2_FIND_BY_SWITCHS_AND_DATERANGE)
      .to(FindDocumentsBySwAndDateRangeV2)
      .inSingletonScope();
    container
      .bind<TypeDocumentRepository>(TYPES.TYPE_DOCUMENT_REPOSITORY)
      .to(TypeDocumentService)
      .inSingletonScope();
    container
      .bind<TypeDocumentSave>(TYPES.TYPE_DOCUMENT_SAVE)
      .to(TypeDocumentSave)
      .inSingletonScope();
    container
      .bind<UpdateTypeDocument>(TYPES.TYPE_DOCUMENT_UPDATE)
      .to(UpdateTypeDocument)
      .inSingletonScope();
    container
      .bind<DeleteTypeDocument>(TYPES.TYPE_DOCUMENT_DELETE)
      .to(DeleteTypeDocument)
      .inSingletonScope();
    container
      .bind<FindAllTypeDocument>(TYPES.TYPE_DOCUMENT_FIND_ALL)
      .to(FindAllTypeDocument)
      .inSingletonScope();
    container
      .bind<FindByIdTypeDocument>(TYPES.TYPE_DOCUMENT_FIND_BY_TYPE)
      .to(FindByIdTypeDocument)
      .inSingletonScope();
    container
      .bind<ContableConceptsRepository>(TYPES.CONTABLE_CONCEPTS_REPOSITORY)
      .to(ContableConceptsService)
      .inSingletonScope();
    container
      .bind<CreateContableConcept>(TYPES.CONTABLE_CONCEPTS_SAVE)
      .to(CreateContableConcept)
      .inSingletonScope();
    container
      .bind<UpdateContableConcepts>(TYPES.CONTABLE_CONCEPTS_UPDATE)
      .to(UpdateContableConcepts)
      .inSingletonScope();
    container
      .bind<DeleteContableConcepts>(TYPES.CONTABLE_CONCEPTS_DELETE)
      .to(DeleteContableConcepts)
      .inSingletonScope();
    container
      .bind<FindAllContableConcepts>(TYPES.CONTABLE_CONCEPTS_FIND_ALL)
      .to(FindAllContableConcepts)
      .inSingletonScope();
    container
      .bind<FindByDocumentTypeContableConcept>(TYPES.CONTABLE_CONCEPTS_FIND_ALL_BY_DOCUMENT_TYPE)
      .to(FindByDocumentTypeContableConcept)
      .inSingletonScope();
    container
      .bind<FindByIdContableConcepts>(TYPES.CONTABLE_CONCEPTS_FIND_BY_ID)
      .to(FindByIdContableConcepts)
      .inSingletonScope();
    container
      .bind<FindByStatusContableConcepts>(TYPES.CONTABLE_CONCEPTS_FIND_BY_STATUS)
      .to(FindByStatusContableConcepts)
      .inSingletonScope();
    container
      .bind<HouseRepository>(TYPES.HOUSE_REPOSITORY)
      .to(HouseService)
      .inSingletonScope();
    container
      .bind<FindHouseByEntityAndMaster>(TYPES.HOUSE_FIND_ALL_BY_ENTITY_AND_MASTER)
      .to(FindHouseByEntityAndMaster)
      .inSingletonScope();
    container
      .bind<FindHouseSerialsByDateRange>(TYPES.HOUSE_FIND_SERIALS_BY_DATE_RANGE)
      .to(FindHouseSerialsByDateRange)
      .inSingletonScope();
    container
      .bind<CloudConfigRepository>(TYPES.CLOUD_CONFIG_REPOSITORY)
      .to(CloudConfigService)
      .inSingletonScope();
    container
      .bind<FindByCompanyIdCloudConfig>(TYPES.CLOUD_FIND_BY_COMPANY_ID)
      .to(FindByCompanyIdCloudConfig)
      .inSingletonScope();
    container
      .bind<CreateCloudConfig>(TYPES.CLOUD_CONFIG_SAVE)
      .to(CreateCloudConfig)
      .inSingletonScope();
    container
      .bind<LoginImageRepository>(TYPES.LOGIN_IMAGE_REPOSITORY)
      .to(LoginImageService)
      .inSingletonScope();
    container
      .bind<LoginImageFindAll>(TYPES.LOGIN_IMAGE_FIND_ALL)
      .to(LoginImageFindAll)
      .inSingletonScope();
    container
      .bind<LoginImageConfigRepository>(TYPES.LOGIN_IMAGE_CONFIG_REPOSITORY)
      .to(LoginImageConfigService)
      .inSingletonScope();
    container
      .bind<SaveLoginImageConfig>(TYPES.LOGIN_IMAGE_CONFIG_SAVE)
      .to(SaveLoginImageConfig)
      .inSingletonScope();

    //#region Menu
    container
      .bind<MenuRepository>(TYPES.MENU_REPOSITORY)
      .to(MenuServices)
      .inSingletonScope();
    container
      .bind<FindAllMenu>(TYPES.MENU_FIND_ALL)
      .to(FindAllMenu)
      .inSingletonScope();
    container
      .bind<FindMenuById>(TYPES.MENU_FIND_BY_ID)
      .to(FindMenuById)
      .inSingletonScope();
    container
      .bind<FindMenuByRole>(TYPES.MENU_FIND_BY_ROLE)
      .to(FindMenuByRole)
      .inSingletonScope();
    container
      .bind<AssignMenuToRole>(TYPES.MENU_ASSIGN_TO_ROLE)
      .to(AssignMenuToRole)
      .inSingletonScope();
    container
      .bind<CreateMenu>(TYPES.MENU_SAVE)
      .to(CreateMenu)
      .inSingletonScope();

    container
      .bind<UpdateMenu>(TYPES.MENU_UPDATE)
      .to(UpdateMenu)
      .inSingletonScope();

    container
      .bind<DeleteMenu>(TYPES.MENU_DELETE)
      .to(DeleteMenu)
      .inSingletonScope();

    //PreRegister EventLog Detail
    container
      .bind<PreRegisterEventLogDetailRepository>(TYPES.PRE_REGISTER_EVENT_LOG_DETAIL_REPOSITORY)
      .to(PreRegisterEventLogDetailService)
      .inSingletonScope();
    container
      .bind<PreRegisterEventLogDetailSave>(TYPES.PRE_REGISTER_EVENT_LOG_DETAIL_SAVE)
      .to(PreRegisterEventLogDetailSave)
      .inSingletonScope();
    container
      .bind<PreRegisterEventLogDetailFindDetail>(TYPES.PRE_REGISTER_EVENT_LOG_DETAIL_GET_DETAIL)
      .to(PreRegisterEventLogDetailFindDetail)
      .inSingletonScope();

    //Activities Transactional
    container
      .bind<ActivityTransactionalRepository>(TYPES.ACTIVITIES_TRANSACTIONAL_RESPOSITORY)
      .to(ActivityTransactionalService)
      .inSingletonScope();
    container
      .bind<ActivityTransactionalUpdateStatus>(TYPES.ACTIVITIES_TRANSACTIONAL_UPDATE_STATUS)
      .to(ActivityTransactionalUpdateStatus)
      .inSingletonScope();
    container
      .bind<FindTransactionalActivityById>(TYPES.ACTIVITIES_TRANSACTIONAL_FIND_BY_ID)
      .to(FindTransactionalActivityById)
      .inSingletonScope();
    container
      .bind<FindActivitiesForTraceabilityByEvent>(TYPES.ACTIVITIES_TRANSACTIONAL_FIND_FOR_TRACEABILITY_BY_EVENT)
      .to(FindActivitiesForTraceabilityByEvent)
      .inSingletonScope();

    //Airport
    container
      .bind<AirportRepository>(TYPES.AIRPORT_REPOSITORY)
      .to(AirportService)
      .inSingletonScope();
    container
      .bind<CreateAirport>(TYPES.AIRPORT_SAVE)
      .to(CreateAirport)
      .inSingletonScope();
    container
      .bind<UpdateAirport>(TYPES.AIRPORT_UPDATE)
      .to(UpdateAirport)
      .inSingletonScope();
    container
      .bind<FindByCodeAirport>(TYPES.AIRPORT_FIND_BY_CODE)
      .to(FindByCodeAirport)
      .inSingletonScope();
    container
      .bind<FindAllAirport>(TYPES.AIRPORT_FIND_ALL)
      .to(FindAllAirport)
      .inSingletonScope();
    //CONSIGNEE
    container
      .bind<FindByNameConsignee>(TYPES.CONSIGNEE_FIND_BY_NAME)
      .to(FindByNameConsignee)
      .inSingletonScope();
    container
      .bind<ConsigneeRepository>(TYPES.CONSIGNEE_REPOSITORY)
      .to(ConsigneeService)
      .inSingletonScope();

    container
      .bind<GeneralPortRepository>(TYPES.GENERAL_PORTS_REPOSITORY)
      .to(GeneralPortService)
      .inSingletonScope();

    container
      .bind<SearchGeneralPortByQuery>(TYPES.GENERAL_PORTS_FIND_BY_QUERY)
      .to(SearchGeneralPortByQuery)
      .inSingletonScope();

    // #region BULKS
    container
      .bind<BulkRepository>(TYPES.BULK_REPOSITORY)
      .to(BulkServices)
      .inSingletonScope();
    container
      .bind<BulkCubeProcess>(TYPES.BULK_CUBE_PROCESS)
      .to(BulkCubeProcess)
      .inSingletonScope();
    container
      .bind<FindBulkCubeDataByOperation>(TYPES.BULK_FIND_CUBE_INFO_BY_OPERATION)
      .to(FindBulkCubeDataByOperation)
      .inSingletonScope();
    container
      .bind<FindBulksInStock>(TYPES.FIND_BULK_IN_STOCK)
      .to(FindBulksInStock)
      .inSingletonScope();
    container
      .bind<UpdateBulkPickingStatus>(TYPES.BULK_UPDATE_PICKING_STATUS)
      .to(UpdateBulkPickingStatus)
      .inSingletonScope();
    container
      .bind<FindPickedBulks>(TYPES.BULK_FIND_PICKED)
      .to(FindPickedBulks)
      .inSingletonScope();
    container
      .bind<FindPickedBulksByStoreAndDate>(TYPES.BULK_FIND_PICKED_BY_STORE_AND_DATE)
      .to(FindPickedBulksByStoreAndDate)
      .inSingletonScope();
    container
      .bind<ConsolidateBulks>(TYPES.BULK_CONSOLIDATE)
      .to(ConsolidateBulks)
      .inSingletonScope();
    container
      .bind<FindBulkByOperation>(TYPES.BULK_FIND_BY_OPERATION_FOR_TABLE)
      .to(FindBulkByOperation)
      .inSingletonScope();
    container
      .bind<DeconsolidateBulks>(TYPES.BULK_DECONSOLIDATE)
      .to(DeconsolidateBulks)
      .inSingletonScope();
    container
      .bind<FindConsolidatedBulks>(TYPES.BULK_FIND_CONSOLIDATED)
      .to(FindConsolidatedBulks)
      .inSingletonScope();
    container
      .bind<FindBulksForPickingByHouseBL>(TYPES.BULK_FIND_FOR_PICKING_BY_HOUSE_BL)
      .to(FindBulksForPickingByHouseBL)
      .inSingletonScope();
    container
      .bind<FindBulksByPickingLists>(TYPES.BULK_FIND_BY_PICKING_LISTS)
      .to(FindBulksByPickingLists)
      .inSingletonScope();
    container
      .bind<FindBulksForPickingByFilters>(TYPES.BULK_FIND_FOR_PICKING)
      .to(FindBulksForPickingByFilters)
      .inSingletonScope();
    container
      .bind<FindBulksForWarehouseReport>(TYPES.BULKS_FIND_DATA_FOR_WAREHOUSE_RECEIPT_REPORT)
      .to(FindBulksForWarehouseReport)
      .inSingletonScope();

    //Branch
    container
      .bind<BranchRepository>(TYPES.BRANCH_REPOSITORY)
      .to(BranchService)
      .inSingletonScope();

    container
      .bind<FindAllBranch>(TYPES.BRANCH_FIND_ALL)
      .to(FindAllBranch)
      .inSingletonScope();

    container
      .bind<FindBranchByCode>(TYPES.BRANCH_FIND_BY_CODE)
      .to(FindBranchByCode)
      .inSingletonScope();

    container
      .bind<CreateBranch>(TYPES.BRANCH_SAVE)
      .to(CreateBranch)
      .inSingletonScope();

    container
      .bind<UpdateBranch>(TYPES.BRANCH_UPDATE)
      .to(UpdateBranch)
      .inSingletonScope();

    container
      .bind<DeleteBranch>(TYPES.BRANCH_DELETE)
      .to(DeleteBranch)
      .inSingletonScope();

    container
      .bind<FindBranchByStatus>(TYPES.BRANCH_FIND_BY_STATUS)
      .to(FindBranchByStatus)
      .inSingletonScope();

    //Gamma
    container
      .bind<GammaRepository>(TYPES.GAMMA_REPOSITORY)
      .to(GammaServices)
      .inSingletonScope();
    container
      .bind<CreateGamma>(TYPES.GAMMA_SAVE)
      .to(CreateGamma)
      .inSingletonScope();
    container
      .bind<UpdateGamma>(TYPES.GAMMA_UPDATE)
      .to(UpdateGamma)
      .inSingletonScope();
    container
      .bind<DeleteGamma>(TYPES.GAMMA_DELETE)
      .to(DeleteGamma)
      .inSingletonScope();
    container
      .bind<FindAllGamma>(TYPES.GAMMA_FINDALL)
      .to(FindAllGamma)
      .inSingletonScope();
    container
      .bind<ColorRepository>(TYPES.COLOR_REPOSITORY)
      .to(ColorServices)
      .inSingletonScope();
    container
      .bind<CreateColor>(TYPES.COLOR_SAVE)
      .to(CreateColor)
      .inSingletonScope();
    container
      .bind<UpdateColor>(TYPES.COLOR_UPDATE)
      .to(UpdateColor)
      .inSingletonScope();
    container
      .bind<DeleteColor>(TYPES.COLOR_DELETE)
      .to(DeleteColor)
      .inSingletonScope();
    container
      .bind<FindAllColor>(TYPES.COLOR_FINDALL)
      .to(FindAllColor)
      .inSingletonScope();
    container
      .bind<SizeRepository>(TYPES.SIZE_REPOSITORY)
      .to(SizeServices)
      .inSingletonScope();
    container
      .bind<CreateSize>(TYPES.SIZE_SAVE)
      .to(CreateSize)
      .inSingletonScope();
    container
      .bind<DeleteSize>(TYPES.SIZE_DELETE)
      .to(DeleteSize)
      .inSingletonScope();
    container
      .bind<FindAllSize>(TYPES.SIZE_FINDALL)
      .to(FindAllSize)
      .inSingletonScope();

    // Courier Price List
    container
      .bind<CourierPriceListRepository>(TYPES.COURIER_PRICE_LIST_REPOSITORY)
      .to(CourierPriceListService)
      .inSingletonScope();
    container
      .bind<CreateCourierPriceList>(TYPES.COURIER_PRICE_LIST_SAVE)
      .to(CreateCourierPriceList)
      .inSingletonScope();
    container
      .bind<DeleteCourierPriceList>(TYPES.COURIER_PRICE_LIST_DELETE)
      .to(DeleteCourierPriceList)
      .inSingletonScope();
    container
      .bind<UpdateCourierPriceList>(TYPES.COURIER_PRICE_LIST_UPDATE)
      .to(UpdateCourierPriceList)
      .inSingletonScope();
    container
      .bind<FindAllCourierPriceList>(TYPES.COURIER_PRICE_LIST_FIND_ALL)
      .to(FindAllCourierPriceList)
      .inSingletonScope();
    container
      .bind<FindByStatusCourierPriceList>(TYPES.COURIER_PRICE_LIST_FIND_BY_STATUS)
      .to(FindByStatusCourierPriceList)
      .inSingletonScope();
    container
      .bind<FindByIdCourierPriceList>(TYPES.COURIER_PRICE_LIST_FIND_BY_ID)
      .to(FindByIdCourierPriceList)
      .inSingletonScope();
    //#region DocsLin
    container
      .bind<DocsLinRepository>(TYPES.DOCS_LIN_REPOSITORY)
      .to(DocsLinService)
      .inSingletonScope();
    container
      .bind<GetDocsLinByTimeline>(TYPES.DOCS_LIN_FIND_BY_TIMELINE)
      .to(GetDocsLinByTimeline)
      .inSingletonScope();

    // #region Picking list
    container
      .bind<PickingListRepository>(TYPES.PICKING_LIST_REPOSITORY)
      .to(PickingListService)
      .inSingletonScope();

    container
      .bind<FindPickingListsByDate>(TYPES.PICKING_LIST_FIND_BY_DATE)
      .to(FindPickingListsByDate)
      .inSingletonScope();

    container
      .bind<FindNoShippedPickingListsNumber>(TYPES.PICKING_LIST_FIND_NO_SHIPPED)
      .to(FindNoShippedPickingListsNumber)
      .inSingletonScope();
    container
      .bind<FindShippedPickingListsNumberByDateRange>(TYPES.PICKING_LIST_FIND_SHIPPED_BY_DATE_RANGE)
      .to(FindShippedPickingListsNumberByDateRange)
      .inSingletonScope();

    container
      .bind<FindPickingListDataForReport>(TYPES.PICKING_LIST_FIND_DATA_FOR_REPORT)
      .to(FindPickingListDataForReport)
      .inSingletonScope();
    // #region House bill of landing
    container
      .bind<HouseBillOfLandingRepository>(TYPES.HOUSE_BILL_OF_LADING_REPOSITORY)
      .to(HouseBillOfLandingServices)
      .inSingletonScope();
    container
      .bind<FindHousesByOperation>(TYPES.HOUSE_BILL_OF_LANDING_FIND_BY_OPERATION)
      .to(FindHousesByOperation)
      .inSingletonScope();
    container
      .bind<SaveHouseBillOfLanding>(TYPES.HOUSE_BILL_OF_LADING_SAVE)
      .to(SaveHouseBillOfLanding)
      .inSingletonScope();
    container
      .bind<FindDataForReportHouseBillOfLading>(TYPES.FIND_DATA_FOR_REPORT_HOUSE_BILL_OF_LADING)
      .to(FindDataForReportHouseBillOfLading)
      .inSingletonScope();
    //#region
    container
      .bind<ConsolidatedRepository>(TYPES.CONSOLIDATED_REPOSITORY)
      .to(ConsolidatedService)
      .inSingletonScope();
    container
      .bind<FindConsolidatedDataForReport>(TYPES.CONSOLIDATED_FIND_DATA_FOR_REPORT)
      .to(FindConsolidatedDataForReport)
      .inSingletonScope();
    container
      .bind<FindConsolidatedNumbersByDateRange>(TYPES.CONSOLIDATED_FIND_BY_DATE)
      .to(FindConsolidatedNumbersByDateRange)
      .inSingletonScope();

    // #region Event transactional
    container
      .bind<EventTransactionalRepository>(TYPES.EVENT_TRANSACTIONAL_REPOSITORY)
      .to(TransactionalEventService)
      .inSingletonScope();
    container
      .bind<TransactionalEventFindByPk>(TYPES.EVENT_TRANSACTIONAL_FIND_BY_PK)
      .to(TransactionalEventFindByPk)
      .inSingletonScope();

    // #region Cargo details preset
    container
      .bind<PreparationRepository>(TYPES.CARGO_DETAILS_PRESET_REPOSITORY)
      .to(PreparationService)
      .inSingletonScope();
    container
      .bind<CreatePreparation>(TYPES.CARGO_DETAILS_PRESET_SAVE)
      .to(CreatePreparation)
      .inSingletonScope();

    container
      .bind<UpdatePreparation>(TYPES.CARGO_DETAILS_PRESET_UPDATE)
      .to(UpdatePreparation)
      .inSingletonScope();

    container
      .bind<DeletePreparation>(TYPES.CARGO_DETAILS_PRESET_DELETE)
      .to(DeletePreparation)
      .inSingletonScope();

    container
      .bind<FindAllPreparations>(TYPES.CARGO_DETAILS_PRESET_FIND_ALL)
      .to(FindAllPreparations)
      .inSingletonScope();

    container
      .bind<FindPreparationById>(TYPES.CARGO_DETAILS_PRESET_FIND_BY_ID)
      .to(FindPreparationById)
      .inSingletonScope();
    container
      .bind<FindAllPreparationsByStatus>(TYPES.CARGO_DETAILS_PRESET_FIND_STATUS)
      .to(FindAllPreparationsByStatus)
      .inSingletonScope();

    container.bind<VueConstructor>(TYPES.VUE).toConstantValue(Vue);
    this._container = container;
  }
  static instance() {
    if (this._instance === null) {
      Container._instance = new Container();
      this._instance = Container._instance;
    }
    return this._instance._container;
  }
}
