export const TYPES = {
  VUE: Symbol.for('VUE'),
  LOGGER: Symbol.for('LOGGER'),
  RUNNER: Symbol.for('RUNNER'),
  LOGGER_LINK: Symbol.for('LOGGER_LINK'),
  EXECUTOR_LINK: Symbol.for('EXECUTOR_LINK'),
  APPLICATION: Symbol.for('APPLICATION'),
  WINDOW: Symbol.for('WINDOW'),
  STATE_MANAGER: Symbol.for('STATE_MANAGER'),
  //CITY
  VIEW_FIND_TERRITORIES: Symbol.for('VIEW_FIND_TERRITORIES'),
  VIEW_TERRITORIES_REPOSITORY: Symbol.for('VIEW_TERRITORIES_REPOSITORY'),
  CITY_CREATE: Symbol.for('CITY_CREATE'),
  CITY_UPDATE: Symbol.for('CITY_UPDATE'),
  CITY_DELETE: Symbol.for('CITY_DELETE'),
  CITY_SEARCH_BY_COUNTRY_AND_STATE: Symbol.for('CITY_SEARCH_BY_COUNTRY_AND_STATE'),
  CITY_SEARCH_BY_DESCRIPTION_LIKE: Symbol.for('CITY_SEARCH_BY_DESCRIPTION_LIKE'),
  CITY_SEARCH_BY_COUNTRY_STATE_AND_DESCRIPTION: Symbol.for('CITY_SEARCH_BY_COUNTRY_STATE_AND_DESCRIPTION'),
  CITY_REPOSITORY: Symbol.for('CITY_REPOSITORY'),
  //End CITY
  //ZIP CODE
  ZIPCODE_SEARCH_BY_COUNTRY_STATE_CITY: Symbol.for('ZIPCODE_SEARCH_BY_COUNTRY_STATE_CITY'),
  ZIPCODE_SEARCH_BY_COUNTRY_STATE_CITY_ACTIVE: Symbol.for('ZIPCODE_SEARCH_BY_COUNTRY_STATE_CITY_ACTIVE'),
  ZIPCODE_SEARCH_ALL: Symbol.for('ZIPCODE_SEARCH_ALL'),
  ZIPCODE_REPOSITORY: Symbol.for('ZIPCODE_REPOSITORY'),
  //END ZIP
  API_VIEW_FIND_TERRITORIES_BY_QUERY: Symbol.for('API_VIEW_FIND_TERRITORIES_BY_QUERY'),
  // #region LOGIN
  LOGIN_REPOSITORY: Symbol.for('LOGIN_REPOSITORY'),
  LOGIN_GET_WORKSPACES: Symbol.for('LOGIN_GET_WORKSPACES'),
  LOGIN_WITH_WORKSPACE: Symbol.for('LOGIN_WITH_WORKSPACE'),
  LOGIN_USER: Symbol.for('LOGIN_USER'),
  EVENTS_REPOSITORY: Symbol.for('EVENTS_REPOSITORY'),
  CREATE_EVENTS: Symbol.for('CREATE_EVENTS'),
  CREATE_EXEC_EVENTS: Symbol.for('CREATE_EXEC_EVENTS'),
  UPDATE_EVENTS: Symbol.for('UPDATE_EVENTS'),
  DELETE_EVENT_ON_GENERAL_TIMELINE: Symbol.for('DELETE_EVENT_ON_GENERAL_TIMELINE'),
  DELETE_EVENT_ON_ROUTE_TIMELINE: Symbol.for('DELETE_EVENT_ON_ROUTE_TIMELINE'),
  FINDEVENTSBYPROCESSTYPE_EVENTS: Symbol.for('FINDEVENTSBYPROCESSTYPE_EVENTS'),
  FINDALLBYTIMELINEID_EVENTS: Symbol.for('FINDALLBYTIMELINEID_EVENTS'),
  FINDALL_EVENTS: Symbol.for('FINDALL_EVENTS'),
  FINDALL_CONF_ACTIVE_EVENTS: Symbol.for('FINDALL_CONF_ACTIVE_EVENTS'),
  //ACTIVITIES
  ACTIVITIES_REPOSITORY: Symbol.for('ACTIVITIES_REPOSITORY'),
  CREATE_ACTIVITIES: Symbol.for('CREATE_ACTIVITIES'),
  DELETE_ACTIVITY: Symbol.for('DELETE_ACTIVITY'),
  NOVELTY_REPOSITORY: Symbol.for('NOVELTY_REPOSITORY'),
  TYPE_OPERATION_REPOSITORY: Symbol.for('TYPE_OPERATION_REPOSITORY'),
  DELETE_OPERATION: Symbol.for('DELETE_OPERATION'),
  CREATE_OPERATION: Symbol.for('CREATE_OPERATION'),
  UPDATE_OPERATION: Symbol.for('UPDATE_OPERATION'),
  FINDBYPROCESSTYPE_TYPE_OPERATION: Symbol.for('FINDBYPROCESSTYPE_TYPE_OPERATION'),
  FINDALL_TYPE_OPERATION: Symbol.for('FINDALL_TYPE_OPERATION'),
  SWITCH_REPOSITORY: Symbol.for('SWITCH_REPOSITORY'),
  FINDALL_SWITCH: Symbol.for('FINDALL_SWITCH'),
  FINDBYPROCESSTYPE_SWITCH: Symbol.for('FINDBYPROCESSTYPE_SWITCH'),
  UPDATE_SWITCHES_STATUS: Symbol.for('UPDATE_SWITCHES_STATUS'),
  TIMELINE_REPOSITORY: Symbol.for('TIMELINE_REPOSITORY'),
  CREATE_TIMELINE: Symbol.for('CREATE_TIMELINE'),
  UPDATE_TIMELINE: Symbol.for('UPDATE_TIMELINE'),
  FINDBYPROCESSTYPE_TIMELINE: Symbol.for('FINDBYPROCESSTYPE_TIMELINE'),
  FINDALL_TIMELINE: Symbol.for('FINDALL_TIMELINE'),
  PROCESS_TYPE_REPOSITORY: Symbol.for('PROCESS_TYPE_REPOSITORY'),
  CREATE_PROCESS: Symbol.for('CREATE_PROCESS'),
  UPDATE_PROCESS: Symbol.for('UPDATE_PROCESS'),
  FINDBYID_PROCESS: Symbol.for('FINDBYID_PROCESS'),
  FINDALL_PROCESS: Symbol.for('FINDALL_PROCESS'),
  TYPE_TRANSPORT_REPOSITORY: Symbol.for('TYPE_TRANSPORT_REPOSITORY'),
  FINDALL_TRANSPORT: Symbol.for('FINDALL_TRANSPORT'),
  MONETARY_TRANSACTION_REPOSITORY: Symbol.for('MONETARY_TRANSACTION_REPOSITORY'),
  FINDALL_MONETARY_TRANSACTION: Symbol.for('FINDALL_MONETARY_TRANSACTION'),
  //Andres Escorcia
  //COUNTRY
  UPDATE_COUNTRY: Symbol.for('UPDATE_COUNTRY'),
  FINDALL_COUNTRY: Symbol.for('FINDALL_COUNTRY'),
  CREATE_COUNTRY: Symbol.for('CREATE_COUNTRY'),
  FINDBYSTATUS_COUNTRY: Symbol.for('FINDBYSTATUS_COUNTRY'),
  FIND_BY_DESCRIPTION_LIKE_COUNTRY: Symbol.for('FIND_BY_DESCRIPTION_LIKE_COUNTRY'),
  COUNTRY_FIND_BY_DESCRIPTION_IN_GENERAL_TERRITORIES: Symbol.for('COUNTRY_FIND_BY_DESCRIPTION_IN_GENERAL_TERRITORIES'),

  TERRITORIES_REPOSITORY: Symbol.for('TERRITORIES_REPOSITORY'),
  UPDATE_STATE: Symbol.for('UPDATE_STATE'),
  FINDALL_STATE: Symbol.for('FINDALL_STATE'),
  CREATE_STATE: Symbol.for('CREATE_STATE'),
  FINDBYSTATUS_STATE: Symbol.for('FINDBYSTATUS_STATE'),
  FINDBYCOUNTRYCODE_STATE: Symbol.for('FINDBYCOUNTRYCODE_STATE'),
  FIND_BY_DESCRIPTION_LIKE_STATE: Symbol.for('FIND_BY_DESCRIPTION_LIKE_STATE'),
  FIND_STATE_BY_COUNTRY_AND_DESCRIPTION_LIKE: Symbol.for('FIND_STATE_BY_COUNTRY_AND_DESCRIPTION_LIKE'),
  STATE_REPOSITORY: Symbol.for('STATE_REPOSITORY'),
  UPDATE_ZONE: Symbol.for('UPDATE_ZONE'),
  FINDSTATUSCOUNTRYSTATE_ZONE: Symbol.for('FINDSTATUSCOUNTRYSTATE_ZONE'),
  FINDALL_ZONE: Symbol.for('FINDALL_ZONE'),
  CREATE_ZONE: Symbol.for('CREATE_ZONE'),
  FIND_BY_DESCRIPTION_LIKE_ZONE: Symbol.for('FIND_BY_DESCRIPTION_LIKE_ZONE'),
  ZONE_REPOSITORY: Symbol.for('ZONE_REPOSITORY'),
  UPDATE_CURRENCY: Symbol.for('UPDATE_CURRENCY'),
  //Currency
  FINDALL_CURRENCY: Symbol.for('FINDALL_CURRENCY'),
  FINDALL_CURRENCY_WITH_RATE: Symbol.for('FINDALL_CURRENCY_WITH_RATE'),
  CREATE_CURRENCY: Symbol.for('CREATE_CURRENCY'),
  DELETE_CURRENCY: Symbol.for('DELETE_CURRENCY'),
  FINDBYID_CURRENCY: Symbol.for('FINDBYID_CURRENCY'),
  CURRENCY_REPOSITORY: Symbol.for('CURRENCY_REPOSITORY'),
  FINDBYCURRENCYCODE_CURRENCYFACTOR: Symbol.for('FINDBYCURRENCYCODE_CURRENCYFACTOR'),
  FINDRATEBYDATE_CURRENCY_FACTOR: Symbol.for('FINDRATEBYDATE_CURRENCY_FACTOR'),
  UPDATE_CURRENCYFACTOR: Symbol.for('UPDATE_CURRENCYFACTOR'),
  FINDALL_CURRENCYFACTOR: Symbol.for('FINDALL_CURRENCYFACTOR'),
  CREATE_CURRENCYFACTOR: Symbol.for('CREATE_CURRENCYFACTOR'),
  FINDBYSTATUS_CURRENCYFACTOR: Symbol.for('FINDBYSTATUS_CURRENCYFACTOR'),
  CURRENCYFACTOR_REPOSITORY: Symbol.for('CURRENCYFACTOR_REPOSITORY'),
  FINDBYID_ROUTES: Symbol.for('FINDBYID_ROUTES'),
  //ROUTES
  UPDATE_ROUTES: Symbol.for('UPDATE_ROUTES'),
  FINDALL_ROUTES: Symbol.for('FINDALL_ROUTES'),
  CREATE_ROUTES: Symbol.for('CREATE_ROUTES'),
  FINDBYSTATUS_ROUTES: Symbol.for('FINDBYSTATUS_ROUTES'),
  ROUTES_REPOSITORY: Symbol.for('ROUTES_REPOSITORY'),
  ROUTES_FIND_BY_ORIGIN_AND_DESTINATION: Symbol.for('ROUTES_FIND_BY_ORIGIN_AND_DESTINATION'),
  FINDBYSTATUS_WAREHOUSE: Symbol.for('FINDBYSTATUS_WAREHOUSE'),
  FINDBYCODE_WAREHOUSE: Symbol.for('FINDBYCODE_WAREHOUSE'),
  UPDATE_WAREHOUSE: Symbol.for('UPDATE_WAREHOUSE'),
  FINDALL_WAREHOUSE: Symbol.for('FINDALL_WAREHOUSE'),
  CREATE_WAREHOUSE: Symbol.for('CREATE_WAREHOUSE'),
  DELETE_WAREHOUSE: Symbol.for('DELETE_WAREHOUSE'),
  WAREHOUSE_REPOSITORY: Symbol.for('WAREHOUSE_REPOSITORY'),
  FINDBYID_COMPANY: Symbol.for('FINDBYID_COMPANY'),
  UPDATE_COMPANY: Symbol.for('UPDATE_COMPANY'),
  FINDALL_COMPANY: Symbol.for('FINDALL_COMPANY'),
  CREATE_COMPANY: Symbol.for('CREATE_COMPANY'),
  COMPANY_REPOSITORY: Symbol.for('COMPANY_REPOSITORY'),
  FINDALL_FILE: Symbol.for('FINDALL_FILE'),
  CREATE_FILE: Symbol.for('CREATE_FILE'),
  FILE_REPOSITORY: Symbol.for('FILE_REPOSITORY'),
  // NEW FILE METHODS
  FIND_FILE_BY_NAME: Symbol.for('FIND_FILE_BY_NAME'),
  SAVE_FILE: Symbol.for('SAVE_FILE'),
  DELETE_TAX: Symbol.for('DELETE_TAX'),
  UPDATE_TAXE: Symbol.for('UPDATE_TAXE'),
  FINDALL_TAXE: Symbol.for('FINDALL_TAXE'),
  CREATE_TAXE: Symbol.for('CREATE_TAXE'),
  FINDBYSTATUS_TAXE: Symbol.for('FINDBYSTATUS_TAXE'),
  TAXE_REPOSITORY: Symbol.for('TAXE_REPOSITORY'),
  ORDER_REPOSITORY: Symbol.for('ORDER_REPOSITORY'),
  CREATE_ORDER: Symbol.for('CREATE_ORDER'),
  UPDATE_ORDER: Symbol.for('UPDATE_ORDER'),
  ORDER_FIND_ALL_BY_MODULE: Symbol.for('ORDER_FIND_ALL_BY_MODULE'),
  ORDER_FIND_ALL_BY_TYPE_AND_STATE: Symbol.for('ORDER_FIND_ALL_BY_TYPE_AND_STATE'),
  FINDBYPK_ORDER: Symbol.for('FINDBYPK_ORDER'),
  FINDBYDATERANGE_ORDER: Symbol.for('FINDBYDATERANGE_ORDER'),
  FINDBYDATERANGEANDCUSTOMERID_ORDER: Symbol.for('FINDBYDATERANGEANDCUSTOMERID_ORDER'),
  CONSECUTIVE_REPOSITORY: Symbol.for('CONSECUTIVE_REPOSITORY'),
  FINDBYWAREHOUSEANDTYPE_CONSECUTIVE: Symbol.for('FINDBYWAREHOUSEANDTYPE_CONSECUTIVE'),

  //Carlos
  UPDATE_PRICE_LIST_VOLUME: Symbol.for('UPDATE_PRICE_LIST_VOLUME'),
  FINDALL_PRICE_LIST_VOLUME: Symbol.for('FINDALL_PRICE_LIST_VOLUME'),
  CREATE_PRICE_LIST_VOLUME: Symbol.for('CREATE_PRICE_LIST_VOLUME'),
  IMPORT_PRICE_LIST: Symbol.for('IMPORT_PRICE_LIST'),
  FINDBYSTATUS_PRICE_LIST_VOLUME: Symbol.for('FINDBYSTATUS_PRICE_LIST_VOLUME'),
  FINDBY_ORIGINANDDESTINATION_VOLUME: Symbol.for('FINDBY_ORIGINANDDESTINATION_VOLUME'),
  PRICE_LIST_REPOSITORY_VOLUME: Symbol.for('PRICE_LIST_REPOSITORY_VOLUME'),
  UPDATE_PRICE_LIST_WEIGHT: Symbol.for('UPDATE_PRICE_LIST_WEIGHT'),
  FINDALL_PRICE_LIST_WEIGHT: Symbol.for('FINDALL_PRICE_LIST_WEIGHT'),
  CREATE_PRICE_LIST_WEIGHT: Symbol.for('CREATE_PRICE_LIST_WEIGHT'),
  IMPORT_PRICE_LIST_WEIGHT: Symbol.for('IMPORT_PRICE_LIST_WEIGHT'),
  FINDBYSTATUS_PRICE_LIST_WEIGHT: Symbol.for('FINDBYSTATUS_PRICE_LIST_WEIGHT'),
  FINDBY_ORIGINANDDESTINATION_WEIGHT: Symbol.for('FINDBY_ORIGINANDDESTINATION_WEIGHT'),
  PRICE_LIST_REPOSITORY_WEIGHT: Symbol.for('PRICE_LIST_REPOSITORY_WEIGHT'),
  FINDALL_PALLETSFACTOR: Symbol.for('FINDALL_PALLETSFACTOR'),
  CREATE_PALLETSFACTOR: Symbol.for('CREATE_PALLETSFACTOR'),
  UPDATE_PALLETSFACTOR: Symbol.for('UPDATE_PALLETSFACTOR'),
  PALLETSFACTOR_REPOSITORY: Symbol.for('PALLETSFACTOR_REPOSITORY'),
  UPDATE_INVOICE: Symbol.for('UPDATE_INVOICE'),
  FINDALL_INVOICE: Symbol.for('FINDALL_INVOICE'),
  CREATE_INVOICE: Symbol.for('CREATE_INVOICE'),
  CREATE_INVOICE_ORDER: Symbol.for('CREATE_INVOICE_ORDER'),
  FINDBYCLIENTID_INVOICE: Symbol.for('FINDBYCLIENTID_INVOICE'),
  FINDBYDATERANGE_INVOICE: Symbol.for('FINDBYDATERANGE_INVOICE'),
  FINDBYDATERANGEANDCUSTOMER_INVOICE: Symbol.for('FINDBYDATERANGEANDCUSTOMER_INVOICE'),
  FINDBYPK_INVOICE: Symbol.for('FINDBYPK_INVOICE'),
  INVOICE_REPOSITORY: Symbol.for('INVOICE_REPOSITORY'),
  // #region USER
  USER_REPOSITORY: Symbol.for('USER_REPOSITORY'),
  USER_ACCEPT_TOKEN: Symbol.for('USER_ACCEPT_TOKEN'),
  REMOVE_FROM_NAMESPACE: Symbol.for('REMOVE_FROM_NAMESPACE'),
  ASSIGN_STORES_TO_USER: Symbol.for('ASSIGN_STORES_TO_USER'),
  CREATE_USER: Symbol.for('CREATE_USER'),
  USER_INVITE_TO_NAMESPACE: Symbol.for('USER_INVITE_TO_NAMESPACE'),
  UPDATE_USER: Symbol.for('UPDATE_USER'),
  DELETE_USER: Symbol.for('DELETE_USER'),
  GET_SECURITYCODE_USER: Symbol.for('GET_SECURITYCODE_USER'),
  FINDBYID_USER: Symbol.for('FINDBYID_USER'),
  USER_FIND_BY_EMAIL: Symbol.for('USER_FIND_BY_EMAIL'),
  UPDATE_USER_PASSWORD: Symbol.for('UPDATE_USER_PASSWORD'),
  PURCHASE_REPOSITORY: Symbol.for('PURCHASE_REPOSITORY'),
  PURCHASE_SCRAPING: Symbol.for('PURCHASE_SCRAPING'),
  GET_TEMPORAL_TOKEN: Symbol.for('GET_TEMPORAL_TOKEN'),
  USER_FIND_ALL_FOR_LIST: Symbol.for('USER_FIND_ALL_FOR_LIST'),
  USER_ASSIGN_ROLE: Symbol.for('USER_ASSIGN_ROLE'),
  USER_RESET_PASSWORD: Symbol.for('USER_RESET_PASSWORD'),
  USER_UPDATE_PROFILE: Symbol.for('USER_UPDATE_PROFILE'),
  //ROLE
  ROLE_REPOSITORY: Symbol.for('ROLE_REPOSITORY'),
  CREATE_ROLE: Symbol.for('CREATE_ROLE'),
  CREATE_ROLE_V2: Symbol.for('CREATE_ROLE_V2'),
  UPDATE_ROLE_V2: Symbol.for('UPDATE_ROLE_V2'),
  DELETE_ROLE: Symbol.for('DELETE_ROLE'),
  UPDATE_ROLE: Symbol.for('UPDATE_ROLE'),
  FINDBYMENU_ROLE: Symbol.for('FINDBYMENU_ROLE'),
  FINDMENUPLANEBYROLEID_ROLE: Symbol.for('FINDMENUPLANEBYROLEID_ROLE'),
  FINDMENUPLANEBYROLENAME_ROLE: Symbol.for('FINDMENUPLANEBYROLENAME_ROLE'),
  FINDALL_ROLE: Symbol.for('FINDALL_ROLE'),
  FIND_ROLE_BY_COMPANY_ID: Symbol.for('FIND_ROLE_BY_COMPANY_ID'),
  CUSTOMER_REPOSITORY: Symbol.for('CUSTOMER_REPOSITORY'),
  ENTITY_MAX_CODE: Symbol.for('ENTITY_MAX_CODE'),
  FINDALLLIKE_CUSTOMER: Symbol.for('FINDALLLIKE_CUSTOMER'),
  CREATE_CUSTOMER: Symbol.for('CREATE_CUSTOMER'),
  GET_SECURITYCODE_CUSTOMER: Symbol.for('GET_SECURITYCODE_CUSTOMER'),
  FINDBYID_CUSTOMER: Symbol.for('FINDBYID_CUSTOMER'),
  UPDATE_CUSTOMER_PASSWORD: Symbol.for('UPDATE_CUSTOMER_PASSWORD'),
  FIND_CHARTVALUESTIMELINE: Symbol.for('FIND_CHARTVALUESTIMELINE'),
  UNITOFMEASUREMENT_REPOSITORY: Symbol.for('UNITOFMEASUREMENT_REPOSITORY'),
  CREATE_UNITOFMEASUREMENT: Symbol.for('CREATE_UNITOFMEASUREMENT'),
  UPDATE_UNITOFMEASUREMENT: Symbol.for('UPDATE_UNITOFMEASUREMENT'),
  FINDALL_UNITOFMEASUREMENT: Symbol.for('FINDALL_UNITOFMEASUREMENT'),
  //WEIGHT
  WEIGHT_REPOSITORY: Symbol.for('WEIGHT_REPOSITORY'),
  CREATE_WEIGHT: Symbol.for('CREATE_WEIGHT'),
  UPDATE_WEIGHT: Symbol.for('UPDATE_WEIGHT'),
  FINDALL_WEIGHT: Symbol.for('FINDALL_WEIGHT'),
  DELETE_WEIGHT: Symbol.for('DELETE_WEIGHT'),
  //VOLUME
  VOLUME_REPOSITORY: Symbol.for('VOLUME_REPOSITORY'),
  CREATE_VOLUME: Symbol.for('CREATE_VOLUME'),
  UPDATE_VOLUME: Symbol.for('UPDATE_VOLUME'),
  FINDALL_VOLUME: Symbol.for('FINDALL_VOLUME'),
  DELETE_VOLUME: Symbol.for('DELETE_VOLUME'),
  //STOCK
  FINDALL_STOCK: Symbol.for('FINDALL_STOCK'),
  FIND_STOCK_MOVES: Symbol.for('FIND_STOCK_MOVES'),
  FIND_STOCK_REFERENCE: Symbol.for('FIND_STOCK_REFERENCE'),
  FINDBY_DATERANGE_AND_STORE: Symbol.for('FINDBY_DATERANGE_AND_STORE'),
  CALCULATE_AVERAGE_COST: Symbol.for('CALCULATE_AVERAGE_COST'),
  STOCK_REPOSITORY: Symbol.for('STOCK_REPOSITORY'),
  FIND_FREIGHT_STOCK_MOVEMENTS: Symbol.for('FIND_FREIGHT_STOCK_MOVEMENTS'),
  FIND_FREIGHT_STOCK: Symbol.for('FIND_FREIGHT_STOCK'),
  //WMS INNOTRI
  CREATE_ALTERNATIVE_REFERENCE: Symbol.for('CREATE_ALTERNATIVE_REFERENCE'),
  FINDALL_ALTERNATIVE_REFERENCE: Symbol.for('FINDALL_ALTERNATIVE_REFERENCE'),
  FINDBYREFERENCE_ALTERNATIVE_REFERENCE: Symbol.for('FINDBYREFERENCE_ALTERNATIVE_REFERENCE'),
  FINDOWNER_ALTERNATIVE_REFERENCE: Symbol.for('FINDOWNER_ALTERNATIVE_REFERENCE'),
  UPDATE_ALTERNATIVE_REFERENCE: Symbol.for('UPDATE_ALTERNATIVE_REFERENCE'),
  DELETE_ALTERNATIVE_REFERENCE: Symbol.for('DELETE_ALTERNATIVE_REFERENCE'),
  ALTERNATIVE_REFERENCE_REPOSITORY: Symbol.for('ALTERNATIVE_REFERENCE_REPOSITORY'),

  CREATE_CATEGORY_REFERENCE: Symbol.for('CREATE_CATEGORY_REFERENCE'),
  FINDALL_CATEGORY_REFERENCE: Symbol.for('FINDALL_CATEGORY_REFERENCE'),
  UPDATE_CATEGORY_REFERENCE: Symbol.for('UPDATE_CATEGORY_REFERENCE'),
  DELETE_CATEGORY_REFERENCE: Symbol.for('DELETE_CATEGORY_REFERENCE'),
  CATEGORY_REFERENCE_REPOSITORY: Symbol.for('CATEGORY_REFERENCE_REPOSITORY'),

  FINDALL_CATEGORY_REFERENCE_SERVICE: Symbol.for('FINDALL_CATEGORY_REFERENCE_SERVICE'),

  CREATE_CLASS_REFERENCE: Symbol.for('CREATE_CLASS_REFERENCE'),
  FINDALL_CLASS_REFERENCE: Symbol.for('FINDALL_CLASS_REFERENCE'),
  UPDATE_CLASS_REFERENCE: Symbol.for('UPDATE_CLASS_REFERENCE'),
  DELETE_CLASS_REFERENCE: Symbol.for('DELETE_CLASS_REFERENCE'),
  CLASS_REFERENCE_REPOSITORY: Symbol.for('CLASS_REFERENCE_REPOSITORY'),
  //#region PACKAGING
  CREATE_PACKAGING_REFERENCE: Symbol.for('CREATE_PACKAGING_REFERENCE'),
  FINDALL_PACKAGING_REFERENCE: Symbol.for('FINDALL_PACKAGING_REFERENCE'),
  FINDBYREFERENCE_PACKAGING_REFERENCE: Symbol.for('FINDBYREFERENCE_PACKAGING_REFERENCE'),
  UPDATE_PACKAGING_REFERENCE: Symbol.for('UPDATE_PACKAGING_REFERENCE'),
  DELETE_PACKAGING_REFERENCE: Symbol.for('DELETE_PACKAGING_REFERENCE'),
  PACKAGING_REFERENCE_REPOSITORY: Symbol.for('PACKAGING_REFERENCE_REPOSITORY'),
  FIND_PACKAGING_BY_REFERENCE_AND_TYPE: Symbol.for('FIND_PACKAGING_BY_REFERENCE_AND_TYPE'),

  SAVE_PRICING_ENGINE_REFERENCE: Symbol.for('SAVE_PRICING_ENGINE_REFERENCE'),
  PRICING_ENGINE_REPOSITORY: Symbol.for('PRICING_ENGINE_REPOSITORY'),

  COURIER_ORDER_REPOSITORY: Symbol.for('COURIER_ORDER_REPOSITORY'),
  COURIER_ORDER_CALCULATE_PRICING: Symbol.for('COURIER_ORDER_CALCULATE_PRICING'),

  PRICING_ENGINE_PARAM_REPOSITORY: Symbol.for('PRICING_ENGINE_PARAM_REPOSITORY'),
  PRICING_ENGINE_PARAM_FIND: Symbol.for('PRICING_ENGINE_PARAM_FIND'),

  // #region REFERENCES

  CREATE_REFERENCE: Symbol.for('CREATE_REFERENCE'),
  FIND_REFERENCES_BY_QUERY: Symbol.for('FIND_REFERENCES_BY_QUERY'),
  FINDALL_REFERENCE: Symbol.for('FINDALL_REFERENCE'),
  UPDATE_REFERENCE: Symbol.for('UPDATE_REFERENCE'),
  UPDATE_BARCODE_REFERENCES: Symbol.for('UPDATE_BARCODE_REFERENCES'),
  DELETE_REFERENCE: Symbol.for('DELETE_REFERENCE'),
  FINDBYID_REFERENCE: Symbol.for('FINDBYID_REFERENCE'),
  FIND_REFERENCES_BY_MAIN_SKU: Symbol.for('FIND_REFERENCES_BY_MAIN_SKU'),
  REFERENCE_REPOSITORY: Symbol.for('REFERENCE_REPOSITORY'),
  REFERENCES_SAVE_SERIALS: Symbol.for('REFERENCES_SAVE_SERIALS'),
  REFERENCES_FIND_SERIALS_BY_REFERENCE: Symbol.for('REFERENCES_FIND_SERIALS_BY_REFERENCE'),
  REFERENCES_DELETE_SERIAL: Symbol.for('REFERENCES_DELETE_SERIAL'),
  // TIMELINE REFERENCES
  TIMELINE_REFERENCES_ASSIGN: Symbol.for('TIMELINE_REFERENCES_ASSIGN'),
  TIMELINE_REFERENCES_FIND_BY_TIMELINE: Symbol.for('TIMELINE_REFERENCES_FIND_BY_TIMELINE'),
  TIMELINE_REFERENCES_CODE_FIND_BY_TIMELINE: Symbol.for('TIMELINE_REFERENCES_CODE_FIND_BY_TIMELINE'),

  PRODUCT_REPOSITORY: Symbol.for('PRODUCT_REPOSITORY'),
  CREATE_PRODUCT: Symbol.for('CREATE_PRODUCT'),
  UPDATE_PRODUCT: Symbol.for('UPDATE_PRODUCT'),
  PRODUCT_FIND_BY_SKU: Symbol.for('PRODUCT_FIND_BY_SKU'),

  CREATE_ADDRESS: Symbol.for('CREATE_ADDRESS'),
  FINDALL_ADDRESS: Symbol.for('FINDALL_ADDRESS'),
  UPDATE_ADDRESS: Symbol.for('UPDATE_ADDRESS'),
  ADDRESS_REPOSITORY: Symbol.for('ADDRESS_REPOSITORY'),

  CREATE_BANK: Symbol.for('CREATE_BANK'),
  FINDALL_BANK: Symbol.for('FINDALL_BANK'),
  UPDATE_BANK: Symbol.for('UPDATE_BANK'),
  BANK_REPOSITORY: Symbol.for('BANK_REPOSITORY'),

  CREATE_CONTACT: Symbol.for('CREATE_CONTACT'),
  FINDALL_CONTACT: Symbol.for('FINDALL_CONTACT'),
  UPDATE_CONTACT: Symbol.for('UPDATE_CONTACT'),
  CONTACT_REPOSITORY: Symbol.for('CONTACT_REPOSITORY'),

  CREATE_GROUP_AGENT_SERVICE: Symbol.for('CREATE_GROUP_AGENT_SERVICE'),
  FINDALL_GROUP_AGENT_SERVICE: Symbol.for('FINDALL_GROUP_AGENT_SERVICE'),
  UPDATE_GROUP_AGENT_SERVICE: Symbol.for('UPDATE_GROUP_AGENT_SERVICE'),
  DELETE_GROUP_AGENT_SERVICE: Symbol.for('DELETE_GROUP_AGENT_SERVICE'),
  GROUP_AGENT_SERVICE_REPOSITORY: Symbol.for('GROUP_AGENT_SERVICE_REPOSITORY'),

  CREATE_INCOTERMS: Symbol.for('CREATE_INCOTERMS'),
  FINDALL_INCOTERMS: Symbol.for('FINDALL_INCOTERMS'),
  UPDATE_INCOTERMS: Symbol.for('UPDATE_INCOTERMS'),
  DELETE_INCOTERMS: Symbol.for('DELETE_INCOTERMS'),
  INCOTERMS_REPOSITORY: Symbol.for('INCOTERMS_REPOSITORY'),

  CREATE_PAYMENT_METHODS: Symbol.for('CREATE_PAYMENT_METHODS'),
  FINDALL_PAYMENT_METHODS: Symbol.for('FINDALL_PAYMENT_METHODS'),
  UPDATE_PAYMENT_METHODS: Symbol.for('UPDATE_PAYMENT_METHODS'),
  DELETE_PAYMENT_METHODS: Symbol.for('DELETE_PAYMENT_METHODS'),
  PAYMENT_METHODS_REPOSITORY: Symbol.for('PAYMENT_METHODS_REPOSITORY'),

  CREATE_TERMS_PAYMENT: Symbol.for('CREATE_TERMS_PAYMENT'),
  FINDALL_TERMS_PAYMENT: Symbol.for('FINDALL_TERMS_PAYMENT'),
  UPDATE_TERMS_PAYMENT: Symbol.for('UPDATE_TERMS_PAYMENT'),
  DELETE_TERMS_PAYMENT: Symbol.for('DELETE_TERMS_PAYMENT'),
  TERMS_PAYMENT_REPOSITORY: Symbol.for('TERMS_PAYMENT_REPOSITORY'),

  CREATE_TYPE_CUSTOMER: Symbol.for('CREATE_TYPE_CUSTOMER'),
  FINDALL_TYPE_CUSTOMER: Symbol.for('FINDALL_TYPE_CUSTOMER'),
  UPDATE_TYPE_CUSTOMER: Symbol.for('UPDATE_TYPE_CUSTOMER'),
  TYPE_CUSTOMER_REPOSITORY: Symbol.for('TYPE_CUSTOMER_REPOSITORY'),

  CREATE_TYPE_IDENTIFICATION: Symbol.for('CREATE_TYPE_IDENTIFICATION'),
  DELETE_TYPE_IDENTIFICATION: Symbol.for('DELETE_TYPE_IDENTIFICATION'),
  FINDALL_TYPE_IDENTIFICATION: Symbol.for('FINDALL_TYPE_IDENTIFICATION'),
  UPDATE_TYPE_IDENTIFICATION: Symbol.for('UPDATE_TYPE_IDENTIFICATION'),
  TYPE_IDENTIFICATION_REPOSITORY: Symbol.for('TYPE_IDENTIFICATION_REPOSITORY'),

  CREATE_TYPE_CONTACT: Symbol.for('CREATE_TYPE_CONTACT'),
  FINDALL_TYPE_CONTACT: Symbol.for('FINDALL_TYPE_CONTACT'),
  UPDATE_TYPE_CONTACT: Symbol.for('UPDATE_TYPE_CONTACT'),
  DELETE_TYPE_CONTACT: Symbol.for('DELETE_TYPE_CONTACT'),
  TYPE_CONTACT_REPOSITORY: Symbol.for('TYPE_CONTACT_REPOSITORY'),

  CREATE_TYPE_ADDRESS: Symbol.for('CREATE_TYPE_ADDRESS'),
  FINDALL_TYPE_ADDRESS: Symbol.for('FINDALL_TYPE_ADDRESS'),
  UPDATE_TYPE_ADDRESS: Symbol.for('UPDATE_TYPE_ADDRESS'),
  DELETE_TYPE_ADDRESS: Symbol.for('DELETE_TYPE_ADDRESS'),
  TYPE_ADDRESS_REPOSITORY: Symbol.for('TYPE_ADDRESS_REPOSITORY'),

  CREATE_GROUP_REFERENCE: Symbol.for('CREATE_GROUP_REFERENCE'),
  FINDALL_GROUP_REFERENCE: Symbol.for('FINDALL_GROUP_REFERENCE'),
  FINDALL_GROUP_TREE_REFERENCE: Symbol.for('FINDALL_GROUP_TREE_REFERENCE'),
  UPDATE_GROUP_REFERENCE: Symbol.for('UPDATE_GROUP_REFERENCE'),
  DELETE_GROUP_REFERENCE: Symbol.for('DELETE_GROUP_REFERENCE'),
  VALIDATE_GROUP_REFERENCE_CODE: Symbol.for('VALIDATE_GROUP_REFERENCE_CODE'),
  FINDBYID_GROUP_REFERENCE: Symbol.for('FINDBYID_GROUP_REFERENCE'),
  GROUP_REFERENCE_REPOSITORY: Symbol.for('GROUP_REFERENCE_REPOSITORY'),

  CREATE_VARIETY_REFERENCE: Symbol.for('CREATE_VARIETY_REFERENCE'),
  FINDALL_VARIETY_REFERENCE: Symbol.for('FINDALL_VARIETY_REFERENCE'),
  UPDATE_VARIETY_REFERENCE: Symbol.for('UPDATE_VARIETY_REFERENCE'),
  VARIETY_REFERENCE_REPOSITORY: Symbol.for('VARIETY_REFERENCE_REPOSITORY'),

  CREATE_SEGMENTATION_REFERENCE: Symbol.for('CREATE_SEGMENTATION_REFERENCE'),
  FINDALL_SEGMENTATION_REFERENCE: Symbol.for('FINDALL_SEGMENTATION_REFERENCE'),
  UPDATE_SEGMENTATION_REFERENCE: Symbol.for('UPDATE_SEGMENTATION_REFERENCE'),
  SEGMENTATION_REFERENCE_REPOSITORY: Symbol.for('SEGMENTATION_REFERENCE_REPOSITORY'),

  //PACKING UNITS
  CREATE_PACKINGUNIT_REFERENCE: Symbol.for('CREATE_PACKINGUNIT_REFERENCE'),
  UPDATE_PACKINGUNIT_REFERENCE: Symbol.for('UPDATE_PACKINGUNIT_REFERENCE'),
  DELETE_PACKINGUNIT_REFERENCE: Symbol.for('DELETE_PACKINGUNIT_REFERENCE'),
  FINDALL_PACKINGUNIT_REFERENCE: Symbol.for('FINDALL_PACKINGUNIT_REFERENCE'),
  FINDALL_BY_TYPE_PACKINGUNIT_REFERENCE: Symbol.for('FINDALL_BY_TYPE_PACKINGUNIT_REFERENCE'),
  PACKINGUNIT_REFERENCE_REPOSITORY: Symbol.for('UNITMEASUREMENT_REFERENCE_REPOSITORY'),

  //Containers
  CREATE_CONTAINER: Symbol.for('CREATE_CONTAINER'),
  UPDATE_CONTAINER: Symbol.for('UPDATE_CONTAINER'),
  FIND_CONTAINER_BY_ID: Symbol.for('FIND_CONTAINER_BY_ID'),
  FIND_ALL_CONTAINERS: Symbol.for('FIND_ALL_CONTAINERS'),
  FIND_CONTAINER_BY_STATUS: Symbol.for('FIND_CONTAINER_BY_STATUS'),
  CONTAINER_REPOSITORY: Symbol.for('CONTAINER_REPOSITORY'),

  //Concepts containers
  CREATE_CONCEPT_CONTAINER: Symbol.for('CREATE_CONCEPT_CONTAINER'),
  UPDATE_CONCEPT_CONTAINER: Symbol.for('UPDATE_CONCEPT_CONTAINER'),
  DELETE_CONCEPT_CONTAINER: Symbol.for('DELETE_CONCEPT_CONTAINER'),
  FINDALL_CONCEPT_CONTAINER: Symbol.for('FINDALL_CONCEPT_CONTAINER'),
  FINDBYCODE_CONCEPT_CONTAINER: Symbol.for('FINDBYCODE_CONCEPT_CONTAINER'),
  CONCEPTS_CONTAINER_REPOSITORY: Symbol.for('CONCEPTS_CONTAINER_REPOSITORY'),

  //Packing Units
  CREATE_PACKING_UNITS: Symbol.for('CREATE_PACKING_UNITS'),
  UPDATE_PACKING_UNITS: Symbol.for('UPDATE_PACKING_UNITS'),
  FIND_PACKING_UNITS_BY_ID: Symbol.for('FIND_PACKING_UNITS_BY_ID'),
  FIND_ALL_PACKING_UNITS: Symbol.for('FIND_ALL_PACKING_UNITS'),
  FIND_PACKING_UNITS_BY_STATUS: Symbol.for('FIND_PACKING_UNITS_BY_STATUS'),
  PACKING_UNITS_REPOSITORY: Symbol.for('PACKING_UNITS_REPOSITORYY'),

  CREATE_LAYOUT_ZONE: Symbol.for('CREATE_LAYOUT_ZONE'),
  DELETE_LAYOUT_ZONE: Symbol.for('DELETE_LAYOUT_ZONE'),
  UPDATE_LAYOUT_ZONE: Symbol.for('UPDATE_LAYOUT_ZONE'),
  FINDALL_LAYOUT_ZONE: Symbol.for('FINDALL_LAYOUT_ZONE'),
  FINDALL_LAYOUT_ZONE_NOT_CONFIGURED: Symbol.for('FINDALL_LAYOUT_ZONE_NOT_CONFIGURED'),
  FIND_BY_ID_LAYOUT_ZONE: Symbol.for('FIND_BY_ID_LAYOUT_ZONE'),
  FIND_ALL_LAYOUT_ZONE: Symbol.for('FIND_ALL_LAYOUT_ZONE'),
  LAYOUT_ZONE_REPOSITORY: Symbol.for('LAYOUT_ZONE_REPOSITORY'),

  // Racks config
  SET_RACKS_CONFIG_TO_LAYOUT_ZONE: Symbol.for('SET_RACKS_CONFIG_TO_LAYOUT_ZONE'),

  CREATE_RACKS: Symbol.for('CREATE_RACKS'),
  UPDATE_RACKS: Symbol.for('UPDATE_RACKS'),
  FIND_BY_ID_RACKS: Symbol.for('FIND_BY_ID_RACKS'),
  FIND_ALL_RACKS: Symbol.for('FIND_ALL_RACKS'),
  FIND_RACKS_BY_STATUS: Symbol.for('FIND_RACKS_BY_STATUS'),
  RACKS_REPOSITORY: Symbol.for('RACKS_REPOSITORY'),

  //STORE
  CREATE_STORE: Symbol.for('CREATE_STORE'),
  UPDATE_STORE: Symbol.for('UPDATE_STORE'),
  FIND_ALL_STORE: Symbol.for('FIND_ALL_STORE'),
  FIND_ALL_STORE_WAREHOUSE: Symbol.for('FIND_ALL_STORE_WAREHOUSE'),
  STORES_GET_BY_USER: Symbol.for('STORES_GET_BY_USER'),
  STORE_REPOSITORY: Symbol.for('STORE_REPOSITORY'),
  STORE_FIND_ALL_FOR_USER_ASSIGN: Symbol.for('STORE_FIND_ALL_FOR_USER_ASSIGN'),

  //PreEntity
  API_PRE_ENTITY_SAVE: Symbol.for('API_PRE_ENTITY_SAVE'),
  API_PRE_ENTITY_UPDATE: Symbol.for('API_PRE_ENTITY_UPDATE'),
  API_PRE_ENTITY_FIND_ALL_PAGE: Symbol.for('API_PRE_ENTITY_FIND_ALL_PAGE'),
  API_PRE_ENTITY_FIND_BY_ID: Symbol.for('API_PRE_ENTITY_FIND_BY_ID'),
  API_PRE_ENTITY_FIND_BY_EMAIL: Symbol.for('API_PRE_ENTITY_FIND_BY_EMAIL'),
  PRE_ENTITY_FIND_BY_DESCRIPTION_LIKE: Symbol.for('PRE_ENTITY_FIND_BY_DESCRIPTION_LIKE'),
  API_PRE_ENTITY_REPOSITORY: Symbol.for('API_PRE_ENTITY_REPOSITORY'),

  //Entity
  API_ENTITY_SAVE: Symbol.for('API_ENTITY_SAVE'),
  API_ENTITY_UPDATE: Symbol.for('API_ENTITY_UPDATE'),
  API_ENTITY_FIND_ALL_PAGE: Symbol.for('API_ENTITY_FIND_ALL_PAGE'),
  API_ENTITY_FIND_BY_ID: Symbol.for('API_ENTITY_FIND_BY_ID'),
  API_ENTITY_FIND_BY_EMAIL: Symbol.for('API_ENTITY_FIND_BY_EMAIL'),
  ENTITY_FIND_BY_DESCRIPTION_LIKE: Symbol.for('ENTITY_FIND_BY_DESCRIPTION_LIKE'),
  API_ENTITY_REPOSITORY: Symbol.for('API_ENTITY_REPOSITORY'),
  API_ENTITY_DELETE_CONTACT: Symbol.for('API_ENTITY_DELETE_CONTACT'),
  API_ENTITY_DELETE_ADDRESS: Symbol.for('API_ENTITY_DELETE_ADDRESS'),

  //Person
  PERSON_REPOSITORY: Symbol.for('PERSON_REPOSITORY'),
  CREATE_PERSON: Symbol.for('CREATE_PERSON'),
  UPDATE_PERSON: Symbol.for('UPDATE_PERSON'),
  FIND_PERSON_BY_DOCUMENT: Symbol.for('FIND_PERSON_BY_DOCUMENT'),

  //UserERP
  USER_ERP_REPOSITORY: Symbol.for('USER_ERP_REPOSITORY'),
  CREATE_USER_ERP: Symbol.for('CREATE_USER_ERP'),
  UPDATE_USER_ERP: Symbol.for('UPDATE_USER_ERP'),
  FIND_USER_ERP_BY_EMAIL: Symbol.for('FIND_USER_ERP_BY_EMAIL'),

  //Ports
  SEARCH_PORTS_BY_DESCRIPTION_LIKE: Symbol.for('SEARCH_PORTS_BY_DESCRIPTION_LIKE'),
  PORT_SEARCH_ALL: Symbol.for('PORT_SEARCH_ALL'),
  PORT_CREATE: Symbol.for('PORT_CREATE'),
  PORT_UPDATE: Symbol.for('PORT_UPDATE'),
  SEARCH_PORTS_BY_STATUS: Symbol.for('SEARCH_PORTS_BY_STATUS'),
  PORT_REPOSITORY: Symbol.for('PORT_REPOSITORY'),

  //view order
  VIEW_ORDER_FINDBYFILTERS: Symbol.for('VIEW_ORDER_FINDBYFILTERS'),
  VIEW_ORDER_REPOSITORY: Symbol.for('VIEW_ORDER_REPOSITORY'),
  //View location
  VIEW_FIND_LOCATIONS_BY_STORE_AND_COMMODITY_REPOSITORY: Symbol.for(
    'VIEW_FIND_LOCATIONS_BY_STORE_AND_COMMODITY_REPOSITORY'
  ),
  VIEW_FIND_LOCATIONS_BY_STORE_AND_COMMODITY: Symbol.for('VIEW_FIND_LOCATIONS_BY_STORE_AND_COMMODITY'),
  VIEW_FIND_LOCATIONS_BY_WAREHOUSE_AND_COMMODITY: Symbol.for('VIEW_FIND_LOCATIONS_BY_WAREHOUSE_AND_COMMODITY'),

  //migratios
  MIGRATIONS_FROMCOUNTRYCODE: Symbol.for('MIGRATIONS_FROMCOUNTRYCODE'),
  MIGRATIONS_FROMCOUNTRYCODE_REPOSITORY: Symbol.for('MIGRATIONS_FROMCOUNTRYCODE_REPOSITORY'),
  //EMAIL CONFIG
  EMAIL_CONFIG_SAVE: Symbol.for('EMAIL_CONFIG_SAVE'),
  EMAIL_CONFIG_FIND_BY_COMPANY: Symbol.for('EMAIL_CONFIG_FIND_BY_COMPANY'),

  /**Email type */
  EMAIL_TYPE_REPOSITORY: Symbol.for('EMAIL_TYPE_REPOSITORY'),
  EMAIL_TYPE_FIND_ALL: Symbol.for('EMAIL_TYPE_FIND_ALL'),

  /**Email type config */
  EMAIL_TYPE_CONFIG_REPOSITORY: Symbol.for('EMAIL_TYPE_CONFIG_REPOSITORY'),
  EMAIL_TYPE_CONFIG_FIND_BY_COMPANY_AND_TYPE: Symbol.for('EMAIL_TYPE_CONFIG_FIND_BY_COMPANY_AND_TYPE'),
  EMAIL_TYPE_CONFIG_SAVE: Symbol.for('EMAIL_TYPE_CONFIG_SAVE'),

  //COMMODITY TYPE
  COMMODITY_TYPE_FIND_ALL: Symbol.for('COMMODITY_TYPE_FIND_ALL'),
  COMMODITY_TYPE_SAVE: Symbol.for('COMMODITY_TYPE_SAVE'),
  COMMODITY_TYPE_DELETE: Symbol.for('COMMODITY_TYPE_DELETE'),
  COMMODITY_TYPE_UPDATE: Symbol.for('COMMODITY_TYPE_UPDATE'),
  COMMODITY_TYPE_FIND_BY_CODE: Symbol.for('COMMODITY_TYPE_FIND_BY_CODE'),
  COMMODITY_TYPE_FIND_BY_ID: Symbol.for('COMMODITY_TYPE_FIND_BY_ID'),
  COMMODITY_TYPE_REPOSITORY: Symbol.for('COMMODITY_TYPE_REPOSITORY'),
  COMMODITY_TYPE_FIND_BY_STATUS: Symbol.for('COMMODITY_TYPE_FIND_BY_STATUS'),
  //OPTIONS TYPE
  OPTIONS_TYPE_DOCUMENT: Symbol.for('OPTIONS_TYPE_DOCUMENT'),
  //DOCUMENTS
  DOCUMENT_TYPE_SAVE: Symbol.for('DOCUMENT_TYPE_SAVE'),
  DOCUMENT_TYPE_UPDATE: Symbol.for('DOCUMENT_TYPE_UPDATE'),
  DOCUMENT_TYPE_REPOSITORY: Symbol.for('DOCUMENT_TYPE_REPOSITORY'),
  DOCUMENT_TYPE_FINDALL_BY_TYPE: Symbol.for('DOCUMENT_TYPE_FINDALL_BY_TYPE'),
  DOCUMENT_TYPE_FIND_BY_SWITCHS_FILTERS: Symbol.for('DOCUMENT_TYPE_FIND_BY_SWITCHS_FILTERS'),
  DOCUMENT_TYPE_FIND_BY_PK: Symbol.for('DOCUMENT_TYPE_FIND_BY_PK'),

  // DOCUMENTS APPLIED
  DOCUMENT_APPLIED_SAVE: Symbol.for('DOCUMENT_APPLIED_SAVE'),
  DOCUMENT_APPLIED_REPOSITORY: Symbol.for('DOCUMENT_APPLIED_REPOSITORY'),

  //TIMELINE CONFIG
  TIMELINECONFIG_TYPE_SAVE: Symbol.for('TIMELINECONFIG_TYPE_SAVE'),
  TIMELINECONFIG_TYPE_FINDALL: Symbol.for('TIMELINECONFIG_TYPE_FINDALL'),
  TIMELINECONFIG_TYPE_REPOSITORY: Symbol.for('TIMELINECONFIG_TYPE_REPOSITORY'),
  TIMELINECONFIG_TYPE_FINDALL_BY_STATUS: Symbol.for('TIMELINECONFIG_TYPE_FINDALL_BY_STATUS'),
  TIMELINECONFIG_TYPE_FINDALL_BY_PARAMS: Symbol.for('TIMELINECONFIG_TYPE_FINDALL_BY_PARAMS'),
  TIMELINECONFIG_TYPE_FINDALL_BY_PK: Symbol.for('TIMELINECONFIG_TYPE_FINDALL_BY_PK'),

  //LAYOUT CONFIG
  LAYOUT_CONFIG_SAVE: Symbol.for('LAYOUT_CONFIG_SAVE'),
  LAYOUT_CONFIG_FIND: Symbol.for('LAYOUT_CONFIG_'),
  LAYOUT_CONFIG_REPOSITORY: Symbol.for('LAYOUT_CONFIG_REPOSITORY'),
  LAYOUT_CONFIG_DELETE_ZONE: Symbol.for('LAYOUT_CONFIG_DELETE_ZONE'),
  LAYOUT_CONFIG_DELETE_RACK: Symbol.for('LAYOUT_CONFIG_DELETE_RACK'),
  //PRICING ZONES
  PRICING_ZONE_REPOSITORY: Symbol.for('PRICING_ZONE_REPOSITORY'),
  PRICING_ZONE_SAVE: Symbol.for('PRICING_ZONE_SAVE'),
  PRICING_ZONE_DELETE: Symbol.for('PRICING_ZONE_DELETE'),
  PRICING_ZONE_UPDATE: Symbol.for('PRICING_ZONE_UPDATE'),
  PRICING_ZONE_FIND_ALL: Symbol.for('PRICING_ZONE_FIND_ALL'),
  PRICING_ZONE_FIND_BY_STATUS: Symbol.for('PRICING_ZONE_FIND_BY_STATUS'),
  PRICING_ZONE_FIND_BY_ORIGIN_AND_DESTINATION: Symbol.for('PRICING_ZONE_FIND_BY_ORIGIN_AND_DESTINATION'),
  //PRICING ZONE LEVEL
  PRICING_ZONE_LEVEL_REPOSITORY: Symbol.for('PRICING_ZONE_LEVEL_REPOSITORY'),
  PRICING_ZONE_LEVEL_DELETE: Symbol.for('PRICING_ZONE_LEVEL_DELETE'),
  //Quotations
  QUOTATIONS_SAVE: Symbol.for('QUOTATIONS_SAVE'),
  QUOTATIONS_UPDATE: Symbol.for('QUOTATIONS_UPDATE'),
  QUOTATIONS_FIND_ALL_BY_TYPE_AND_MODULE: Symbol.for('QUOTATIONS_FIND_ALL_BY_TYPE_AND_MODULE'),
  WMS_ORDER_FIND_BY_PK: Symbol.for('WMS_ORDER_FIND_BY_PK'),
  // #region PreRegister
  PRE_REGISTER_REPOSITORY: Symbol.for('PRE_REGISTER_REPOSITORY'),
  PRE_REGISTER_SAVE: Symbol.for('PRE_REGISTER_SAVE'),
  PRE_REGISTER_UPDATE: Symbol.for('PRE_REGISTER_UPDATE'),
  PRE_REGISTER_CHANGE_TYPE: Symbol.for('PRE_REGISTER_CHANGE_TYPE'),
  PRE_REGISTER_FIND_BY_PK: Symbol.for('PRE_REGISTER_FIND_BY_PK'),
  PRE_REGISTER_FINDALL: Symbol.for('PRE_REGISTER_FINDALL'),
  PRE_REGISTER_FINDALL_WITH_BULKS: Symbol.for('PRE_REGISTER_FINDALL_WITH_BULKS'),
  PRE_REGISTER_FINDALL_BY_DATE_AND_TYPE: Symbol.for('PRE_REGISTER_FINDALL_BY_DATE_AND_TYPE'),
  PRE_REGISTER_FINDALL_BY_DATE_AND_LIKE_TYPE: Symbol.for('PRE_REGISTER_FINDALL_BY_DATE_AND_LIKE_TYPE'),
  PRE_REGISTER_CREATE_REPORT: Symbol.for('PRE_REGISTER_CREATE_REPORT'),
  PRE_REGISTER_FIND_FOR_TRACEABILITY: Symbol.for('PRE_REGISTER_FIND_FOR_TRACEABILITY'),
  PRE_REGISTER_FIND_FOR_DASHBOARD: Symbol.for('PRE_REGISTER_FIND_FOR_DASHBOARD'),
  PRE_REGISTER_FIND_CONSIGNEES: Symbol.for('PRE_REGISTER_FIND_CONSIGNEES'),
  PRE_REGISTER_FIND_PAYMENTS: Symbol.for('PRE_REGISTER_FIND_PAYMENTS'),
  //Shipping Methods
  SHIPPING_METHOD_REPOSITORY: Symbol.for('SHIPPING_METHOD_REPOSITORY'),
  SHIPPING_METHOD_SAVE: Symbol.for('SHIPPING_METHOD_SAVE'),
  SHIPPING_METHOD_UPDATE: Symbol.for('SHIPPING_METHOD_UPDATE'),
  SHIPPING_METHOD_DELETE: Symbol.for('SHIPPING_METHOD_DELETE'),
  SHIPPING_METHOD_FIND_ALL: Symbol.for('SHIPPING_METHOD_FIND_ALL'),
  SHIPPING_METHOD_FIND_BY_STATUS: Symbol.for('SHIPPING_METHOD_FIND_BY_STATUS'),
  SHIPPING_METHOD_FIND_BY_ID: Symbol.for('SHIPPING_METHOD_FIND_BY_ID'),
  //Commodity Class
  COMMODITY_CLASS_REPOSITORY: Symbol.for('COMMODITY_CLASS_REPOSITORY'),
  COMMODITY_CLASS_SAVE: Symbol.for('COMMODITY_CLASS_SAVE'),
  COMMODITY_CLASS_UPDATE: Symbol.for('COMMODITY_CLASS_UPDATE'),
  COMMODITY_CLASS_DELETE: Symbol.for('COMMODITY_CLASS_DELETE'),
  COMMODITY_CLASS_FIND_ALL: Symbol.for('COMMODITY_CLASS_FIND_ALL'),
  COMMODITY_CLASS_FIND_BY_CODE: Symbol.for('COMMODITY_CLASS_FIND_BY_CODE'),
  COMMODITY_CLASS_FIND_BY_STATUS: Symbol.for('COMMODITY_CLASS_FIND_BY_STATUS'),
  // #region Bill of landing
  BILL_OF_LANDING_REPOSITORY: Symbol.for('BILL_OF_LANDING_REPOSITORY'),
  BILL_OF_LANDING_SAVE: Symbol.for('BILL_OF_LANDING_SAVE'),
  BILL_OF_LANDING_FINDALL: Symbol.for('BILL_OF_LANDING_FINDALL'),
  BILL_OF_LANDING_FIND_DATA_FOR_REPORT: Symbol.for('BILL_OF_LANDING_FIND_DATA_FOR_REPORT'),
  BILL_OF_LANDING_FINDALL_WITH_OPERATION_DATA: Symbol.for('BILL_OF_LANDING_FINDALL_WITH_OPERATION_DATA'),
  BILL_OF_LANDING_CHECK_EXIST: Symbol.for('BILL_OF_LANDING_CHECK_EXIST'),
  MASTER_BILL_OF_LANDING_FIND_BY_FILTERS: Symbol.for('MASTER_BILL_OF_LANDING_FIND_BY_FILTERS'),
  MASTER_BILL_OF_LANDING_BY_COMMODITY_AND_ENTITY: Symbol.for('MASTER_BILL_OF_LANDING_BY_COMMODITY_AND_ENTITY'),
  MASTER_BILL_OF_LANDING_FIND_BY_HOUSES: Symbol.for('MASTER_BILL_OF_LANDING_FIND_BY_HOUSES'),
  MASTER_BILL_OF_LANDING_CREATE_FOR_PRE_REGISTER: Symbol.for('MASTER_BILL_OF_LANDING_CREATE_FOR_PRE_REGISTER'),
  MASTER_BILL_OF_LANDING_FIND_BY_PRE_REGISTER: Symbol.for('MASTER_BILL_OF_LANDING_FIND_BY_PRE_REGISTER'),
  BILL_OF_LANDING_CREATE_REPORT: Symbol.for('BILL_OF_LANDING_CREATE_REPORT'),
  // Reference group level
  REFERENCE_GROUP_LEVEL_REPOSITORY: Symbol.for('REFERENCE_GROUP_LEVEL_REPOSITORY'),
  REFERENCE_GROUP_LEVEL_SAVE: Symbol.for('REFERENCE_GROUP_LEVEL_SAVE'),
  REFERENCE_GROUP_LEVEL_UPDATE: Symbol.for('REFERENCE_GROUP_LEVEL_UPDATE'),
  REFERENCE_GROUP_LEVEL_DELETE: Symbol.for('REFERENCE_GROUP_LEVEL_DELETE'),
  REFERENCE_GROUP_LEVEL_FIND_ALL: Symbol.for('REFERENCE_GROUP_LEVEL_FIND_ALL'),
  REFERENCE_GROUP_LEVEL_FIND_BY_ID: Symbol.for('REFERENCE_GROUP_LEVEL_FIND_BY_ID'),
  REFERENCE_GROUP_LEVEL_FIND_BY_PARENT: Symbol.for('REFERENCE_GROUP_LEVEL_FIND_BY_PARENT'),
  REFERENCE_GROUP_LEVEL_GET_TREE: Symbol.for('REFERENCE_GROUP_LEVEL_GET_TREE'),

  // Documentos V2
  DOCUMENTS_V2_REPOSITORY: Symbol.for('DOCUMENTS_V2_REPOSITORY'),
  DOCUMENTS_V2_SAVE: Symbol.for('DOCUMENTS_V2_SAVE'),
  DOCUMENTS_V2_UPDATE: Symbol.for('DOCUMENTS_V2_UPDATE'),
  DOCUMENTS_V2_FIND_BY_PK: Symbol.for('DOCUMENTS_V2_FIND_BY_PK'),
  DOCUMENTS_V2_FIND_BY_MASTER: Symbol.for('DOCUMENTS_V2_FIND_BY_MASTER'),
  DOCUMENTS_V2_FIND_ALL_BY_TYPE: Symbol.for('DOCUMENTS_V2_FIND_ALL_BY_TYPE'),
  DOCUMENTS_V2_FIND_BY_SWITCHS_AND_FILTERS: Symbol.for('DOCUMENTS_V2_FIND_BY_SWITCHS_AND_FILTERS'),
  DOCUMENTS_V2_FIND_BY_SWITCHS_AND_DATERANGE: Symbol.for('DOCUMENTS_V2_FIND_BY_SWITCHS_AND_DATERANGE'),

  /** Type document Types */
  TYPE_DOCUMENT_REPOSITORY: Symbol.for('TYPE_DOCUMENT_REPOSITORY'),
  TYPE_DOCUMENT_SAVE: Symbol.for('TYPE_DOCUMENT_SAVE'),
  TYPE_DOCUMENT_UPDATE: Symbol.for('TYPE_DOCUMENT_UPDATE'),
  TYPE_DOCUMENT_DELETE: Symbol.for('TYPE_DOCUMENT_DELETE'),
  TYPE_DOCUMENT_FIND_BY_TYPE: Symbol.for('TYPE_DOCUMENT_FIND_BY_TYPE'),
  TYPE_DOCUMENT_FIND_ALL: Symbol.for('TYPE_DOCUMENT_FIND_ALL'),

  // Contable Concepts
  CONTABLE_CONCEPTS_REPOSITORY: Symbol.for('CONTABLE_CONCEPTS_REPOSITORY'),
  CONTABLE_CONCEPTS_FIND_ALL: Symbol.for('CONTABLE_CONCEPTS_FIND_ALL'),
  CONTABLE_CONCEPTS_FIND_ALL_BY_DOCUMENT_TYPE: Symbol.for('CONTABLE_CONCEPTS_FIND_ALL_BY_DOCUMENT_TYPE'),
  CONTABLE_CONCEPTS_FIND_BY_ID: Symbol.for('CONTABLE_CONCEPTS_FIND_BY_ID'),
  CONTABLE_CONCEPTS_FIND_BY_STATUS: Symbol.for('CONTABLE_CONCEPTS_FIND_BY_STATUS'),
  CONTABLE_CONCEPTS_SAVE: Symbol.for('CONTABLE_CONCEPTS_SAVE'),
  CONTABLE_CONCEPTS_UPDATE: Symbol.for('CONTABLE_CONCEPTS_UPDATE'),
  CONTABLE_CONCEPTS_DELETE: Symbol.for('CONTABLE_CONCEPTS_DELETE'),

  // House
  HOUSE_REPOSITORY: Symbol.for('HOUSE_REPOSITORY'),
  HOUSE_FIND_ALL_BY_ENTITY_AND_MASTER: Symbol.for('HOUSE_FIND_ALL_BY_ENTITY_AND_MASTER'),
  HOUSE_FIND_SERIALS_BY_DATE_RANGE: Symbol.for('HOUSE_FIND_SERIALS_BY_DATE_RANGE'),

  // CLOUD CONFIG
  CLOUD_CONFIG_REPOSITORY: Symbol.for('CLOUD_CONFIG_REPOSITORY'),
  CLOUD_FIND_BY_COMPANY_ID: Symbol.for('CLOUD_FIND_BY_COMPANY_ID'),
  CLOUD_CONFIG_SAVE: Symbol.for('CLOUD_CONFIG_SAVE'),

  // login image
  LOGIN_IMAGE_REPOSITORY: Symbol.for('LOGIN_IMAGE_REPOSITORY'),
  LOGIN_IMAGE_FIND_ALL: Symbol.for('LOGIN_IMAGE_FIND_ALL'),

  // Login image config
  LOGIN_IMAGE_CONFIG_REPOSITORY: Symbol.for('LOGIN_IMAGE_CONFIG_REPOSITORY'),
  LOGIN_IMAGE_CONFIG_SAVE: Symbol.for('LOGIN_IMAGE_CONFIG_SAVE'),

  //Menu
  MENU_REPOSITORY: Symbol.for('MENU_REPOSITORY'),
  MENU_FIND_ALL: Symbol.for('MENU_FIND_ALL'),
  MENU_FIND_BY_ID: Symbol.for('MENU_FIND_BY_ID'),
  MENU_FIND_BY_ROLE: Symbol.for('MENU_FIND_BY_ROLE'),
  MENU_SAVE: Symbol.for('MENU_SAVE'),
  MENU_UPDATE: Symbol.for('MENU_UPDATE'),
  MENU_DELETE: Symbol.for('MENU_DELETE'),
  MENU_ASSIGN_TO_ROLE: Symbol.for('MENU_ASSIGN_TO_ROLE'),

  //PreRegister EventLog Detail
  PRE_REGISTER_EVENT_LOG_DETAIL_REPOSITORY: Symbol.for('PRE_REGISTER_EVENT_LOG_DETAIL_REPOSITORY'),
  PRE_REGISTER_EVENT_LOG_DETAIL_SAVE: Symbol.for('PRE_REGISTER_EVENT_LOG_DETAIL_SAVE'),
  PRE_REGISTER_EVENT_LOG_DETAIL_GET_DETAIL: Symbol.for('PRE_REGISTER_EVENT_LOG_DETAIL_GET_DETAIL'),

  //#region Activities Transactional
  ACTIVITIES_TRANSACTIONAL_RESPOSITORY: Symbol.for('ACTIVITY_TRANSACTIONAL_RESPOSITORY'),
  ACTIVITIES_TRANSACTIONAL_UPDATE_STATUS: Symbol.for('ACTIVITIES_TRANSACTIONAL_UPDATE_STATUS'),
  ACTIVITIES_TRANSACTIONAL_FIND_BY_ID: Symbol.for('ACTIVITIES_TRANSACTIONAL_FIND_BY_ID'),
  ACTIVITIES_TRANSACTIONAL_FIND_FOR_TRACEABILITY_BY_EVENT: Symbol.for(
    'ACTIVITIES_TRANSACTIONAL_FIND_FOR_TRACEABILITY_BY_EVENT'
  ),

  //Airport
  AIRPORT_REPOSITORY: Symbol.for('AIRPORT_REPOSITORY'),
  AIRPORT_SAVE: Symbol.for('AIRPORT_SAVE'),
  AIRPORT_UPDATE: Symbol.for('AIRPORT_UPDATE'),
  AIRPORT_FIND_BY_CODE: Symbol.for('AIRPORT_FIND_BY_CODE'),
  AIRPORT_FIND_ALL: Symbol.for('AIRPORT_FIND_ALL'),

  //CONSIGNEE
  CONSIGNEE_REPOSITORY: Symbol.for('CONSIGNEE_REPOSITORY'),
  CONSIGNEE_FIND_ALL: Symbol.for('CONSIGNEE_FIND_ALL'),
  CONSIGNEE_FIND_BY_NAME: Symbol.for('CONSIGNEE_FIND_BY_NAME'),

  //GENERAL PORTS
  GENERAL_PORTS_REPOSITORY: Symbol.for('GENERAL_PORTS_REPOSITORY'),
  GENERAL_PORTS_FIND_BY_QUERY: Symbol.for('GENERAL_PORTS_FIND_BY_QUERY'),

  // #region BULKS
  BULK_REPOSITORY: Symbol.for('BULK_REPOSITORY'),
  FIND_BULK_IN_STOCK: Symbol.for('FIND_BULK_IN_STOCK'),
  BULK_UPDATE_PICKING_STATUS: Symbol.for('BULK_UPDATE_PICKING_STATUS'),
  BULK_FIND_PICKED: Symbol.for('BULK_FIND_PICKED'),
  BULK_FIND_PICKED_BY_STORE_AND_DATE: Symbol.for('BULK_FIND_PICKED_BY_STORE_AND_DATE'),
  BULK_FIND_CONSOLIDATED: Symbol.for('BULK_FIND_CONSOLIDATED'),
  BULK_FIND_FOR_PICKING_BY_HOUSE_BL: Symbol.for('BULK_FIND_FOR_PICKING_BY_HOUSE_BL'),
  BULK_FIND_FOR_PICKING: Symbol.for('BULK_FIND_FOR_PICKING'),
  BULK_FIND_BY_PICKING_LISTS: Symbol.for('BULK_FIND_BY_PICKING_LISTS'),
  BULK_FIND_CUBE_INFO_BY_OPERATION: Symbol.for('BULK_FIND_CUBE_INFO_BY_OPERATION'),
  BULK_FIND_BY_OPERATION_FOR_TABLE: Symbol.for('BULK_FIND_BY_OPERATION_FOR_TABLE'),
  BULK_CUBE_PROCESS: Symbol.for('BULK_CUBE_PROCESS'),
  //Consolidation
  BULK_CONSOLIDATE: Symbol.for('BULK_CONSOLIDATE'),
  BULK_DECONSOLIDATE: Symbol.for('BULK_DECONSOLIDATE'),
  BULKS_FIND_DATA_FOR_WAREHOUSE_RECEIPT_REPORT: Symbol.for('BULKS_FIND_DATA_FOR_WAREHOUSE_RECEIPT_REPORT'),
  //BRANCH
  BRANCH_REPOSITORY: Symbol.for('BRANCH_REPOSITORY'),
  BRANCH_FIND_ALL: Symbol.for('BRANCH_FIND_ALL'),
  BRANCH_FIND_BY_CODE: Symbol.for('BRANCH_FIND_BY_CODE'),
  BRANCH_SAVE: Symbol.for('BRANCH_SAVE'),
  BRANCH_UPDATE: Symbol.for('BRANCH_UPDATE'),
  BRANCH_DELETE: Symbol.for('BRANCH_DELETE'),
  BRANCH_FIND_BY_STATUS: Symbol.for('BRANCH_FIND_BY_STATUS'),

  // GAMMA
  GAMMA_REPOSITORY: Symbol.for('GAMMA_REPOSITORY'),
  GAMMA_FINDALL: Symbol.for('GAMMA_FINDALL'),
  GAMMA_SAVE: Symbol.for('GAMMA_SAVE'),
  GAMMA_UPDATE: Symbol.for('GAMMA_UPDATE'),
  GAMMA_DELETE: Symbol.for('GAMMA_DELETE'),
  COLOR_REPOSITORY: Symbol.for('COLOR_REPOSITORY'),
  COLOR_FINDALL: Symbol.for('COLOR_FINDALL'),
  COLOR_SAVE: Symbol.for('COLOR_SAVE'),
  COLOR_UPDATE: Symbol.for('COLOR_UPDATE'),
  COLOR_DELETE: Symbol.for('COLOR_DELETE'),
  SIZE_REPOSITORY: Symbol.for('SIZE_REPOSITORY'),
  SIZE_FINDALL: Symbol.for('SIZE_FINDALL'),
  SIZE_SAVE: Symbol.for('SIZE_SAVE'),
  SIZE_DELETE: Symbol.for('SIZE_DELETE'),

  // COURIER PRICE LIST
  COURIER_PRICE_LIST_REPOSITORY: Symbol.for('COURIER_PRICE_LIST_REPOSITORY'),
  COURIER_PRICE_LIST_SAVE: Symbol.for('COURIER_PRICE_LIST_SAVE'),
  COURIER_PRICE_LIST_UPDATE: Symbol.for('COURIER_PRICE_LIST_UPDATE'),
  COURIER_PRICE_LIST_DELETE: Symbol.for('COURIER_PRICE_LIST_DELETE'),
  COURIER_PRICE_LIST_FIND_ALL: Symbol.for('COURIER_PRICE_LIST_FIND_ALL'),
  COURIER_PRICE_LIST_FIND_BY_ID: Symbol.for('COURIER_PRICE_LIST_FIND_BY_ID'),
  COURIER_PRICE_LIST_FIND_BY_STATUS: Symbol.for('COURIER_PRICE_LIST_FIND_BY_STATUS'),
  //#region DocsLin
  DOCS_LIN_REPOSITORY: Symbol.for('DOCS_LIN_REPOSITORY'),
  DOCS_LIN_FIND_BY_TIMELINE: Symbol.for('DOCS_LIN_FIND_BY_TIMELINE'),

  // #region Picking list
  PICKING_LIST_REPOSITORY: Symbol.for('PICKING_LIST_REPOSITORY'),
  PICKING_LIST_FIND_BY_DATE: Symbol.for('PICKING_LIST_FIND_BY_DATE'),
  PICKING_LIST_FIND_NO_SHIPPED: Symbol.for('PICKING_LIST_FIND_NO_SHIPPED'),
  PICKING_LIST_FIND_DATA_FOR_REPORT: Symbol.for('PICKING_LIST_FIND_DATA_FOR_REPORT'),

  // #region House Bill of Lading
  HOUSE_BILL_OF_LADING_REPOSITORY: Symbol.for('HOUSE_BILL_OF_LADING_REPOSITORY'),
  HOUSE_BILL_OF_LANDING_FIND_BY_OPERATION: Symbol.for('HOUSE_BILL_OF_LANDING_FIND_BY_OPERATION'),
  HOUSE_BILL_OF_LADING_SAVE: Symbol.for('HOUSE_BILL_OF_LADING_SAVE'),
  FIND_DATA_FOR_REPORT_HOUSE_BILL_OF_LADING: Symbol.for('FIND_DATA_FOR_REPORT_HOUSE_BILL_OF_LADING'),
  PICKING_LIST_FIND_SHIPPED_BY_DATE_RANGE: Symbol.for('PICKING_LIST_FIND_SHIPPED_BY_DATE_RANGE'),

  //#region Consolidated
  CONSOLIDATED_REPOSITORY: Symbol.for('CONSOLIDATED_REPOSITORY'),
  CONSOLIDATED_FIND_DATA_FOR_REPORT: Symbol.for('CONSOLIDATED_FIND_DATA_FOR_REPORT'),
  CONSOLIDATED_FIND_BY_DATE: Symbol.for('CONSOLIDATED_FIND_BY_DATE'),
  // #region Event transactional
  EVENT_TRANSACTIONAL_REPOSITORY: Symbol.for('EVENT_TRANSACTIONAL_REPOSITORY'),
  EVENT_TRANSACTIONAL_FIND_BY_PK: Symbol.for('EVENT_TRANSACTIONAL_FIND_BY_PK'),

  // #region Cargo details preset
  CARGO_DETAILS_PRESET_REPOSITORY: Symbol.for('CARGO_DETAILS_PRESET_REPOSITORY'),
  CARGO_DETAILS_PRESET_FIND_ALL: Symbol.for('CARGO_DETAILS_PRESET_FIND_ALL'),
  CARGO_DETAILS_PRESET_SAVE: Symbol.for('CARGO_DETAILS_PRESET_SAVE'),
  CARGO_DETAILS_PRESET_UPDATE: Symbol.for('CARGO_DETAILS_PRESET_UPDATE'),
  CARGO_DETAILS_PRESET_DELETE: Symbol.for('CARGO_DETAILS_PRESET_DELETE'),
  CARGO_DETAILS_PRESET_FIND_BY_ID: Symbol.for('CARGO_DETAILS_PRESET_FIND_BY_ID'),
  CARGO_DETAILS_PRESET_FIND_STATUS: Symbol.for('CARGO_DETAILS_PRESET_FIND_STATUS')
};
