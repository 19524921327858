import { http } from '@/core/http/HttpFetch';
import { Injectable } from '@/core/di/Injectable';
import { localStorageModule } from '@/core/config/ConfigurationServer';
import { Consecutive } from '@/courier/domain/consecutive/Consecutive';
import { ConsecutiveRepository } from '@/courier/domain/consecutive/ConsecutiveRepository';

@Injectable()
export class ConsecutiveServices implements ConsecutiveRepository {
  private readonly API_BASE_URI: string = localStorageModule().transactions.host.API_BASE_URI;
  private readonly API_CONSECUTIVE_FINDBYWAREHOUSEANDTYPE: string =
    this.API_BASE_URI + localStorageModule().transactions.routes.API_CONSECUTIVE_FINDBYWAREHOUSEANDTYPE;

  async searchByWarehouseAndType(payload: any): Promise<Consecutive[]> {
    try {
      return await http.get<Consecutive[]>(
        this.API_CONSECUTIVE_FINDBYWAREHOUSEANDTYPE + `type=${payload.type}&warehouse=${payload.warehouse}`
      );
    } catch (err) {
      throw new Error(`${err}`);
    }
  }
}
